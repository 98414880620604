import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

export type FBDirection = 'column' | 'column-reverse' | 'row' | 'row-reverse' | 'inherit';
export type FBJustify =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'stretch'
  | 'space-evenly'
  | 'inherit';
export type FBAlign = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline' | 'inherit';
export type FBWrap = 'nowrap' | 'wrap' | 'wrap-reverse' | 'inherit';

export interface IPadding {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

export interface IFlexCardCell {
  padding?: IPadding | number;
  className?: string;
  children?: React.ReactNode;
}

const FlexCardCell: React.FC<IFlexCardCell> = observer(props => {
  const { padding = 0, className = '', children, ...restProps } = props;
  let stylePadding: object = {};
  if (typeof padding === 'number') {
    stylePadding = {
      padding,
    };
  } else {
    stylePadding = {
      paddingTop: padding.top,
      paddingRight: padding.right,
      paddingBottom: padding.bottom,
      paddingLeft: padding.left,
    };
  }

  const Cell = styled.div``;

  return (
    <Cell style={stylePadding} className={className}>
      {children}
    </Cell>
  );
});

export interface IFlexCardTd {
  label: string | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const FlexCardTd: React.FC<IFlexCardTd> = observer(props => {
  const { label = '', className = '', children, ...restProps } = props;
  const Td = styled.div`
    [data-td-label] {
    }
    [data-td-content] {
    }
  `;
  return (
    <Td {...restProps} className={className}>
      <div data-td-label>{label}</div>
      <div data-td-content>{children}</div>
    </Td>
  );
});

export interface IFlexCardProps {
  direction?: FBDirection;
  justify?: FBJustify;
  align?: FBAlign;
  wrap?: FBWrap;
  width?: string;
  height?: string;
  styled?: string;
  className?: string;
  children?: React.ReactNode;
}

@observer
class FlexCard extends React.Component<IFlexCardProps> {
  static Cell = FlexCardCell;
  static Td = FlexCardTd;

  render() {
    const {
      direction = 'column',
      justify = 'flex-start',
      align = 'flex-start',
      wrap = 'nowrap',
      width = 'auto',
      height = 'auto',
      styled: styledString = '',
      className = '',
      ...restProps
    } = this.props;

    const FlexContainer = styled.div`
      position: relative;
      display: flex;
      flex-direction: ${direction};
      justify-content: ${justify};
      align-items: ${align};
      flex-wrap: ${wrap};
      min-width: ${width};
      width: ${width};
      min-height: ${height};
      height: ${height};
      ${styledString};
    `;

    return (
      <FlexContainer {...restProps} className={className}>
        {this.props.children}
      </FlexContainer>
    );
  }
}

export default FlexCard;
