import * as queryString from 'query-string';
import { cloneDeep } from 'lodash';
import { ILink } from 'stores/ConfigStore';
import { findMenuByName } from 'mocks/menu';
import { getDataValue } from './componentHelper';
import { match } from 'react-router';

export function parseQueryString<T>(_query: string): queryString.ParsedQuery<T> {
  const query = queryString.parse(_query);
  Object.entries(query).forEach(([key, value]) => {
    try {
      if (typeof value === 'string') {
        query[key] = JSON.parse(value);
      }
    } catch (e) {}
  });

  return query as unknown as queryString.ParsedQuery<T>;
}

export function stringifyQueryString<T>(_query: { [key: string]: T }): string {
  const query: { [key: string]: T } = cloneDeep(_query);
  Object.entries(query).forEach(([key, value]) => {
    if (typeof value === 'object') {
      query[key] = JSON.stringify(value) as T;
    }
  });

  return queryString.stringify(query);
}

export function getListPath(link: ILink = {} as ILink): string {
  const menu = findMenuByName(link.to);
  if (menu && menu.path) {
    return menu.path.replace(':id', '');
  }

  return '';
}

export function getPathFromLink(
  link: ILink = {} as ILink,
  match: match = { params: {} } as match,
  data: any = {},
): string | undefined {
  const menu = findMenuByName(link.to);
  if (menu && menu.path) {
    let path = menu.path;

    let id: string = link.id ?? '';

    if (!id) {
      if (link.idIndex) {
        id = getDataValue(data, link.idIndex) || match.params?.[link.idIndex];
      } else if (link.dataIndex) {
        id = getDataValue(data, link.dataIndex) || match.params?.[link.dataIndex];
      }
    }

    if (link.searchKey) {
      const value = link.dataIndex ? getDataValue(data, link.dataIndex) : id;
      path = `${menu.path.replace(':id', id || 'new')}?${stringifyQueryString({
        search: { [link.searchKey]: value },
      })}`;
    } else {
      path = menu.path.replace(':id', id || 'new');
    }

    return path;
  }

  return undefined;
}
