import * as React from 'react';
import { Button as AntButton } from 'antd';
import { forEach } from 'lodash';
import { MultiSelector } from 'components';
import { IInjectedType } from 'common/@types';

interface IProps extends IInjectedType {
  cType: 'buttonGroup';
  data?: any[];
  config: any;
  pageUuid: string;
}

export default class ButtonGroup extends React.Component<IProps> {
  render() {
    const buttons = [] as any;
    forEach(this.props.config.buttons, (button: any, key) => {
      buttons.push(
        <MultiSelector
          config={{ ...button, column: this.props.config.column }}
          data={this.props.data}
          pageUuid={this.props.pageUuid}
          form={this.props.form}
          mode={this.props.mode}
          handleSubmit={this.props.handleSubmit}
          key={`${button.cType}-${key}`}
        />,
      );
    });

    return <AntButton.Group>{buttons}</AntButton.Group>;
  }
}
