import * as React from 'react';
import styled from 'styled-components';
import { api } from 'providers';
import { IActionAPIRequest, IChartConfig } from 'stores/ConfigStore';
import { IReactionDisposer, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { IDictionary } from 'stores/KeysStore';
import { Button, Col, Row, Spin } from 'antd';
import { Bar } from '@ant-design/plots';
import { isEqual } from 'lodash';
import { BarConfig } from '@ant-design/charts';
import { Footer, Header } from 'components/Layout';
import { download } from 'common/download';

interface IBarChartProps extends IInjectedType, IInjectedStore {
  config: IChartConfig;
  pageUuid: string;
}
type ExposedProps = Omit<IBarChartProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

const Container = styled.div`
  canvas {
    background-color: #ffffff;
  }
`;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class BarChart extends React.Component<IBarChartProps> {
  @observable spinning = false;
  @observable remoteData = [];
  @observable recentDate: string = '';

  searchReaction: IReactionDisposer | undefined;
  prevSearch: any;
  chartRef: HTMLCanvasElement | undefined;

  componentDidMount() {
    this.getData();

    this.searchReaction = this.props.keyStore.addReaction(this.props.pageUuid, 'search', (_search: IDictionary) => {
      const search = toJS(_search || {}) || {};
      console.log('PieChart search reaction / search', search);

      if (!isEqual(search, this.prevSearch || {})) {
        this.prevSearch = search;
        this.getData();
      }
    });
  }

  componentWillUnmount() {
    this.searchReaction && this.searchReaction(); // dispose reaction
  }

  async getData() {
    const { request = {} as IActionAPIRequest } = this.props.config;

    if (request.url) {
      try {
        this.spinning = true;

        const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);

        const remoteData = await api[request.method || 'get'](
          `${request.url}${request.url.indexOf('?') === -1 ? '?' : '&'}where=${JSON.stringify(search)}`,
        );
        this.remoteData = remoteData.data.rows;
        this.recentDate = remoteData.data.recentDate;
      } finally {
        this.spinning = false;
      }
    }
  }

  exportChart = async () => {
    if (this.chartRef) {
      const image = this.chartRef.toDataURL('image/png');
      await download(image, 'export_chart.png');
    }
  };

  render() {
    const { config, form, handleSubmit, pageUuid, mode } = this.props;
    const { cType, header, header2nd, footer, isStack = false, isGroup = false, seriesField, style } = config;

    if (cType !== 'barChart') {
      return null;
    }

    const chartProps: BarConfig = {
      data: this.remoteData || {},
      xField: 'value',
      yField: 'type',
      isStack,
      isGroup,
      seriesField: seriesField ?? 'type',
      style,
      label: {
        position: 'middle',
        layout: [
          {
            type: 'interval-adjust-position',
          },
          {
            type: 'interval-hide-overlap',
          },
          {
            type: 'adjust-color',
          },
        ],
        style: {
          fontSize: 24,
          fontWeight: 600,
        },
      },
      theme: {
        styleSheet: {
          backgroundColor: '#ffffff',
        },
      },
      annotations: [],
    };

    if (this.recentDate && chartProps.annotations) {
      chartProps.annotations.push({
        type: 'text',
        content: `Up to date: ${this.recentDate}`,
        position: ['85%', '2%'],
        style: {
          textAlign: 'center',
          fill: 'rgba(0,0,0,0.85)',
        },
      });
    }

    return (
      <Spin spinning={this.spinning}>
        <Container>
          <Header config={header} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
          <Header config={header2nd} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
          <Row justify="end" style={{ marginBottom: '10px' }}>
            <Col>
              <Button onClick={this.exportChart}>EXPORT</Button>
            </Col>
          </Row>
          <Bar {...chartProps} onReady={(ref: any) => (this.chartRef = ref)} />
          <Footer config={footer} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        </Container>
      </Spin>
    );
  }
}

export default BarChart as React.ComponentType<ExposedProps>;
