import { IAttrGroup, ICodeData, IDisplay, IStyleGroup } from 'stores/ConfigStore';
import { clone, get, set, unset } from 'lodash';
import { isMoment, Moment } from 'moment';
import { DATE_FORMAT } from 'components/constants';

export function splitStyle(style: IStyleGroup | undefined): any {
  if (!style) {
    return {
      container: {},
      row: {},
      column: {},
    };
  }
  return {
    container: style.container || {},
    row: style.row || {},
    column: style.column || {},
  };
}

export function splitAttr(attr: IAttrGroup): any {
  if (!attr) {
    return {
      row: {},
      column: {},
    };
  }
  return {
    row: attr.row || {},
    column: attr.column || {},
  };
}

type TContainerProps = {
  style: IStyleGroup;
  attr: IAttrGroup;
};
export function splitContainerProps(props: TContainerProps): any {
  return {
    styles: splitStyle(props.style),
    attrs: splitAttr(props.attr),
  };
}

// function isValue(value: any) {
//   return value !== null && value !== undefined;
// }

export function getDataValue(data: object | undefined, dataIndex: string | undefined): any {
  if (!data || !dataIndex) {
    return null;
  }

  // const namespace = dataIndex.split('.');
  // return namespace.reduce((prev: string | object, current: string) => {
  //   return isValue(prev) && typeof prev === 'object' ? prev[current] : null;
  // }, data);
  return get(data, dataIndex);
}

const dateRegExp =
  /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z)?$/g;

export const formatDateString = (value: any, parent?: any, key?: string | number) => {
  if (Array.isArray(value)) {
    value.forEach((val, idx) => formatDateString(val, value, idx));
  } else if (value && typeof value === 'object' && Object.keys(value).length) {
    Object.keys(value).forEach(key => formatDateString(value[key], value, key));
  } else if (typeof value === 'string' && value.match(dateRegExp) && parent && key) {
    parent[key] = value.replace(/T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z)?/g, '');
  }
};

export const convertValueToJson = (value: any): any => {
  if (!value) {
    if (value === '') {
      return '';
    } else if (value === null) {
      return undefined;
    } else if (!isNaN(Number(value))) {
      return Number(value);
    }

    return value;
  } else if (isMoment(value)) {
    // moment type
    const _value: Moment = value;
    return _value.format(DATE_FORMAT);
  } else if (Array.isArray(value)) {
    return value.map(convertValueToJson);
  } else if (typeof value === 'object') {
    const newValue = clone(value);

    Object.keys(value).forEach(k => {
      if (k.indexOf('.') > -1) {
        unset(newValue, k);
        set(newValue, k, convertValueToJson(value[k]));
      } else if (k.indexOf('range/') > -1) {
        // range picker
        const _value: Moment[] = value[k];
        const arrK = k.split('/');
        unset(newValue, k);
        set(newValue, arrK[1].replace('_', '.'), isMoment(_value[0]) ? _value[0].format(DATE_FORMAT) : _value[0]);
        set(newValue, arrK[2].replace('_', '.'), isMoment(_value[1]) ? _value[1].format(DATE_FORMAT) : _value[1]);
      } else {
        newValue[k] = convertValueToJson(value[k]);
      }
    });

    return newValue;
  }

  return value;
};

export function dangerouslySetInnerHTML(rawMarkup: string, replacer?: (str: string) => string) {
  return {
    dangerouslySetInnerHTML: {
      __html: replacer ? replacer(rawMarkup) : rawMarkup.replace(/\n/g, '<br />'),
    },
  };
}

export function convertDisplay(item: any, display: IDisplay, codeData: ICodeData): string[] {
  return display.map(_dsp => {
    if (typeof _dsp === 'string') {
      return get(item, _dsp);
    }

    if (typeof _dsp === 'object') {
      return get(codeData[_dsp.code], get(item, _dsp.dataIndex));
    }

    return '';
  });
}
