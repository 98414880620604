import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import StoreProvider from 'stores/StoreProvider';
import App from 'pages/App';
import { GlobalStyles } from 'styles/global';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <CookiesProvider>
    <StoreProvider>
      <>
        <App />
        <GlobalStyles />
      </>
    </StoreProvider>
  </CookiesProvider>,
);

const onresize = () => {
  const root = document.getElementById('root');
  if (root) {
    root.style.height = window.innerHeight + 'px';
  }
};
onresize();
window.addEventListener('resize', onresize);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
