import { observable } from 'mobx';
import appStore, { IAppStore } from './AppStore';
import i18nStore, { II18nStore } from './I18nStore';
import KeysStore, { IKeysStore } from './KeysStore';

const keyStore = new KeysStore();

function prepareStore(storeInstance: any, newStore: any) {
  storeInstance.set(newStore);
}

// To support HMR of store, this ref holds the latest loaded store.
const appStoreInstance = observable.box<IAppStore>(undefined);
const i18nStoreInstance = observable.box<II18nStore>(undefined);
const keyStoreInstance = observable.box<IKeysStore>(undefined);

prepareStore(appStoreInstance, appStore);
prepareStore(i18nStoreInstance, i18nStore);
prepareStore(keyStoreInstance, keyStore);

export { appStoreInstance, i18nStoreInstance, keyStoreInstance };
export type { IAppStore, II18nStore, IKeysStore };

appStore.init();
