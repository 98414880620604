type IMenu = {
  menuId?: number;
  depth?: number;
  name: string;
  desc?: string;
  hidden?: boolean;
  parent?: string;
  isPublic?: boolean;
  slide?: string;
  url?: string;
};

export const modalConfig = {
  address: require('./address.json'),
  calculate: require('./calculate.json'),
  companyPerson: require('./companyPerson.json'),
  deliveryProduct: require('./deliveryProduct.json'),
  educationProduct: require('./educationProduct.json'),
  equipment: require('./equipment.json'),
  equipmentList: require('./equipmentList.json'),
  part: require('./part.json'),
  personWithCompany: require('./personWithCompany.json'),
  rentAmount: require('./rentAmount.json'),
  rentPeriod: require('./rentPeriod.json'),
  rentProduct: require('./rentProduct.json'),
  student: require('./student.json'),
  user: require('./user.json'),
  kanbanChecklist: require('./kanbanChecklist.json'),
  kanbanComment: require('./kanbanComment.json'),
  message: require('./message.json'),
  messageWithReceiver: require('./messageWithReceiver.json'),
  company: require('./company.json'),
  project: require('./project.json'),
  qualityIssue: require('./qualityIssue.json'),
  product: require('./product.json'),
};

const modals: IMenu[] = [
  {
    name: 'modal.company',
    desc: '업체',
    slide: 'v2-',
  },
  {
    name: 'modal.project',
    desc: '프로젝트',
    slide: 'v2-',
  },
  {
    name: 'modal.product',
    desc: '제품',
    slide: 'v2-',
  },
  {
    name: 'modal.qualityIssue',
    desc: '품질이슈',
    slide: 'v2-',
  },
  {
    name: 'modal.personWithCompany',
    desc: '업체_담당자',
    slide: 'v1-g3ee5744d2d_0_36',
  },
  {
    name: 'modal.part',
    desc: '자재',
    slide: 'v1-g412100e07d_1_1',
    url: '/v1/part/order/:id',
  },
  {
    name: 'modal.equipment',
    desc: '임대 장비',
    slide: 'v1-g416fdd93a0_0_114',
  },
  {
    name: 'modal.equipmentList',
    desc: '임대 장비',
    slide: 'v2-',
  },
  {
    name: 'modal.educationProduct',
    desc: '교육 장비',
    slide: 'v1-',
  },
  {
    name: 'modal.rentProduct',
    desc: '임대 품목',
    slide: 'v1-g4811cdd0de_0_72',
  },
  {
    name: 'modal.rentPeriod',
    desc: '임대 기간',
    slide: 'v1-g416fdd93a0_0_200',
  },
  {
    name: 'modal.rentAmount',
    desc: '임대 금액',
    slide: 'v1-g416fdd93a0_0_200',
  },
  {
    name: 'modal.deliveryProduct',
    desc: '납품 대상 선택',
    slide: 'v1-g4be4dc2159_1_0',
    url: '/v1/deliveryPack/:id',
  },
  {
    name: 'modal.calculate',
    desc: '정산 대상 선택',
    slide: 'v1-g498d505431_2_35',
  },
  {
    name: 'modal.student',
    desc: '교육생 선택',
    slide: 'v1-g4811cdd0de_0_0',
  },
  {
    name: 'modal.user',
    desc: '사용자 선택',
    slide: 'v1-g4988bf631c_0_7',
  },
  {
    name: 'modal.address',
    desc: '주소',
    slide: 'v1-g416fdd93a0_0_20',
  },
  {
    name: 'modal.companyPerson',
    desc: '담당자',
    slide: 'v1-p54',
    url: '/v1/company/:id',
  },
  {
    name: 'modal.auto',
    desc: '테이블 추출',
    slide: 'v1-',
    url: '/v1/rental/:id',
  },
];
export default modals;
