import * as React from 'react';
import { observer } from 'mobx-react';
import { IMixedComp } from 'stores/ConfigStore';
import { Row } from 'antd';
import { IInjectedType, Omit } from 'common/@types';
import { splitByAlign } from 'components';

interface IFooterProps extends IInjectedType {
  config: IMixedComp[] | undefined;
  data?: object;
  column?: any[];
  pageUuid: string;
}
type ExposedProps = Omit<IFooterProps, keyof IInjectedType> & Partial<IInjectedType>;

@observer
class Footer extends React.Component<IFooterProps> {
  render() {
    if (!this.props.config) {
      return null;
    }

    const { leftCols, centerCols, rightCols } = splitByAlign(this.props);
    return (
      <Row justify="space-between" style={{ marginTop: '10px' }}>
        <Row gutter={10} align="bottom">
          {leftCols}
        </Row>
        <Row gutter={10} align="bottom">
          {centerCols}
        </Row>
        <Row gutter={10} align="bottom">
          {rightCols}
        </Row>
      </Row>
    );
  }
}

export default Footer as React.ComponentType<ExposedProps>;
