import * as React from 'react';
import { IMenuProps, TMenu, TMenuContainer } from 'stores/ConfigStore';
import { Drawer, Menu as AntMenu } from 'antd';
import { i18nFromJSON } from 'components';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { IInjectedStore, IInjectedType, Omit } from '../../common/@types';

type IMenuPropsWithRouter = IMenuProps & RouteComponentProps & IInjectedType & IInjectedStore;

type ExposedProps = Omit<IMenuPropsWithRouter, keyof (RouteComponentProps & IInjectedType & IInjectedStore)> &
  Partial<RouteComponentProps & IInjectedType & IInjectedStore>;

const AntDrawer = styled(Drawer)`
  &.ant-drawer {
    top: 40px;
  }

  .ant-drawer-content {
    max-height: calc(100% - 40px);
  }

  .ant-drawer-body {
    padding: 0 !important;
  }
`;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Menu extends React.Component<IMenuPropsWithRouter> {
  @computed
  get menuItems(): ItemType[] {
    const { me, isLoggedIn } = this.props.appStore.userStore;
    if (!isLoggedIn) {
      return [];
    }

    const { history, hideMenu } = this.props;
    const allowMenu: string[] = (me && me.allowMenu) || [];
    const isLimitMenu = !!allowMenu.length;

    function menuToItem(_menu: TMenu) {
      return {
        key: _menu.name,
        label: i18nFromJSON(_menu.name),
        onClick: () => {
          hideMenu && hideMenu();

          if (_menu.path) {
            if (history.location.pathname.indexOf(_menu.path) > -1) {
              window.location.href = _menu.path;
            } else {
              history.push(_menu.path);
            }
          }
        },
      };
    }

    function objectToMenu(_menus: TMenuContainer): ItemType[] {
      return Object.keys(_menus)
        .map(key => {
          const _menu = _menus[key];
          return {
            key,
            label: i18nFromJSON(key),
            children: Array.isArray(_menu)
              ? _menu
                  .filter(
                    ({ hidden, isPublic, name }) =>
                      !hidden && !isPublic && (!isLimitMenu || allowMenu.find(m => m === name)),
                  )
                  .map(menuToItem)
              : objectToMenu(_menu),
          };
        })
        .filter(m => m.children.length);
    }

    return objectToMenu(this.props.menu || {});
  }

  render() {
    return (
      <AntDrawer placement="left" width="200" open={this.props.appear} closable={false} onClose={this.props.hideMenu}>
        <AntMenu
          defaultSelectedKeys={this.props.defaultSelectedKeys}
          defaultOpenKeys={this.props.defaultOpenKeys}
          mode="inline"
          className={this.props.className}
          style={{
            ...this.props.style,
            // transition: 'margin 0.5s',
            // marginLeft: this.props.appear ? '0' : '-210px',
            // borderRight: '1px solid black',
            // borderBottom: '1px solid black',
            border: 'none',
            width: '100%',
          }}
          items={this.menuItems}
        />
      </AntDrawer>
    );
  }
}

export default withRouter(Menu) as React.ComponentType<ExposedProps>;
