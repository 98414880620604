import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { IMemo } from 'stores/ConfigStore';
import { IUser } from 'stores/UserStore';
import { Input as AntInput } from 'antd';
import { AntFormItem, getDataValue } from 'components';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';

const AntMemo = AntInput.TextArea;
interface IMemoProps extends IInjectedType, IInjectedStore, IFormItemProps {
  config: IMemo;
  data: IUser;
  pageUuid: string;
}
type ExposedProps = Omit<IMemoProps, keyof (IInjectedType & IInjectedStore)> & Partial<IInjectedType & IInjectedStore>;

@inject('i18nStore')
@observer
class Memo extends React.Component<IMemoProps> {
  render() {
    const {
      config,
      data,
      form,
      i18nStore: { t },
    } = this.props;
    const { cType, dataIndex, defaultValue, placeholder, rows, style, isRequire } = config;

    if (cType !== 'memo') {
      return null;
    }

    const fieldValue = getDataValue(data, dataIndex) || defaultValue;

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          initialValue={fieldValue}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <AntMemo
            id={dataIndex}
            placeholder={placeholder}
            rows={rows}
            style={toJS(style)}
            autoSize={{ minRows: 2, maxRows: 10 }}
          />
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default Memo as React.ComponentType<ExposedProps>;
