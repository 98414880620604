import * as React from 'react';
import styled from 'styled-components';
import { api } from 'providers';
import { IActionAPIRequest, ITableChartConfig } from 'stores/ConfigStore';
import { IReactionDisposer, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { IDictionary } from 'stores/KeysStore';
import { Col, Row, Spin, Table } from 'antd';
import { isEqual } from 'lodash';
import { Footer, Header } from 'components/Layout';
import { ColumnsType } from 'antd/es/table';
import { i18nFromJSON } from '../index';

interface ITableChartProps extends IInjectedType, IInjectedStore {
  config: ITableChartConfig;
  pageUuid: string;
}
type ExposedProps = Omit<ITableChartProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

const Container = styled.div`
  /* add css here */
`;

interface ITableChartData {
  codes: { code: string; name: string }[];
  rows: Record<string, any>[];
  recentDate: string;
}

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class TableChart extends React.Component<ITableChartProps> {
  @observable spinning = false;
  @observable remoteData: ITableChartData = { codes: [], rows: [], recentDate: '' };

  searchReaction: IReactionDisposer | undefined;
  prevSearch: any;

  componentDidMount() {
    this.getData();

    this.searchReaction = this.props.keyStore.addReaction(this.props.pageUuid, 'search', (_search: IDictionary) => {
      const search = toJS(_search || {}) || {};
      console.log('PieChart search reaction / search', search);

      if (!isEqual(search, this.prevSearch || {})) {
        this.prevSearch = search;
        this.getData();
      }
    });
  }

  componentWillUnmount() {
    this.searchReaction && this.searchReaction(); // dispose reaction
  }

  async getData() {
    const { request = {} as IActionAPIRequest } = this.props.config;

    if (request.url) {
      try {
        this.spinning = true;

        const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);

        const remoteData = await api[request.method || 'get'](
          `${request.url}${request.url.indexOf('?') === -1 ? '?' : '&'}where=${JSON.stringify(search)}`,
        );
        this.remoteData = remoteData.data;
      } finally {
        this.spinning = false;
      }
    }
  }

  render() {
    const { config, form, mode, handleSubmit, pageUuid } = this.props;
    const { cType, header, header2nd, footer, rowId, columnHeadKey, column, style } = config;

    if (cType !== 'tableChart') {
      return null;
    }

    const columns: ColumnsType<any> = [
      {
        title: i18nFromJSON(rowId.text),
        dataIndex: rowId.key,
      },
    ];

    let data: any[] = [];

    if (columnHeadKey) {
      const headKeys: Record<string, string> = {};
      this.remoteData.rows.forEach(row => {
        const headKey = row[columnHeadKey];

        if (!headKeys[headKey]) {
          headKeys[headKey] = headKey;

          columns.push({
            title: headKey,
            children: column.map(col => ({
              title: i18nFromJSON(col.text),
              dataIndex: `${headKey}/${col.key}`,
              key: `${headKey}/${col.key}`,
            })),
          });
        }
      });

      data = this.remoteData.codes.map(code => ({
        key: code.code,
        [rowId.key]: code.name,
      }));

      this.remoteData.rows.forEach(row => {
        const targetData = data.find(d => d.key === row[rowId.key]);
        if (targetData) {
          const headKey = row[columnHeadKey];
          column.forEach(col => {
            targetData[`${headKey}/${col.key}`] = row[col.key];
          });
        }
      });
    } else {
      column.forEach(col => {
        columns.push({ title: i18nFromJSON(col.text), dataIndex: col.key });
      });

      data = this.remoteData.rows;
    }

    return (
      <Spin spinning={this.spinning}>
        <Container>
          <Header config={header} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
          <Header config={header2nd} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
          {this.remoteData.recentDate ? (
            <Row justify="space-between">
              <Col></Col>
              <Col style={{ fontSize: '12px' }}>{`Up to date: ${this.remoteData.recentDate}`}</Col>
            </Row>
          ) : null}
          <Table style={style} columns={columns} dataSource={data} bordered pagination={false} />
          <Footer config={footer} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        </Container>
      </Spin>
    );
  }
}

export default TableChart as React.ComponentType<ExposedProps>;
