import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IList } from 'stores/ConfigStore';
import { Avatar, List as AntList } from 'antd';
import { formatDateString, getDataValue } from 'components';
import { IInjectedType, Omit } from 'common/@types';
import { computed, toJS } from 'mobx';
import { forEach } from 'lodash';

const AntItem = AntList.Item;
const AntItemMeta = AntList.Item.Meta;
interface IItemProps {
  item: any;
  icon?: any;
  pageUuid: string;
}

@observer
class Item extends React.Component<IItemProps> {
  render() {
    const { item, icon } = this.props;
    if (typeof item[0] === 'string') {
      return <AntItem>{item}</AntItem>;
    }
    const title = item.path ? <a href={item.path}>{item.name}</a> : item.name;
    return (
      <AntItem>
        <AntItemMeta avatar={<Avatar src={icon} />} title={title} description={item.description} />
      </AntItem>
    );
  }
}

interface IListProps extends IInjectedType {
  config: IList;
  data?: any;
  pageUuid: string;
}
type ExposedProps = Omit<IListProps, keyof IInjectedType> & Partial<IInjectedType>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class List extends React.Component<IListProps> {
  @computed
  get listData() {
    const { config } = this.props;
    const { dataIndex } = config;

    let data: any = {};
    if (dataIndex) {
      let value = toJS(getDataValue(this.props.data, dataIndex) || []);
      if (!Array.isArray(value)) {
        value = [value];
      }
      // console.log('table render value', value);
      formatDateString(value);
      data = {
        rows: value,
        page: 1,
        pageSize: 100,
        total: value.length,
      };
    } else if (this.props.data) {
      data = { ...toJS(this.props.data) };
      formatDateString(data.rows);
    }

    if (data.rows && data.rows.length > 0) {
      data.rows = data.rows.filter((row: any) => !row.delete);
      data.total = data.rows.length;
    }

    forEach(data.rows, row => {
      if (!row._id) {
        row._id = row.id || `${new Date().getTime()}${Math.random()}`;
      }
    });

    return data;
  }

  getDataRow(id: string) {
    const { config } = this.props;
    const { dataIndex } = config;

    let rows: any[] = [];
    if (dataIndex) {
      const value = getDataValue(this.props.data, dataIndex);
      rows = value || [];
    } else if (this.props.data) {
      rows = this.props.data.rows;
    }

    return rows.find(row => row.id === id);
  }

  render() {
    const { config, pageUuid } = this.props;
    const { cType, dataIndex, attr } = config;

    if (cType !== 'list') {
      return null;
    }

    const data = this.listData;

    return (
      <AntList
        {...attr}
        rowKey="_id"
        dataSource={data.rows}
        renderItem={(item: any) => <Item item={item} pageUuid={pageUuid} />}
      />
    );
  }
}

export default List as React.ComponentType<ExposedProps>;
