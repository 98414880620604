import * as React from 'react';
import { Form } from 'antd';
import { FormInstance, FormItemProps } from 'antd/es/form';
import { observer } from 'mobx-react';

const FormItem = Form.Item;

export interface IAntFormItemProps extends FormItemProps {
  form: React.RefObject<FormInstance>;
}

@observer
class AntFormItem extends React.Component<IAntFormItemProps> {
  initialValue: any;

  render() {
    // TODO: componentDidUpdate 가 안타서...
    if (this.initialValue !== this.props.initialValue) {
      const { form, name } = this.props;
      this.initialValue = this.props.initialValue;
      setTimeout(() => {
        name && form.current?.setFieldValue(name, this.initialValue);
      }, 0);
    }

    const newProps: any = { ...this.props };
    delete newProps.form;

    return <FormItem {...newProps} />;
  }
}

export default AntFormItem as React.ComponentType<IAntFormItemProps>;
