import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { IInput } from 'stores/ConfigStore';
import { IUser } from 'stores/UserStore';
import { Input as AntInput } from 'antd';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { AntFormItem } from 'components';

interface IPasswordProps extends IInjectedType, IInjectedStore, IFormItemProps {
  config: IInput;
  data: IUser;
  pageUuid: string;
}
type ExposedProps = Omit<IPasswordProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

@inject('i18nStore')
@observer
class Password extends React.Component<IPasswordProps> {
  render() {
    const {
      config,
      form,
      i18nStore: { t },
    } = this.props;
    const { cType, placeholder, dataIndex, style, isRequire } = config;

    if (cType !== 'password') {
      return null;
    }

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <AntInput type="password" placeholder={placeholder} style={toJS(style)} />
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default Password as React.ComponentType<ExposedProps>;
