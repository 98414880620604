import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IInput } from 'stores/ConfigStore';
import { IUser } from 'stores/UserStore';
import { Input as AntInput } from 'antd';
import { AntFormItem, getDataValue } from 'components';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { set } from 'lodash';
import { toJS } from 'mobx';

const AntInputGroup = AntInput.Group;

interface IEmailProps extends IInjectedType, IInjectedStore, IFormItemProps {
  config: IInput;
  data: IUser;
  pageUuid: string;
}
type ExposedProps = Omit<IEmailProps, keyof (IInjectedType & IInjectedStore)> & Partial<IInjectedType & IInjectedStore>;

interface IEmailInputProps {
  value?: string;
  onChange?: (value: string) => void;

  style?: React.CSSProperties;
}

interface IEmailInputState {
  initialValue: string;
  address: string;
  server: string;
}

class EmailInput extends React.Component<IEmailInputProps, IEmailInputState> {
  state = { initialValue: '', address: '', server: '' };

  static getDerivedStateFromProps(props: IEmailInputProps, state: IEmailInputState) {
    if ('value' in props) {
      const initialValue = props.value + '';

      if (state.initialValue === initialValue) {
        return null;
      }

      const [address = '', server = ''] = initialValue.split('@');

      return {
        initialValue,
        address,
        server,
      };
    }
    return null;
  }

  handleChange = (e: any, type: 'address' | 'server') => {
    const value = e.target.value;

    const newState: any = { ...this.state };
    newState[type] = value;

    if (!('value' in this.props)) {
      this.setState(newState as any);
    }

    this.triggerChange(newState);
  };

  triggerChange = ({ address, server }: IEmailInputState) => {
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(`${address}@${server}`);
    }
  };

  render() {
    return (
      <AntInputGroup style={toJS(this.props.style)} compact>
        <AntInput
          style={{ width: '47%' }}
          value={this.state.address}
          onChange={(e: any) => this.handleChange(e, 'address')}
        />
        <AntInput
          style={{
            width: '6%',
            borderLeft: 0,
            paddingLeft: 0,
            paddingRight: 0,
            pointerEvents: 'none',
            backgroundColor: '#fff',
          }}
          placeholder="@"
          disabled
        />
        <AntInput
          style={{ width: '47%', borderLeft: 0 }}
          value={this.state.server}
          onChange={(e: any) => this.handleChange(e, 'server')}
        />
      </AntInputGroup>
    );
  }
}

@inject('i18nStore')
@observer
class Email extends React.Component<IEmailProps> {
  onChange = (value: string) => {
    console.log('onChange Email', value);
    if (this.props.config.dataIndex) {
      set(this.props.data, this.props.config.dataIndex, value);
    }
  };

  render() {
    const {
      config,
      data,
      form,
      i18nStore: { t },
    } = this.props;
    const { cType, dataIndex, style, isRequire } = config;

    if (cType !== 'email') {
      return null;
    }

    const initialValue = getDataValue(data, dataIndex) || '';

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          initialValue={initialValue}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <EmailInput value={initialValue} onChange={this.onChange} style={style} />
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default Email as React.ComponentType<ExposedProps>;
