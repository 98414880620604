import * as React from 'react';
import { observer, Provider as MobxProvider } from 'mobx-react';
import { appStoreInstance, i18nStoreInstance, keyStoreInstance } from '.';

const StoreProvider = observer((props: any) => {
  return (
    <MobxProvider
      appStore={appStoreInstance.get()}
      i18nStore={i18nStoreInstance.get()}
      keyStore={keyStoreInstance.get()}
    >
      {props.children}
    </MobxProvider>
  );
});

if (process.env.NODE_ENV === 'development') {
  (window as any).appStore = appStoreInstance.get();
  (window as any).i18nStore = i18nStoreInstance.get();
  (window as any).keyStore = keyStoreInstance.get();
}

(window as any).__store = appStoreInstance.get();

export default StoreProvider;
