import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { IMenuProps, TMenu, TMenuContainer } from 'stores/ConfigStore';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Menu as MenuComp } from 'components/Layout';
import styled from 'styled-components';
import { matchPath, RouteComponentProps, withRouter } from 'react-router';
import { IInjectedStore, Omit } from 'common/@types';
import { menu } from 'mocks/menu';

const slideV1 =
  'https://docs.google.com/presentation/d/1ufrjVcKpnUarSuO3DnuW2rr9hEWAnoI6YkxBzLapiXQ/mobilepresent?slide=id.';

const slideV2 =
  'https://docs.google.com/presentation/d/15demoldVWPQb92Erzmp7LfC-kG2IHiLFljuYIdtwLRo/mobilepresent?slide=id.';

const HeaderRight = styled.div`
  float: right;
  height: 100%;
  margin-right: 10px;
`;

const MenuWrap = styled.div`
  height: 40px;
  background-color: #1890ff;
  border-color: #1890ff;
`;

const StyledMenuComp = styled(MenuComp)``;

interface IProps extends IMenuProps, RouteComponentProps, IInjectedStore {}
type ExposedProps = Omit<IProps, keyof (RouteComponentProps & IInjectedStore)> &
  Partial<RouteComponentProps & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Menu extends React.Component<IProps> {
  @observable appear: boolean = false;
  @observable slide: string = '';

  constructor(props: IProps) {
    super(props);
    this.findMatchMenu(menu);
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.findMatchMenu(menu);
    }
  }

  toggleCollapsed = () => {
    const { isLoggedIn } = this.props.appStore.userStore;

    if (!isLoggedIn) {
      this.props.history.push('/login');
      return;
    }

    this.appear = !this.appear;
  };

  hideMenu = () => {
    this.appear = false;
  };

  logout = async () => {
    await this.props.appStore.userStore.logout();
    this.appear = false;
    this.props.history.push('/');
  };

  findMatchMenu(_menus: TMenuContainer) {
    Object.values(_menus).forEach(_menu => {
      if (Array.isArray(_menu)) {
        _menu
          .slice()
          .reverse()
          .forEach((subMenu: TMenu) => {
            const { path, slide } = subMenu;

            if (path && slide) {
              const match = matchPath(this.props.location.pathname, { path });
              if (match) {
                this.slide = slide;
              }
            }
          });
      } else {
        this.findMatchMenu(_menu);
      }
    });
  }

  moveSlide = () => {
    if (this.slide.indexOf('v1-') > -1) {
      window.location.href = `${slideV1}${this.slide.replace('v1-', '')}`;
    }
    if (this.slide.indexOf('v2-') > -1) {
      window.location.href = `${slideV2}${this.slide.replace('v2-', '')}`;
    }
  };

  render() {
    console.log('slide', this.slide);
    return (
      <>
        <HeaderRight>
          {this.props.appStore.userStore.me && this.slide && (
            <Button size="small" ghost onClick={this.moveSlide} style={{ marginRight: '5px' }}>
              bug
            </Button>
          )}
          {this.props.appStore.userStore.me && (
            <Button size="small" ghost onClick={this.logout}>
              logout
            </Button>
          )}
        </HeaderRight>
        <MenuWrap>
          <Button style={{ height: '40px' }} type="primary" onClick={this.toggleCollapsed}>
            {this.appear ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>

          <StyledMenuComp menu={this.props.menu} appear={this.appear} hideMenu={this.hideMenu} />
        </MenuWrap>
      </>
    );
  }
}

export default withRouter(Menu) as React.ComponentType<ExposedProps>;
