import api from './axios';
import { IUser } from 'stores/UserStore';

type IApiError = {
  type?: string;
  code?: number;
  message?: string;
  requiredKey?: string;
};

type IApiPage = {
  totalPages: number;
  totalElements: number;
  currentPage: number;
  pageSize: number;
};

interface IApiResponse<Data> {
  status?: number;
  redirect?: string;
  message?: string;
  error?: IApiError;
  page?: IApiPage;
  list?: Data[];
}

const ApiProvider = {
  CommonRequest: {},

  AuthRequest: {
    async login(email: string | undefined, password: string | undefined) {
      const { data } = await api.post(`/login/agru`, { email, password });
      return data as IUser;
    },
    async logout() {
      const { data } = await api.get(`/logout`);
      return data as IUser;
    },
    async me(token?: string) {
      const { data } = await api.get(`/v1/user/me`, token ? { headers: { authorization: token } } : {});
      return data as IUser;
    },
    async update(body: IUser) {
      const { data } = await api.put(`/v1/user`, body);
      return data as IApiResponse<IUser>;
    },
  },
};

export default ApiProvider;
