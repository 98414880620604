import * as React from 'react';
import styled from 'styled-components';
import 'assets/devices.min.css';
import AppStoreSpin from './AppStoreSpin';

const Container = styled.div`
  /* css here */
`;

const Screen = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

const AndroidFrame: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Container>
    <div className="marvel-device note8">
      <div className="inner" />
      <div className="overflow">
        <div className="shadow" />
      </div>
      <div className="speaker" />
      <div className="sensors" />
      <div className="more-sensors" />
      <div className="sleep" />
      <div className="volume" />
      <div className="camera" />
      <div className="screen">
        <Screen>
          <AppStoreSpin>{children}</AppStoreSpin>
        </Screen>
      </div>
    </div>
  </Container>
);

export default AndroidFrame;
