import * as React from 'react';
import { ILocale } from 'stores/ConfigStore';
import { i18nStoreInstance, II18nStore } from 'stores';
import { isNumber } from 'lodash';

function i18nFromJSON(field: string | ILocale | undefined): any {
  const i18nStore: II18nStore | null = i18nStoreInstance.get();
  if (!i18nStore) {
    return;
  }
  const { language, L } = i18nStore;

  let str: string = '';
  if (isNumber(field)) {
    return `${field || ''}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (typeof field === 'string') {
    str = L(field) || field || '';
  } else if (field && typeof field === 'object') {
    str = field[language] || field || '';
  } else {
    str = field || '';
  }

  const splited = str.split('\n');

  if (splited.length === 1) {
    return splited[0];
  }
  return splited.map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
}

export default i18nFromJSON;
