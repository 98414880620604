import * as React from 'react';
import { CSSProperties } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button, Card, Checkbox, Col, Image, Row } from 'antd';
import { UploadFile, UploadFileStatus, UploadListType } from 'antd/lib/upload/interface';

const ImageWrap = styled.div`
  .ant-image {
    width: 102px;
    height: 102px;
    overflow: hidden;
    margin: 0 auto;
  }
`;

const AntImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Buttons = styled.div`
  flex: 1;
  flex-direction: row;
  position: absolute;
  z-index: 1;
  min-width: 40px;
  min-height: 40px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 5px;
  vertical-align: top;

  .ant-checkbox {
    font-size: 0;
  }
`;

const DelButton = styled(Button)`
  margin: 5px;
  vertical-align: top;
`;

const StyledCard = styled(Card)`
  margin-top: 8px;

  .ant-card-body {
    padding: 8px;
  }
`;

const FileName = styled.span`
  margin: 5px;
  vertical-align: text-top;
`;

export interface FileRow<T = any> extends UploadFile<T> {
  division: string;
  status: UploadFileStatus;
  url: string;
  folder: string;
}

export interface UploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: FileRow;
  fileList: FileRow[];
  listType: UploadListType;
  onRemove?: (file: FileRow) => void | boolean | Promise<void | boolean>;
  onPreview?: (file: FileRow) => void;
  onCheck?: (file: FileRow, checked: boolean) => void;
}

export const UploadListItem = ({
  originNode,
  file,
  fileList,
  listType,
  onRemove,
  onPreview,
  onCheck,
}: UploadListItemProps) => {
  if (listType === 'picture-card') {
    const style: CSSProperties = {};
    if (onPreview) {
      style.cursor = 'pointer';
    }

    return (
      <ImageWrap style={style} onClick={() => onPreview?.(file)}>
        <Buttons onClick={e => e.stopPropagation()}>
          {onCheck ? <StyledCheckbox onChange={e => onCheck?.(file, e.target.checked)} /> : null}
          {onRemove ? (
            <DelButton
              onClick={e => {
                e.stopPropagation();
                onRemove?.(file);
              }}
              size="small"
              icon={<DeleteOutlined />}
            />
          ) : null}
        </Buttons>
        <AntImage src={file.url} preview={false} />
      </ImageWrap>
    );
  }

  return (
    <StyledCard>
      <Row>
        {onCheck ? (
          <Col>
            <StyledCheckbox onChange={e => onCheck?.(file, e.target.checked)} />
          </Col>
        ) : null}
        {onRemove ? (
          <Col>
            <DelButton
              onClick={e => {
                e.stopPropagation();
                onRemove?.(file);
              }}
              size="small"
              icon={<DeleteOutlined />}
            />
          </Col>
        ) : null}
        <Col>
          <FileName>
            <a onClick={() => onPreview?.(file)}>{file.name}</a>
          </FileName>
        </Col>
      </Row>
    </StyledCard>
  );
};
