import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ErrorBoundary, NoMatch, PrivateRoute } from 'components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { menu } from 'mocks/menu';
import { IContainer, TMenuContainer } from 'stores/ConfigStore';
import PageFromMenu from 'pages/Menu/PageFromMenu';
import Privacy from 'pages/Terms/Privacy';
import { findConfig, getPath } from 'helper/storybook';
import Menu from 'pages/Menu/Menu';
import { Layout } from 'antd';
import styled from 'styled-components';
import './App.css';
import AppStoreSpin from 'components/Common/AppStoreSpin';
import Login from 'pages/Login/Login';
import { IInjectedStore, Omit } from 'common/@types';

declare global {
  interface Window {
    ReactNativeWebView?: any;
  }
}

const PageLayout = styled(Layout)`
  &.ant-layout {
    height: 100%;
    min-height: 100%;
    background-color: white;
  }
`;

const Header = styled(Layout.Header)`
  &.ant-layout-header {
    height: 40px;
    padding: 0;
    line-height: 40px;
    z-index: 10;
  }
`;

const Content = styled(Layout.Content)`
  &.ant-layout-content {
    padding: 10px;
    overflow: auto;
    background-color: white;
    width: 100%;
    height: calc(100% - 40px);
    min-height: calc(100% - 40px);
  }
`;

const Footer = styled(Layout.Footer)`
  &.ant-layout-footer {
    text-align: center;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 11px;
    background-color: white;
  }
`;

interface IProps extends IInjectedStore {}
type ExposedProps = Omit<IProps, keyof IInjectedStore> & Partial<IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class App extends React.Component<IProps> {
  menu: React.ReactNode[] = [];

  constructor(props: IProps) {
    super(props);

    window.addEventListener('message', this.onMessage);
    document.addEventListener('message', this.onMessage);

    const menuToRoute = (_menus: TMenuContainer) => {
      Object.values(_menus).forEach(_menu => {
        if (Array.isArray(_menu)) {
          _menu
            .slice()
            .reverse()
            .forEach(subMenu => {
              const { name, path, url } = subMenu;
              const config: IContainer[] = findConfig(getPath(name));

              if (path) {
                this.menu.push(
                  <Route path={path} key={name}>
                    {path.indexOf('/public/') > -1 ? (
                      <PageFromMenu key={name} title={name} url={url} path={path} config={config} />
                    ) : (
                      <PrivateRoute>
                        <PageFromMenu key={name} title={name} url={url} path={path} config={config} />
                      </PrivateRoute>
                    )}
                  </Route>,
                );
              }
            });
        } else {
          menuToRoute(_menu);
        }
      });
    };

    menuToRoute(menu);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
    document.removeEventListener('message', this.onMessage);
  }

  onMessage = (e: any) => {
    if (typeof e.data === 'string') {
      try {
        const evt = JSON.parse(e.data);

        if (typeof evt === 'object') {
          const { event, data }: { event: string; data: Record<string, any> } = evt;

          switch (event) {
            case 'login':
              const token: string = data.token;
              this.props.appStore.userStore.getMe(token);
              break;
            case 'locale':
              const locale: string = data.locale;
              this.props.i18nStore.changeLanguageTo(locale);
              break;
          }
        }
      } catch (e) {}
    }
  };

  render() {
    return (
      <ErrorBoundary>
        <BrowserRouter>
          <PageLayout>
            {window.ReactNativeWebView ? null : (
              <Header>
                <Menu menu={menu} />
              </Header>
            )}
            <Content>
              <AppStoreSpin>
                <Switch>
                  {this.menu}
                  <Route component={Login} path="/login" />
                  <Route component={Privacy} path="/privacy" />
                  <Route component={NoMatch} path="/*" />
                </Switch>
                <Footer>ⓒ 2018-2023. AGRU Korea Co,.Ltd All rights reserved.</Footer>
              </AppStoreSpin>
            </Content>
          </PageLayout>
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

export default App as React.ComponentType<ExposedProps>;
