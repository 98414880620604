import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import { IMemo } from 'stores/ConfigStore';
import { IUser } from 'stores/UserStore';
import { Input as AntInput, Select as AntSelect, Spin } from 'antd';
import { AntFormItem, getDataValue } from 'components';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import styled from 'styled-components';
import { api } from 'providers';

const AntOption = AntSelect.Option;
const AntMemo = AntInput.TextArea;

const SelectInput = styled(AntSelect)`
  &.ant-select {
    min-width: 140px;
  }
`;

interface IMessageProps extends IInjectedType, IInjectedStore, IFormItemProps {
  config: IMemo;
  data: IUser;
  pageUuid: string;
}
type ExposedProps = Omit<IMessageProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

type ISelectState = {
  remoteData: any;
};

@inject('i18nStore')
@observer
class Message extends React.Component<IMessageProps, ISelectState> {
  state = {
    remoteData: [],
  };

  @observable spinning = false;

  componentDidMount() {
    this.getRemoteData();
  }

  componentDidUpdate(prevProps: IMessageProps) {
    if (this.props.config.url !== prevProps.config.url) {
      this.getRemoteData();
    }
  }

  async getRemoteData() {
    const { url } = this.props.config;
    if (url) {
      try {
        this.spinning = true;

        const remoteData = await api.get(`${url}${url.indexOf('?') === -1 ? '?' : '&'}pageSize=100000000`);
        this.setState({ remoteData: remoteData.data.rows });
      } finally {
        this.spinning = false;
      }
    }
  }

  handleSelectChange = (value: string): void => {
    console.log(`selected ${value}`);
    const { dataIndex } = this.props.config;
    if (dataIndex) {
      this.props.form.current?.setFieldValue(dataIndex, value);
    }
  };

  render() {
    const { remoteData } = this.state;
    const {
      config,
      data,
      form,
      i18nStore: { t },
    } = this.props;
    const { cType, dataIndex, defaultValue, placeholder, rows, style, isRequire, option, url, keys } = config;

    if (cType !== 'message') {
      return null;
    }

    const fieldValue = getDataValue(data, dataIndex);

    let _options = option || [];
    console.log('_options', _options);
    if (remoteData.length > 0 && keys) {
      _options = remoteData.map((_data: any) => ({
        text: _data[keys.text],
        value: _data[keys.value],
      }));
      console.log('_options from remoteData', _options);
    }
    const options = [
      <AntOption value={''} key="blank">
        {placeholder || '-'}
      </AntOption>,
    ];

    for (const _option of _options) {
      const { text, value, selected } = _option;
      options.push(
        <AntOption value={`${value || text}`} key={value + '' || text}>
          {text || value}
        </AntOption>,
      );
    }

    return (
      <Spin spinning={this.spinning}>
        {option || (url && keys) ? (
          <SelectInput onChange={(value: any) => this.handleSelectChange(value)} placeholder={placeholder}>
            {options}
          </SelectInput>
        ) : null}
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          initialValue={`${fieldValue || defaultValue || ''}`}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <AntMemo
            id={dataIndex}
            placeholder={placeholder}
            rows={rows}
            style={toJS(style)}
            autoSize={{ minRows: 2, maxRows: 10 }}
          />
        </AntFormItem>
        {this.props.children}
      </Spin>
    );
  }
}

export default Message as React.ComponentType<ExposedProps>;
