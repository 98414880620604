import * as React from 'react';
import styled from 'styled-components';
import 'assets/devices.min.css';
import AppStoreSpin from './AppStoreSpin';

const Container = styled.div`
  /* css here */
`;

const Screen = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

const IPhoneFrame: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Container>
    <div className="marvel-device iphone8 silver">
      <div className="top-bar" />
      <div className="sleep" />
      <div className="volume" />
      <div className="camera" />
      <div className="sensor" />
      <div className="speaker" />
      <div className="screen">
        <Screen>
          <AppStoreSpin>{children}</AppStoreSpin>
        </Screen>
      </div>
      <div className="home" />
      <div className="bottom-bar" />
    </div>
  </Container>
);

export default IPhoneFrame;
