import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { IConfigComp } from 'stores/ConfigStore';
import { getDataValue } from 'components';

interface IImageProps {
  config: IConfigComp;
  data: { result: any };
  pageUuid: string;
}

const Container = styled.div``;

@observer
class Image extends React.Component<IImageProps> {
  render() {
    const { config, data } = this.props;
    const { cType, dataIndex, attr, style } = config;
    const imageSources = (data && getDataValue(data, dataIndex)) || null;

    if (cType !== 'image' || !imageSources) {
      return null;
    }

    const images = imageSources.map((src: string, key: number) => (
      <img src={src} style={{ width: '100%', ...style }} {...attr} key={`${cType}-${dataIndex}-${key}`} />
    ));

    return <Container>{images}</Container>;
  }
}

export default Image;
