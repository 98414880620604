import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { cloneDeep, forEach } from 'lodash';
import { IList, IButton, IModalProps, EModalMode, TModalForCaller } from 'stores/ConfigStore';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { List as AntList, Checkbox as AntCheckbox, Row, Col } from 'antd';
import { getDataValue, formatDateString } from 'components';
import { MODAL_TYPE_AUTO } from 'components/constants';
import { default as ModalSelector } from 'components/Common/ModalSelector';
import { IInjectedType, Omit } from 'common/@types';
import { computed, toJS } from 'mobx';

const AntItem = AntList.Item;

interface IItemProps {
  item: any;
  icon?: any;
  pageUuid: string;
  onToggle?: (item: any, checked: boolean) => any;
  onRemove?: (item: any) => any;
  onEdit?: (item: any) => any;
}

@observer
class CheckItem extends React.Component<IItemProps> {
  render() {
    const { item } = this.props;
    const title = item.path ? <a href={item.path}>{item.name}</a> : item.name;

    return (
      <AntItem style={{ borderBottom: 'none', marginBottom: -16 }}>
        {item && (
          <Row justify="space-between" style={{ width: '100%' }}>
            <Col>
              <AntCheckbox
                checked={!!item.isComplete}
                onChange={e => this.props.onToggle && this.props.onToggle(item, e.target.checked)}
              >
                {title}
              </AntCheckbox>
            </Col>
            <Col>
              {this.props.onEdit ? (
                <EditOutlined
                  onClick={() => this.props.onEdit && this.props.onEdit(item)}
                  style={{ marginRight: '10px' }}
                />
              ) : null}
              {this.props.onRemove ? (
                <CloseOutlined onClick={() => this.props.onRemove && this.props.onRemove(item)} />
              ) : null}
            </Col>
          </Row>
        )}
      </AntItem>
    );
  }
}

interface ICheckListProps extends RouteComponentProps, IInjectedType {
  config: IList & IButton;
  data?: any;
  pageUuid: string;
}
type ExposedProps = Omit<ICheckListProps, keyof IInjectedType> & Partial<IInjectedType>;

interface ICheckListState {
  modalVisible: boolean;
  editIndex: number | undefined;
}

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class CheckList extends React.Component<ICheckListProps, ICheckListState> {
  state = {
    modalVisible: false,
    editIndex: undefined,
  };

  getDataRows() {
    const { config } = this.props;
    const { dataIndex } = config;

    let rows: any[] = [];
    if (dataIndex) {
      const value = getDataValue(this.props.data, dataIndex);
      rows = value || [];
    } else if (this.props.data) {
      rows = this.props.data.rows;
    }

    if (!Array.isArray(rows)) {
      rows = [rows];
    }

    return rows;
  }

  getDataRow(id: string) {
    return this.getDataRows().find(row => row._id === id || row.id === id);
  }

  @computed
  get listData() {
    const { config } = this.props;
    const { dataIndex } = config;

    let data: any = {};
    if (dataIndex) {
      let value = toJS(getDataValue(this.props.data, dataIndex) || []);
      if (!Array.isArray(value)) {
        value = [value];
      }
      // console.log('table render value', value);
      formatDateString(value);
      data = {
        rows: value,
        page: 1,
        pageSize: 100,
        total: value.length,
      };
    } else if (this.props.data) {
      data = { ...toJS(this.props.data) };
      formatDateString(data.rows);
    }

    if (data.rows && data.rows.length > 0) {
      data.rows = data.rows.filter((row: any) => !row.delete);
      data.total = data.rows.length;
    }

    forEach(data.rows, row => {
      if (!row._id) {
        row._id = row.id || `${new Date().getTime()}${Math.random()}`;
      }
    });

    return data;
  }

  openModal = (): void => this.setState({ modalVisible: true });
  closeModal = (): void => {
    const rows = this.getDataRows().filter((row: any) => row.editing);
    rows.forEach(row => (row.editing = false));

    this.setState({ modalVisible: false, editIndex: undefined });
  };

  render() {
    const { config, pageUuid } = this.props;
    const { cType, attr, modal } = config;

    let ModalComponent: any = null;
    if (modal) {
      const modalProps: IModalProps = (typeof modal === 'string' ? { mType: modal } : modal) as IModalProps;
      const close = (): void => this.closeModal();
      const onCancel = (): void => close();
      const onOk = (): void => close();

      if (modalProps.mType === MODAL_TYPE_AUTO) {
        modalProps.mode = EModalMode.NEW;
        modalProps.target = this.props.config.source;
        const column = cloneDeep(this.props.config.column || []);
        column.forEach(col => {
          if (col.modal) {
            if (typeof col.modal === 'string') {
              col.modal = {
                mType: col.modal,
                target: this.props.config.source,
              } as TModalForCaller;
            } else if (col.modal) {
              col.modal.target = this.props.config.source;
            }
          }
        });
        modalProps.form = { column };
      }

      ModalComponent = (
        <ModalSelector
          pageUuid={this.props.pageUuid}
          {...modalProps}
          visible={this.state.modalVisible}
          onOk={onOk}
          onCancel={onCancel}
        />
      );
    }

    if (cType !== 'checkList') {
      return null;
    }

    const data = this.listData;

    const toggleItem = (index: number, checked: boolean) => {
      const rows = this.getDataRows().filter((row: any) => !row.delete);
      rows[index].isComplete = typeof checked === 'boolean' ? checked : !rows[index].isComplete;
    };

    const removeItem = (index: number) => {
      const rows = this.getDataRows().filter((row: any) => !row.delete);
      rows[index].delete = true;
    };

    const editItem = (index: number) => {
      const rows = this.getDataRows().filter((row: any) => !row.delete);
      rows[index].editing = true;
      this.setState({ modalVisible: true, editIndex: index });
    };

    return (
      <div>
        <AntList
          {...attr}
          rowKey="_id"
          dataSource={data.rows}
          renderItem={(item: any, index: number) => (
            <CheckItem
              item={item}
              pageUuid={pageUuid}
              onToggle={(item, checked) => toggleItem(index, checked)}
              onRemove={item => removeItem(index)}
              onEdit={item => editItem(index)}
            />
          )}
        />
        {ModalComponent}
      </div>
    );
  }
}

export default CheckList as React.ComponentType<ExposedProps>;
