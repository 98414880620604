import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EInputAction, ISearch } from 'stores/ConfigStore';
import { IDictionary } from 'stores/KeysStore';
import { IUser } from 'stores/UserStore';
import { Input } from 'antd';
import { AntFormItem, getDataValue } from 'components';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';

const AntSearch = Input.Search;

interface IInputProps extends IInjectedType, IFormItemProps, IInjectedStore {
  config: ISearch;
  data: IUser;
  pageUuid: string;
}
type ExposedProps = Omit<IInputProps, keyof (IInjectedType & IInjectedStore)> & Partial<IInjectedType & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Search extends React.Component<IInputProps> {
  onSearch = async (value: string) => {
    const { search = {}, page = {} } = this.props.keyStore.getItem(this.props.pageUuid);
    const newSearch: IDictionary = { ...search, search: value };
    if (!value) {
      delete newSearch.search;
    }
    console.log('newSearch', newSearch);
    this.props.keyStore.mergeItem(this.props.pageUuid, { search: newSearch, page: { pageSize: page.pageSize } });
  };

  render() {
    const {
      config,
      data,
      form,
      i18nStore: { t },
    } = this.props;
    const { cType, placeholder, dataIndex, defaultValue, style, isRequire, action } = config;

    if (cType !== 'search') {
      return null;
    }

    let fieldValue: any;
    if ((action === EInputAction.SEARCH || action === EInputAction.QUERY) && dataIndex) {
      const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);
      fieldValue = search[dataIndex];
    } else {
      fieldValue = getDataValue(data, dataIndex);
    }

    console.log('Search', fieldValue);

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          initialValue={fieldValue}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <AntSearch onSearch={this.onSearch} placeholder={placeholder} style={style} enterButton allowClear />
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default Search as React.ComponentType<ExposedProps>;
