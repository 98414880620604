import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EInputAction, IFormColumn } from 'stores/ConfigStore';
import { IUser } from 'stores/UserStore';
import { findComponent, Hr, i18nFromJSON, MultiSelector } from 'components';
import { Col, Row } from 'antd';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { FormItemProps } from 'antd/es/form';

interface IFIProps extends IInjectedType, IInjectedStore {
  config: IFormColumn;
  data: IUser;
  style?: React.CSSProperties;
  pageUuid: string;
}
type ExposedProps = Omit<IFIProps, keyof (IInjectedType & IInjectedStore)> & Partial<IInjectedType & IInjectedStore>;

const normalLayout: FormItemProps & { labelWrap: boolean } = {
  wrapperCol: {
    xs: { span: 16 },
  },
  labelWrap: true,
};

const formLayout: FormItemProps & { labelWrap: boolean } = {
  labelCol: {
    xs: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
  },
  labelWrap: true,
};

const memoLayout: FormItemProps = {
  labelCol: {},
  wrapperCol: {
    xs: { span: 24 },
  },
};

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class FormItem extends React.Component<IFIProps> {
  componentDidMount() {
    if (this.props.config.defaultValue) {
      const { dataIndex, searchIndex, action } = this.props.config;
      const targetIndex = searchIndex || dataIndex;
      if (action === EInputAction.QUERY && targetIndex) {
        const { search = {}, page = {} } = this.props.keyStore.getItem(this.props.pageUuid);
        if (typeof search[targetIndex] === 'undefined') {
          this.props.keyStore.mergeItem(this.props.pageUuid, {
            search: { ...search, [targetIndex]: this.props.config.defaultValue },
            page: { pageSize: page.pageSize },
          });
        }
      }
    }
  }

  render() {
    const { config, data, style, form, handleSubmit, pageUuid, mode } = this.props;
    const withs = config.with || [];

    const { cType, title, border, labelAlign } = config;

    const withComponents = withs.map((_config, key) => (
      <Col key={`${_config.cType}-${key}`}>
        <MultiSelector
          config={_config}
          data={data}
          pageUuid={pageUuid}
          form={form}
          mode={mode}
          handleSubmit={handleSubmit}
        />
      </Col>
    ));

    const colon = labelAlign !== 'left';
    const layout: FormItemProps = {
      ...(cType === 'memo' || cType === 'message' ? memoLayout : mode === 'form' ? formLayout : normalLayout),
    };
    if (labelAlign === 'left') {
      layout.labelCol = {
        ...layout.labelCol,
        style: { textAlign: 'left' },
      };
    }

    const Component = findComponent(cType);
    const formItemProps: FormItemProps = { label: i18nFromJSON(title), style, colon, ...layout };

    return (
      <>
        <Component
          config={config}
          data={data}
          pageUuid={pageUuid}
          form={form}
          mode={mode}
          handleSubmit={handleSubmit}
          formItemProps={formItemProps}
        >
          {withComponents ? <Row gutter={10}>{withComponents}</Row> : null}
        </Component>
        {border ? <Hr /> : null}
      </>
    );
  }
}

export default FormItem as React.ComponentType<ExposedProps>;
