import * as React from 'react';
import styled from 'styled-components';
import { Button, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { observable } from 'mobx';

const StyledSelect = styled(Select)`
  width: 102px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const GroupButton = styled(Button)`
  margin-right: 5px;
  margin-bottom: 5px;
`;

interface IFolderSelectProps extends IInjectedType, IInjectedStore {
  folders: string[];
  onClick: (v: string | undefined) => void;
}
type ExposedProps = Omit<IFolderSelectProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class FolderSelect extends React.Component<IFolderSelectProps> {
  @observable selectValue: string | undefined = undefined;

  render() {
    const { t } = this.props.i18nStore;
    const { folders, onClick } = this.props;

    return (
      <>
        <StyledSelect
          value={this.selectValue}
          onChange={(v: any) => (this.selectValue = v)}
          options={[...folders.map(f => ({ value: f, label: f })), { value: '', label: '(Root)' }]}
        />
        <GroupButton onClick={() => onClick(this.selectValue)}>{t.button.group.move}</GroupButton>
      </>
    );
  }
}

export default FolderSelect as React.ComponentType<ExposedProps>;
