import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IInput } from 'stores/ConfigStore';
import { IUser } from 'stores/UserStore';
import { InputNumber as AntInputNumber } from 'antd';
import { AntFormItem, getDataValue } from 'components';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';

interface IInputProps extends IInjectedType, IInjectedStore, IFormItemProps {
  config: IInput;
  data: IUser;
  pageUuid: string;
}
type ExposedProps = Omit<IInputProps, keyof (IInjectedType & IInjectedStore)> & Partial<IInjectedType & IInjectedStore>;

// NOTE: number는 antd에서 넓이가 고정되어있음.
//   - 헤결방법은 나중에 찾아보기로
@inject('i18nStore')
@observer
class InputNumber extends React.Component<IInputProps> {
  render() {
    const {
      config,
      data,
      form,
      pageUuid,
      i18nStore: { t },
    } = this.props;
    const { cType, placeholder, dataIndex, style, isRequire, readOnly, editOnlyNew } = config;

    if (cType !== 'number') {
      return null;
    }

    let fieldValue = Number(getDataValue(data, dataIndex));
    fieldValue = !isNaN(fieldValue) ? fieldValue : 0;

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          initialValue={fieldValue}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <AntInputNumber
            placeholder={placeholder}
            style={{ width: '100%', ...style }}
            formatter={value => `${value || ''}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => `${value || ''}`.replace(/\$\s?|(,*)/g, '') as any}
            disabled={readOnly || (editOnlyNew && pageUuid.indexOf('/new') === -1)}
          />
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default InputNumber as React.ComponentType<ExposedProps>;
