import * as React from 'react';
import { observer } from 'mobx-react';
import { ICheckbox } from 'stores/ConfigStore';
import { Checkbox as AntCheckbox } from 'antd';
import { IFormItemProps, IInjectedType, Omit } from 'common/@types';
import { AntFormItem, getDataValue } from 'components';

interface ICheckboxProps extends IInjectedType, IFormItemProps {
  config: ICheckbox;
  data?: any;
  pageUuid: string;
}
type ExposedProps = Omit<ICheckboxProps, keyof IInjectedType> & Partial<IInjectedType>;

@observer
class Checkbox extends React.Component<ICheckboxProps> {
  render() {
    const { config, data, form, mode } = this.props;
    const { cType, dataIndex, option } = config;

    if (cType !== 'checkboxes') {
      return null;
    }

    const checkboxes = [];
    const _options = (data && getDataValue(data, dataIndex)) || option || [];
    for (const _option of _options) {
      const { label, value, checked } = _option;

      const fieldName = label + dataIndex;

      checkboxes.push(
        <AntFormItem {...this.props.formItemProps} name={fieldName} initialValue={!!(value || checked)} form={form}>
          <AntCheckbox>{label || value}</AntCheckbox>
        </AntFormItem>,
      );
    }

    return (
      <>
        {checkboxes}
        {this.props.children}
      </>
    );
  }
}

export default Checkbox as React.ComponentType<ExposedProps>;
