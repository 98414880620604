import { IContainer, IMixedConfig } from 'stores/ConfigStore';

const defaultConfig: IContainer[] = [
  {
    cType: '',
    config: {} as IMixedConfig,
    data: [],
  },
];

export function getPath(name: string): string {
  const divider = '.';
  const [_root, parent, ...paths] = name.split(divider);
  if (paths.length > 0) {
    return [_root, parent, paths.join(divider)].join('/');
  }
  return [_root, parent].join('/');
}

// TODO: File Exist module사용시 아래 코드를 해당 모듈을 쓰는 형태로 바꾸는게 바람직
export function findConfig(path: string): IContainer[] {
  try {
    console.log(`mocks/${path}.json`);
    return require(/* webpackMode: "eager" */ `mocks/${path}.json`);
  } catch {
    return defaultConfig;
  }
}

export function findImage(path: string): any {
  try {
    return require(/* webpackMode: "eager" */ `mocks/${path}.png`);
  } catch {
    return null;
  }
}
