import * as React from 'react';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { forEach, get, isEqual } from 'lodash';
import { IAgenda, ICodeData } from 'stores/ConfigStore';
import {
  Button,
  Calendar,
  Collapse as AntCollapse,
  Empty as AntEmpty,
  Row,
  Space,
  Spin,
  Timeline as AntTimeline,
} from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { convertDisplay, formatDateString, getDataValue } from 'components';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { computed, observable, toJS } from 'mobx';
import styled from 'styled-components';
import { each as PromiseEach } from 'bluebird';
import { IDictionary } from 'stores/KeysStore';
import { DATE_FORMAT, MONTH_FORMAT } from 'components/constants';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import { getPathFromLink } from 'common/router';

const AntPanel = AntCollapse.Panel;
const AntTimelineItem = AntTimeline.Item;

const AntCalendar = styled(Calendar)`
  .ant-picker-calendar-date-value {
    line-height: 40px !important;
  }

  .ant-picker-calendar-date {
    padding: 0 !important;
  }
`;

const DateCell = styled.li`
  margin-bottom: 2px;
  border: #40a9ff 1px solid;
  max-height: 27px;
  overflow: hidden;
  //text-overflow: ellipsis;
  //white-space: nowrap;

  span {
    margin-right: 2px;
  }
`;

const TimeCell = styled.div`
  span {
    display: block;
  }
`;

const ButtonRow = styled(Row)`
  margin-bottom: 10px;
  justify-content: flex-end;
`;

interface ICalendarDate {
  value: moment.Moment;
  mode: CalendarMode;
}

const AGENDA_DATE_FORMAT = 'MM/DD dddd';

interface IListData {
  total: number;
  rows: any[];
  dailyRows: { [key: string]: any };
  monthlyRows: { [key: string]: any };
}

interface IAgendaProps extends RouteComponentProps, IInjectedType, IInjectedStore {
  styled?: string;
  config: IAgenda;
  data?: any;
  pageUuid: string;
}

type ExposedProps = Omit<IAgendaProps, keyof (RouteComponentProps & IInjectedType & IInjectedStore)> &
  Partial<RouteComponentProps & IInjectedType & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Agenda extends React.Component<IAgendaProps> {
  @observable spinning = false;
  @observable codeData: ICodeData = {};

  @computed
  get listData(): IListData {
    const data: IListData = {
      total: 0,
      rows: [],
      dailyRows: {},
      monthlyRows: {},
    };

    if (this.spinning) {
      return data;
    }

    const { dataIndex, display, tagRender } = this.props.config;

    if (dataIndex) {
      let _value = toJS(getDataValue(this.props.data, dataIndex) || []);
      if (!Array.isArray(_value)) {
        _value = [_value];
      }
      // console.log('table render value', value);
      formatDateString(_value);
      data.rows = _value;
      data.total = _value.length;
    } else if (this.props.data) {
      const _data = toJS(this.props.data);
      data.rows = _data.rows;
      data.total = _data.rows.length;
      formatDateString(data.rows);
    }

    if (data.rows && data.rows.length > 0) {
      data.rows = data.rows.filter((row: any) => !row.delete);
      data.total = data.rows.length;
    }

    forEach(data.rows, row => {
      if (!row._id) {
        row._id = row.id || `${new Date().getTime()}${Math.random()}`;
      }

      if (display) {
        row.fullName = convertDisplay(row, display, this.codeData)
          .filter(v => !!v)
          .map((val, idx) => <span key={idx}>{val}</span>);

        if (tagRender) {
          const condition = tagRender.find(({ type, value, dataIndex }) => {
            const val = dataIndex ? row[dataIndex] : row.fullName;
            if (!val) {
              return false;
            }

            if (type === 'like') {
              if (val.indexOf(value) > -1) return true;
            } else {
              if (val === value) return true;
            }
            return false;
          });

          if (condition) {
            row.color = condition.color;
          }
        }
      }

      const targetDate = row[this.props.config.targetDate || 'dueDate'];
      if (targetDate) {
        const dateString = moment(targetDate).format(DATE_FORMAT);

        if (!data.dailyRows[dateString]) {
          data.dailyRows[dateString] = [];
        }
        data.dailyRows[dateString].push(row);

        const monthString = moment(targetDate).format(MONTH_FORMAT);
        if (!data.monthlyRows[monthString]) {
          data.monthlyRows[monthString] = [];
        }
        data.monthlyRows[monthString].push(row);
      }
    });

    return data;
  }

  @computed
  get getItems(): React.ReactNode {
    const { attr } = this.props.config;

    const calendarDate = this.calendarDate as ICalendarDate;
    const activeDate = moment(calendarDate.value).format(AGENDA_DATE_FORMAT);
    const activeKey: string[] = [];

    if (this.spinning) {
      return null;
    }

    const { display } = this.props.config;
    const items: any[] = [];
    const daily: any[] = [];

    const targetDate = moment(calendarDate.value).isoWeekday(1); // Monday

    daily.push({
      title: targetDate.format(AGENDA_DATE_FORMAT),
      items: this.listData.dailyRows[targetDate.format(DATE_FORMAT)] || [],
    });

    for (let _i = 0; _i < 6; _i++) {
      targetDate.add(1, 'd');
      daily.push({
        title: targetDate.format(AGENDA_DATE_FORMAT),
        items: this.listData.dailyRows[targetDate.format(DATE_FORMAT)] || [],
      });
    }

    forEach(daily, (day: any) => {
      const timelines: React.ReactNode[] = [];

      if (day.items.length) {
        activeKey.push(day.title);
      }

      forEach(day.items, (item: any) => {
        const targetDate = item[this.props.config.targetDate || 'dueDate'];
        const itemDate = moment(targetDate).isValid() ? moment(targetDate).format('A h:mm') : null;
        const itemPerson = item.companyPerson
          ? '[' + item.companyPerson.name + (item.companyPerson.role ? ' ' + item.companyPerson.role : '') + ']'
          : false;

        timelines.push(
          <AntTimelineItem key={item._id}>
            <div onClick={e => this.onClickItem(e, item)} style={{ cursor: 'pointer' }}>
              {itemDate && itemDate !== 'AM 12:00' ? <div>{itemDate}</div> : null}
              {display ? (
                <TimeCell>{item.fullName || item.name}</TimeCell>
              ) : (
                <>
                  {itemPerson ? <div>{itemPerson || ''}</div> : null}
                  <TimeCell>{item.fullName || item.name}</TimeCell>
                </>
              )}
            </div>
          </AntTimelineItem>,
        );
      });

      items.push(
        <AntPanel key={day.title} header={day.title}>
          {timelines.length ? <AntTimeline>{timelines}</AntTimeline> : <AntEmpty />}
        </AntPanel>,
      );
    });

    if (!activeKey.length) {
      activeKey.push(activeDate);
    }

    return (
      <AntCollapse {...attr} key={activeDate} defaultActiveKey={activeKey}>
        {items}
      </AntCollapse>
    );
  }

  @computed
  get calendarDate(): ICalendarDate | undefined {
    const { option = {} } = this.props.keyStore.getItem(this.props.pageUuid);
    return option.calendarDate;
  }

  set calendarDate(calendarDate: ICalendarDate | undefined) {
    const { option = {} } = this.props.keyStore.getItem(this.props.pageUuid);

    const preCalendarDate = this.calendarDate || ({} as ICalendarDate);
    const newCalendarDate = calendarDate || ({} as ICalendarDate);
    if (
      moment(preCalendarDate.value).format(DATE_FORMAT) !== moment(newCalendarDate.value).format(DATE_FORMAT) ||
      preCalendarDate.mode !== newCalendarDate.mode
    ) {
      this.props.keyStore.mergeItem(this.props.pageUuid, {
        option: { ...option, calendarDate },
      });

      if (calendarDate) {
        this.getData(calendarDate);
      }
    }
  }

  componentDidMount() {
    if (!this.calendarDate?.value) {
      this.calendarDate = { value: moment(), mode: 'month' };
    }

    this.getDisplayCommonCode();
  }

  componentDidUpdate(prevProps: Readonly<IAgendaProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (!this.calendarDate?.value) {
      this.calendarDate = { value: moment(), mode: 'month' };
    }

    if (!isEqual(toJS(this.props.config.display), toJS(prevProps.config.display))) {
      this.getDisplayCommonCode();
    }
  }

  onClickItem(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined, data: any) {
    e?.stopPropagation();

    const path = getPathFromLink(this.props.config.link, this.props.match, data);
    path && this.props.history.push(path);
  }

  monthCellRender = (value: moment.Moment) => {
    const { t } = this.props.i18nStore;
    const { monthCell } = this.props.config;

    const items: React.ReactNode[] = [];

    if (this.listData.total > 0) {
      const monthRow: any[] = this.listData.monthlyRows[value.format(MONTH_FORMAT)];

      if (monthRow) {
        const display = monthCell?.display;
        const groupBy = monthCell?.groupBy;

        if (groupBy) {
          const groupedRows: any[] = [];

          forEach(monthRow, row => {
            if (typeof groupBy === 'string') {
              const groupKey = get(row, groupBy);

              const targetRow = groupedRows.find(r => r.groupKey === groupKey);
              if (!targetRow) {
                groupedRows.push({ ...row, groupKey, count: 1 });
              } else {
                targetRow.count++;
              }
            } else if (groupBy.namespace) {
              const targetSpace = get(row, groupBy.namespace);
              if (Array.isArray(targetSpace)) {
                forEach(targetSpace, target => {
                  const groupKey = get(target, groupBy.dataIndex);

                  const targetRow = groupedRows.find(r => r.groupKey === groupKey);
                  if (!targetRow) {
                    const groupedRow = { ...row, [groupBy.namespace!]: [target], groupKey, count: 1 };
                    delete groupedRow['id'];
                    delete groupedRow['_id'];

                    if (groupBy.display) {
                      groupedRow.fullName = convertDisplay(target, groupBy.display, this.codeData)
                        .filter(v => !!v)
                        .join(' ');
                    }

                    groupedRows.push(groupedRow);
                  } else {
                    targetRow.count++;
                  }
                });
              } else {
                const groupKey = get(targetSpace, groupBy.dataIndex);

                const targetRow = groupedRows.find(r => r.groupKey === groupKey);
                if (!targetRow) {
                  groupedRows.push({ ...row, groupKey, count: 1 });
                } else {
                  targetRow.count++;
                }
              }
            } else {
              const groupKey = get(row, groupBy.dataIndex);

              const targetRow = groupedRows.find(r => r.groupKey === groupKey);
              if (!targetRow) {
                groupedRows.push({ ...row, groupKey, count: 1 });
              } else {
                targetRow.count++;
              }
            }
          });

          forEach(groupedRows, item => {
            items.push(
              <li key={item._id} onClick={e => this.onClickItem(e, item)} style={{ cursor: 'pointer' }}>
                {display ? convertDisplay(item, display, this.codeData) : item.fullName || item.name} {item.count}{' '}
                {t.rowCount}
              </li>,
            );
          });
        } else {
          forEach(monthRow.slice(0, 5), item => {
            items.push(
              <li key={item._id} onClick={e => this.onClickItem(e, item)} style={{ cursor: 'pointer' }}>
                {display ? convertDisplay(item, display, this.codeData) : item.fullName || item.name}
              </li>,
            );
          });

          if (monthRow.length > 5) {
            items.push(
              <li key={6} style={{ cursor: 'pointer' }}>
                (+ {monthRow.length - 5} {t.rowCount})
              </li>,
            );
          }
        }
      }
    }

    return (
      <ul
        className="events"
        style={{
          fontSize: '12px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {items}
      </ul>
    );
  };

  dateCellRender = (value: moment.Moment) => {
    const items: React.ReactNode[] = [];

    if (this.listData.total > 0) {
      const dateRow: any[] = this.listData.dailyRows[value.format(DATE_FORMAT)];

      if (dateRow) {
        forEach(dateRow, item => {
          const style: React.CSSProperties = { cursor: 'pointer' };
          if (item.color) {
            style.backgroundColor = item.color;
            style.color = '#ffffff';
          }

          items.push(
            <DateCell key={item._id} onClick={e => this.onClickItem(e, item)} style={style}>
              {item.fullName || item.name}
            </DateCell>,
          );
        });
      }
    }

    return (
      <ul
        className="events"
        style={{
          fontSize: '8px',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {items}
      </ul>
    );
  };

  async getDisplayCommonCode() {
    const { display, monthCell } = this.props.config;

    if (!display && !monthCell?.display) {
      return;
    }

    try {
      this.spinning = true;
      const codeData: ICodeData = {};

      if (display) {
        await PromiseEach(
          display.filter(_dsp => typeof _dsp === 'object'),
          async _dsp => {
            if (typeof _dsp === 'object') {
              const _codeData = await this.props.keyStore.fetchCodeData(this.props.pageUuid, _dsp.code);

              codeData[_dsp.code] = _codeData.reduce((acc: any, cur: any) => {
                acc[cur.code] = cur.name;
                return acc;
              }, {});
            }
          },
        );
      }

      if (monthCell?.display) {
        await PromiseEach(
          monthCell?.display.filter(_dsp => typeof _dsp === 'object'),
          async _dsp => {
            if (typeof _dsp === 'object') {
              const _codeData = await this.props.keyStore.fetchCodeData(this.props.pageUuid, _dsp.code);

              codeData[_dsp.code] = _codeData.reduce((acc: any, cur: any) => {
                acc[cur.code] = cur.name;
                return acc;
              }, {});
            }
          },
        );
      }

      this.codeData = codeData;
    } finally {
      this.spinning = false;
    }
  }

  getData(calendarDate: ICalendarDate) {
    const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);
    const { value, mode } = calendarDate;
    const { targetDate } = this.props.config;
    const targetKey = targetDate || 'dueDate';

    const newSearch: IDictionary = { ...search };

    if (mode === 'year') {
      newSearch[targetKey] = {
        $between: [moment(value).startOf('year').format(DATE_FORMAT), moment(value).endOf('year').format(DATE_FORMAT)],
      };
    } else {
      newSearch[targetKey] = {
        $between: [
          moment(value).startOf('month').add(-13, 'd').format(DATE_FORMAT),
          moment(value).endOf('month').add(13, 'd').format(DATE_FORMAT),
        ],
      };
    }

    console.log('newSearch', newSearch);

    if (!isEqual(toJS(search[targetKey] || {}), newSearch[targetKey])) {
      this.props.keyStore.mergeItem(this.props.pageUuid, { search: newSearch, page: { pageSize: 1000000 } });
    }
  }

  onChange = (value: moment.Moment) => {
    console.log('onChange', value.format(DATE_FORMAT), 'mode', this.calendarDate?.mode);

    if (
      this.calendarDate &&
      moment(this.calendarDate.value).format(DATE_FORMAT) === moment(value).format(DATE_FORMAT)
    ) {
      return;
    }

    this.calendarDate = { value, mode: this.calendarDate?.mode || 'month' };
  };

  onPanelChange = (value: moment.Moment, mode: CalendarMode) => {
    console.log('onPanelChange', value.format(DATE_FORMAT), 'mode', mode);

    if (
      this.calendarDate &&
      moment(this.calendarDate.value).format(DATE_FORMAT) === moment(value).format(DATE_FORMAT) &&
      this.calendarDate.mode === mode
    ) {
      return;
    }

    this.calendarDate = { value, mode };
  };

  movePrevWeek = () => {
    const { value, mode } = this.calendarDate as ICalendarDate;

    this.calendarDate = { value: moment(value).add(-7, 'd'), mode };
  };

  moveNextWeek = () => {
    const { value, mode } = this.calendarDate as ICalendarDate;

    this.calendarDate = { value: moment(value).add(7, 'd'), mode };
  };

  render() {
    const { config } = this.props;

    if (!this.calendarDate?.value) {
      return null;
    }

    console.log('render', moment(this.calendarDate.value).format(DATE_FORMAT), 'mode', this.calendarDate.mode);

    return (
      <Spin spinning={this.spinning}>
        {config.format === 'monthly' ? (
          <AntCalendar
            value={moment(this.calendarDate.value)}
            mode={this.calendarDate.mode}
            dateCellRender={this.dateCellRender}
            monthCellRender={this.monthCellRender}
            onChange={this.onChange}
            onPanelChange={this.onPanelChange}
          />
        ) : (
          <>
            <ButtonRow>
              <Space>
                <Button onClick={this.movePrevWeek}>
                  <LeftOutlined />
                </Button>
                <Button onClick={this.moveNextWeek}>
                  <RightOutlined />
                </Button>
              </Space>
            </ButtonRow>
            {this.getItems}
          </>
        )}
      </Spin>
    );
  }
}

export default withRouter(Agenda) as unknown as React.ComponentType<ExposedProps>;
