import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IModalConfigProps, IModalProps } from 'stores/ConfigStore';
import { IInjectedStore, Omit } from 'common/@types';
import { Modal } from 'antd';
import { Search as ModalSearch } from 'components/Modal/Content';
import { convertAndSetData } from 'common/modalHelper';
import Spinner from 'components/Common/Spinner';

interface IProps extends IModalProps, IInjectedStore {
  mType: string;
  cType: string;
  title?: any;
  search: IModalConfigProps;
  pageUuid: string;
}

type ExposedProps = Omit<IProps, keyof IInjectedStore> & Partial<IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Search extends React.Component<IProps> {
  onSelectItem = (form: any) => {
    console.log('onSelectItem', form);
    this.props.keyStore.mergeItem(this.props.pageUuid, { form });
  };

  onClickClear = () => {
    console.log('onClickClear');
    convertAndSetData(this, {});
    this.props.onOk && this.props.onOk();
    this.props.keyStore.mergeItem(this.props.pageUuid, { form: {} });
  };

  onClickNew = () => {
    console.log('onClickNew');
  };

  onOk = () => {
    const { form } = this.props.keyStore.getItem(this.props.pageUuid);
    convertAndSetData(this, form);
    this.props.onOk && this.props.onOk();
    this.props.keyStore.mergeItem(this.props.pageUuid, { form: {} });
  };

  onCancel = () => {
    this.props.keyStore.mergeItem(this.props.pageUuid, { form: {} });
    this.props.onCancel && this.props.onCancel();
  };

  render() {
    const { cType, visible, title, pageUuid } = this.props;

    if (cType !== 'search') {
      return null;
    }

    return (
      <Modal open={visible} onOk={this.onOk} onCancel={this.onCancel} title={title} destroyOnClose={true}>
        <Spinner pageUuid={this.props.pageUuid}>
          {visible ? (
            <ModalSearch
              checkable={true}
              config={this.props.search}
              pageUuid={pageUuid}
              onClickClear={this.onClickClear}
              onSelect={this.onSelectItem}
              onClickNew={this.onClickNew}
            />
          ) : null}
        </Spinner>
      </Modal>
    );
  }
}

export default Search as React.ComponentType<ExposedProps>;
