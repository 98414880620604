import * as React from 'react';
import { IFormGroup } from 'stores/ConfigStore';
import { Input } from 'antd';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { AntFormItem, MultiSelector } from 'components';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';

const AntInputGroup = styled(Input.Group)`
  .ant-spin-nested-loading {
    width: unset;
    padding-right: 10px;
  }

  .ant-form-item {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

interface IFormGroupProps extends IInjectedType, IInjectedStore, IFormItemProps {
  config: IFormGroup;
  data: any;
  pageUuid: string;
}
type ExposedProps = Omit<IFormGroupProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

@inject('i18nStore')
@observer
class FormGroup extends React.Component<IFormGroupProps> {
  render() {
    const {
      config,
      data,
      form,
      mode,
      handleSubmit,
      pageUuid,
      i18nStore: { t },
    } = this.props;
    const { cType, column, dataIndex, isRequire, style } = config;

    if (cType !== 'formGroup') {
      return null;
    }

    const components =
      column &&
      column.map((_config, index) => (
        <MultiSelector
          config={_config}
          data={data}
          key={`formGroup-${index}`}
          pageUuid={pageUuid}
          form={form}
          mode={mode}
          handleSubmit={handleSubmit}
        />
      ));

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <AntInputGroup style={toJS(style)} compact>
            {components}
          </AntInputGroup>
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default FormGroup as React.ComponentType<ExposedProps>;
