import { cloneDeep, forEach, isArray, isEmpty, last, set, unset } from 'lodash';
import moment from 'moment';
import { getDataValue } from 'components';
import { DATE_FORMAT } from 'components/constants';
import { toJS } from 'mobx';
import { IAppStore, IKeysStore } from 'stores';

export function convertAndSetData(component: any, values: any) {
  console.log('convertAndSetData / values', values);

  const { mode, target, namespace, dataIndexes, title, pageUuid } = component.props;
  const keyStore: IKeysStore = component.props.keyStore;
  const appStore: IAppStore = component.props.appStore;

  console.log('convertAndSetData / mode', mode);
  console.log('convertAndSetData / pageUuid', pageUuid);
  const { parentPageUuid, form: item } = keyStore.getItem(pageUuid);
  console.log('convertAndSetData / parentPageUuid', parentPageUuid);
  if (!parentPageUuid) {
    throw 'not found parentPageUuid';
  }

  // eslint-disable-next-line prefer-const
  let { data, form } = keyStore.getItem(parentPageUuid);
  console.log('convertAndSetData / data', data);
  console.log('convertAndSetData / form', form);

  data = form || data;
  while (!data) {
    keyStore.setItem(parentPageUuid, { data: {} });
    data = keyStore.getItem(parentPageUuid).data;
  }

  // NOTE: remove Title if not included values
  console.log('convertAndSetData / title', title);
  if (title && title.dataIndex) {
    if (!getDataValue(values, title.dataIndex)) {
      unset(dataIndexes, title.dataIndex);
    }
  }

  // NOTE: Moment인것은 string으로 parse
  forEach(values, (value, index) => {
    if (moment.isMoment(value)) {
      values[index] = moment(value).format(DATE_FORMAT);
    }
  });

  let targetStore: any | any[] = data;

  console.log('convertAndSetData / target', target);
  if (target) {
    targetStore = getDataValue(data, target);
    if (!targetStore) {
      set(data, target, {});
      targetStore = getDataValue(data, target);
    }
  }
  console.log('convertAndSetData / targetStore', targetStore);

  if (!Array.isArray(targetStore)) {
    console.log('targetStore is not array');
    console.log('convertAndSetData / namespace', namespace);
    const _values = cloneDeep(values);

    if (namespace) {
      set(targetStore, namespace, _values);
    } else {
      forEach(_values, (value, key) => {
        set(targetStore, key, value);
      });
    }

    // NOTE: dataIndex Mapping
    console.log('convertAndSetData / dataIndexes', dataIndexes);
    forEach(dataIndexes, (destination, source) => {
      if (source === '*') {
        return;
      }

      let value = getDataValue(values, source) || getDataValue(item, source);
      if (!value) {
        if (destination === 'userId') {
          value = toJS(appStore.userStore.meId);
        }
        if (destination === 'user') {
          value = toJS(appStore.userStore.me);
        }
      }

      set(targetStore, destination, value);
    });
  } else {
    console.log('targetStore is array');
    console.log('convertAndSetData / namespace', namespace);
    if (isEmpty(values)) {
      return;
    }

    let newData = values;
    const _values = cloneDeep(values);

    if (namespace) {
      newData = {};
      set(newData, namespace, _values);
    }

    // NOTE: dataIndex Mapping
    console.log('convertAndSetData / dataIndexes', dataIndexes);
    forEach(dataIndexes, (destination, source) => {
      if (source === '*') {
        return;
      }

      let value = getDataValue(values, source) || getDataValue(item, source);
      if (!value) {
        if (destination === 'userId') {
          value = toJS(appStore.userStore.meId);
        }
        if (destination === 'user') {
          value = toJS(appStore.userStore.me);
        }
      }

      set(newData, destination, value);

      if (source === destination) {
        return;
      }
      unset(newData, source);
    });

    console.log('convertAndSetData / newData', newData);

    // NOTE: 테이블에서 셀 선택해서 수정인 경우
    let selectedRow = targetStore.find(row => row.editing);
    // NOTE: "추가"하는 녀석인데, Modal의 Modal로 떳는데, 처음 데이터를 입력하는 경우
    if (!selectedRow) {
      // NOTE: 이렇게 하는 이유는 mobx에 한번 담갔다가 빼야 observerable해져서
      targetStore.push({ _id: `${new Date().getTime()}${Math.random()}` });
      selectedRow = last(targetStore);
    }
    console.log('convertAndSetData / selectedRow', selectedRow);

    forEach(newData, (data, dataIndex) => {
      // const targetData = get(selectedRow, dataIndex);
      set(selectedRow, dataIndex, data);
    });
  }
}

export function getAndRevertData(component: any) {
  const { target, dataIndexes, pageUuid, visible } = component.props;
  const keyStore: IKeysStore = component.props.keyStore;
  const appStore: IAppStore = component.props.appStore;

  if (!visible) {
    return {};
  }

  console.log('getAndRevertData / pageUuid', pageUuid);
  const { form } = keyStore.getItem(pageUuid);
  console.log('getAndRevertData / target', target);
  const targetData = target ? getDataValue(form, target) : form;
  console.log('getAndRevertData / targetData', targetData);
  let selectedRow = {} as any;
  if (isArray(targetData)) {
    selectedRow = (targetData || []).find(row => row.editing);
    if (!selectedRow) {
      targetData.push({ _id: `${new Date().getTime()}${Math.random()}`, editing: true });
      selectedRow = last(targetData);
    }
  } else if (targetData) {
    selectedRow = targetData;
  }

  let transFormRow = toJS(selectedRow);
  console.log('getAndRevertData / selectedRow', selectedRow);

  if (dataIndexes) {
    Object.keys(dataIndexes).forEach(key => {
      const value = dataIndexes[key];
      if (key !== value) {
        const dataValue = getDataValue(transFormRow, value);
        set(transFormRow, key, dataValue);
        unset(transFormRow, value);
      }
    });
  }

  if (component.props.namespace) {
    const { [component.props.namespace]: namespace, ...parentFields } = transFormRow;
    transFormRow = { ...namespace, ...parentFields };
  }

  console.log('getAndRevertData / transFormRow', transFormRow);

  return transFormRow;
}
