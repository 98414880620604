import * as React from 'react';
import styled from 'styled-components';
import { api } from 'providers';
import { IActionAPIRequest, IChartConfig } from 'stores/ConfigStore';
import { IReactionDisposer, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { IDictionary } from 'stores/KeysStore';
import { Button, Col, Row, Spin } from 'antd';
import { Pie } from '@ant-design/plots';
import { isEqual } from 'lodash';
import { PieConfig } from '@ant-design/charts';
import { Footer, Header } from 'components/Layout';
import { download } from 'common/download';

interface IPieChartProps extends IInjectedType, IInjectedStore {
  config: IChartConfig;
  pageUuid: string;
}
type ExposedProps = Omit<IPieChartProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

const Container = styled.div`
  canvas {
    background-color: #ffffff;
  }
`;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class PieChart extends React.Component<IPieChartProps> {
  @observable spinning = false;
  @observable remoteData: any[] = [];
  @observable recentDate: string = '';

  searchReaction: IReactionDisposer | undefined;
  prevSearch: any;
  chartRef: HTMLCanvasElement | undefined;

  componentDidMount() {
    this.getData();

    this.searchReaction = this.props.keyStore.addReaction(this.props.pageUuid, 'search', (_search: IDictionary) => {
      const search = toJS(_search || {}) || {};
      console.log('PieChart search reaction / search', search);

      if (!isEqual(search, this.prevSearch || {})) {
        this.prevSearch = search;
        this.getData();
      }
    });
  }

  componentWillUnmount() {
    this.searchReaction && this.searchReaction(); // dispose reaction
  }

  async getData() {
    const { request = {} as IActionAPIRequest } = this.props.config;

    if (request.url) {
      try {
        this.spinning = true;

        const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);

        const remoteData = await api[request.method || 'get'](
          `${request.url}${request.url.indexOf('?') === -1 ? '?' : '&'}where=${JSON.stringify(search)}`,
        );
        this.remoteData = remoteData.data.rows;
        this.recentDate = remoteData.data.recentDate;
      } finally {
        this.spinning = false;
      }
    }
  }

  exportChart = async () => {
    if (this.chartRef) {
      const image = this.chartRef.toDataURL('image/png');
      await download(image, 'export_chart.png');
    }
  };

  render() {
    const { config, form, mode, handleSubmit, pageUuid } = this.props;
    const { cType, header, header2nd, footer, style, displayTotal, colors } = config;

    if (cType !== 'pieChart') {
      return null;
    }

    const total = this.remoteData.reduce((p, c) => p + c.value, 0);

    const chartProps: PieConfig = {
      data: this.remoteData || {},
      padding: 'auto',
      height: 500,
      angleField: 'value',
      colorField: 'type',
      radius: 0.75,
      style,
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ type, percent }) => (percent * 100 > 10 ? `${type}\n${(percent * 100).toFixed(0)}%` : ''),
        autoRotate: false,
        style: {
          textAlign: 'center',
          fontSize: 16,
          fontWeight: 600,
        },
      },
      tooltip: {
        formatter: ({ type, value }) => ({ name: type, value: `${((value / total) * 100).toFixed(0)}%` }),
      },
      legend: {
        layout: 'vertical',
        position: 'bottom',
        flipPage: false,
      },
      interactions: [
        {
          type: 'element-selected',
        },
        {
          type: 'element-active',
        },
      ],
      theme: {
        styleSheet: {
          backgroundColor: '#ffffff',
        },
      },
      annotations: [],
      pieStyle: ({ type }) => {
        if (colors) {
          const color = colors.find(c => c.value === type);
          if (color) {
            return {
              fill: color.color,
            };
          }
        }
        return;
      },
    };

    if (displayTotal && chartProps.annotations) {
      chartProps.annotations.push({
        type: 'text',
        content: `Total: ${this.remoteData.reduce((p, c) => p + c.value, 0)}`,
        position: ['10%', '10%'],
        style: {
          textAlign: 'center',
          fill: 'rgba(0,0,0,0.85)',
        },
      });
    }

    if (this.recentDate && chartProps.annotations) {
      chartProps.annotations.push({
        type: 'text',
        content: `Up to date: ${this.recentDate}`,
        position: ['85%', '10%'],
        style: {
          textAlign: 'center',
          fill: 'rgba(0,0,0,0.85)',
        },
      });
    }

    return (
      <Spin spinning={this.spinning}>
        <Container>
          <Header config={header} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
          <Header config={header2nd} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
          <Row justify="end" style={{ marginBottom: '10px' }}>
            <Col>
              <Button onClick={this.exportChart}>EXPORT</Button>
            </Col>
          </Row>
          <Pie {...chartProps} onReady={(ref: any) => (this.chartRef = ref)} />
          <Footer config={footer} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        </Container>
      </Spin>
    );
  }
}

export default PieChart as React.ComponentType<ExposedProps>;
