import * as React from 'react';
import { observer } from 'mobx-react';
import { IFormConfig } from 'stores/ConfigStore';
import { MultiSelector, splitStyle } from 'components';
import { Footer, Header } from 'components/Layout';
import { IInjectedType, Omit } from 'common/@types';
import { toJS } from 'mobx';

interface IFormProps extends IInjectedType {
  config: IFormConfig;
  data: any;
  pageUuid: string;
}
type ExposedProps = Omit<IFormProps, keyof IInjectedType> & Partial<IInjectedType>;

@observer
class NormalForm extends React.Component<IFormProps, any> {
  render() {
    const { config, data, form, mode, handleSubmit, pageUuid } = this.props;
    const { column, style, header, header2nd, footer } = config;
    const styles = splitStyle(style);
    const formItems =
      column &&
      column.map((_column, key) => {
        return (
          <MultiSelector
            config={_column}
            data={data}
            key={`${_column.cType}-${key}`}
            pageUuid={pageUuid}
            form={form}
            mode="form"
            handleSubmit={handleSubmit}
          />
        );
      });

    return (
      <div style={toJS(styles.container)}>
        <Header config={header} data={data} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        <Header
          config={header2nd}
          data={data}
          pageUuid={pageUuid}
          form={form}
          mode={mode}
          handleSubmit={handleSubmit}
        />
        {formItems}
        <Footer config={footer} data={data} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
      </div>
    );
  }
}

export default NormalForm as React.ComponentType<ExposedProps>;
