import * as React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { getDataValue, i18nFromJSON } from 'components';
import { IConfigComp, ILocale } from 'stores/ConfigStore';

interface IDescProps {
  config: { desc?: ILocale } & IConfigComp;
  data: any;
  pageUuid: string;
}

const Container = styled.div`
  padding: 10px;
`;

@observer
class Desc extends React.Component<IDescProps> {
  render() {
    const { config, data } = this.props;
    const { cType, desc, style, dataIndex } = config;

    const _desc = getDataValue(data, dataIndex) || desc;
    const i18nDesc = i18nFromJSON(_desc);

    if (cType !== 'desc') {
      return null;
    }

    return <Container style={toJS(style)}>{i18nDesc}</Container>;
  }
}

export default Desc;
