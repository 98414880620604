import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styled from 'styled-components';
import { IInjectedStore, Omit } from 'common/@types';
import { RouteComponentProps, withRouter } from 'react-router';

const SpinWrap = styled.div`
  width: 100%;
  height: 100%;

  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
  }

  .ant-spin-container {
    width: 100%;
    height: 100%;
    min-height: 400px;
  }
`;

const SpinContent = styled.div`
  width: 100%;
  height: 100%;

  .ant-spin-container {
    min-height: unset;
  }
`;

interface IProps extends RouteComponentProps, IInjectedStore {
  pageUuid: string;
  children?: React.ReactNode;
}
type ExposedProps = Omit<IProps, keyof (RouteComponentProps & IInjectedStore)> &
  Partial<RouteComponentProps & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Spinner extends React.Component<IProps> {
  render() {
    const { spinning, spinningTip } = this.props.appStore.getSpinner(this.props.pageUuid);

    return (
      <SpinWrap>
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
          spinning={spinning}
          size="large"
          tip={spinningTip || ''}
        >
          <SpinContent>{this.props.children}</SpinContent>
        </Spin>
      </SpinWrap>
    );
  }
}

export default withRouter(Spinner) as React.ComponentType<ExposedProps>;
