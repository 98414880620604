import en from './en';

const RESOURCES: typeof en = {
  change: {
    english: '영어로 변환하기',
    korean: '한국어로 변환하기',
  },
  button: {
    ok: '확인',
    send: '보내기',
    cancel: '취소',
    reset: '취소',
    delete: '삭제',
    save: '저장',
    list: '목록',
    back: '뒤로',
    selectAll: '전체',
    deselect: '비우기',
    add: '추가',
    addItem: '항목 추가',
    group: {
      delete: '선택삭제',
      download: '다운로드',
      noSelect: '선택된 파일이 없습니다.',
      move: '이동',
      noFolder: '선택된 폴더가 없습니다.',
    },
    copyClipboard: '공유할 내용이 복사되었습니다.',
    noPhoneNumber: '전화번호가 없습니다.',
    noEmail: '이메일 주소가 없습니다.',
  },
  newFolder: {
    button: '새폴더 생성',
    placeholder: '폴더 이름 입력',
  },
  confirm: {
    delete: '삭제하시겠습니까?',
    save: '저장하시겠습니까?',
    move: '이동하시겠습니까?',
  },
  title: '융착기술팀 앱',
  company: '아그루코리아',
  address: '주소',
  user: '사용자',
  kanban: '칸반',
  backlog: '할일',
  doing: '작업중',
  review: '검토중',
  done: '완료',
  calendar: '일정',
  rental: '임대',
  sales: '판매',
  delivery: '납품',
  calculate: '정산',
  machine: '장비',
  education: '교육',
  customer: '고객',
  as: 'A/S',
  part: '자재',
  equipment: '장비',
  oldMenu: '(구)',
  common: '공통',
  quality: '품질',
  rowCount: '건',
  menu: {
    kanban: {
      list: '칸반 보드',
      detail: '카드 상세',
    },
    calendar: {
      list: '업무 일정',
      detail: '일정 상세',
    },
    rental: {
      rent: {
        list: '융착기 임대',
        detail: '임대 상세',
      },
      assign: {
        list: '융착기 할당',
        detail: '할당 상세',
      },
      end: {
        list: '임대 종료',
        detail: '종료 상세',
      },
      product: {
        list: '임대 품목 관리',
        detail: '품목 상세',
      },
    },
    sales: {
      order: {
        list: '장비 발주',
        detail: '발주서',
      },
      consumable: {
        list: '소모품 발주',
        detail: '발주서',
      },
    },
    delivery: {
      estimate: {
        list: '납품 예정',
      },
      packing: {
        list: '패킹 리스트',
        detail: '패킹 상세',
      },
    },
    calculate: {
      target: {
        list: '정산 대상',
      },
      issued: {
        list: '정산서',
        detail: '정산서 상세',
      },
    },
    machine: {
      list: '장비 명세',
      detail: '장비 상세',
      repair: {
        needCheck: '점검 대상 장비',
        list: '점검 이력',
        detail: '점검 결과',
      },
      checklist: {
        list: '구성품 체크리스트',
        detail: '체크리스트 상세',
      },
    },
    education: {
      calendar: {
        list: '교육 일정',
      },
      list: '교육 목록',
      detail: '교육 상세',
      ready: '교육 준비',
      start: '교육 시작',
      end: '교육 종료',
      student: {
        list: '교육생',
        detail: '교육생 상세',
      },
      graduate: {
        list: '교육 수료자',
        detail: '수료자 상세',
      },
      messagetemplate: {
        list: '메시지 템플릿',
        detail: '메시지 템플릿 상세',
      },
      statistics: '교육 통계',
    },
    customer: {
      list: '고객 목록',
      detail: '고객 상세',
      call: {
        list: '고객 문의',
        detail: '문의 상세',
      },
      estimate: {
        list: '견적서',
        detail: '견적서 상세',
      },
    },
    as: {
      list: 'A/S 목록',
      detail: 'A/S 상세',
    },
    part: {
      list: '자재 목록',
      detail: '자재 상세',
      io: {
        list: '자재 입출고',
        detail: '입출고 상세',
      },
    },
    public: {
      delivery: {
        checklist: '구성품 체크리스트',
        transaction: '거래 명세표',
      },
      calculate: {
        issued: '정산서',
      },
      education: {
        apply: '융착사 인증교육 신청서',
      },
      estimate: '견적서',
      as: '수리비 견적서',
    },
    equipment: {
      schedule: {
        calendar: '일정',
        list: '일정(목록)',
        detail: '일정 상세',
      },
      list: '장비대장',
      detail: '장비대장 상세',
      history: {
        list: '장비 이력 목록',
        detail: '장비 이력 상세',
      },
      note: {
        list: '장비대장 비고 목록',
        detail: '장비대장 비고 상세',
      },
      product: {
        list: '장비 품목 목록',
        detail: '장비 품목 상세',
      },
      statistics: '현황',
    },
    quality: {
      schedule: {
        calendar: '품질 일정',
        list: '품질 일정(목록)',
        detail: '품질 일정 상세',
        statistics: '품질 일정 통계',
        export: '품질 일정 Export',
      },
      issue: {
        list: '품질 이슈 목록',
        detail: '품질 이슈 상세',
        statistics: '품질 이슈 통계',
      },
    },
    common: {
      project: {
        list: '프로젝트 목록',
        detail: '프로젝트 상세',
      },
      commonCode: {
        list: '공통코드 목록',
        detail: '공통코드 상세',
      },
      user: {
        list: '사용자 목록',
        detail: '사용자 상세',
      },
    },
  },
  validator: {
    required: '필수 입력 입니다.',
    duplicated: '값이 중복 됩니다.',
  },
  lostChange: '이 페이지를 벗어나면 마지막 저장 후 수정된 내용은 저장되지 않습니다.',
  noDataSelected: '선택된 데이터가 없습니다.',
};

export default RESOURCES;
