import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import 'antd/dist/antd.min.css';

export const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  #root {
    width: 100%;
    height: 100%;
  }

  .clearfix:before, .clearfix:after {
    content: " ";
    display: table;
  }
  .clearfix:after {
    clear: both;
  }
  /* .ant-fullcalendar-header{
    display:none;
  } */
  
  .ant-form-item {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  @media (max-width: 575px) {
    .ant-form-item .ant-form-item-label {
      padding: 0;  
    }
  }
`;
