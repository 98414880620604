import * as React from 'react';
import { Col } from 'antd';
import { IMixedComp } from 'stores/ConfigStore';
import { MODAL_TYPE_AUTO } from 'components/constants';
import { MultiSelector } from 'components';
import { IInjectedType } from 'common/@types';

interface IProps extends IInjectedType {
  config: IMixedComp[] | undefined;
  data?: object;
  column?: any[];
  pageUuid: string;
}

function splitByAlign({ config, data, form, handleSubmit, column, pageUuid }: IProps): any {
  const leftCols: any = [];
  const rightCols: any = [];
  const centerCols: any = [];
  config &&
    config.forEach((_config, key) => {
      const { modal, dataIndex, align } = _config;
      const props = {
        config: _config as any,
        data,
        form,
        handleSubmit,
        pageUuid,
      };
      if (modal === MODAL_TYPE_AUTO || _config.cType === 'buttonGroup') {
        props.config.column = column;
      }

      const component = (
        <Col key={`${dataIndex}-${key}`}>
          <MultiSelector {...props} />
        </Col>
      );

      if (align === 'right') {
        rightCols.push(component);
      } else if (align === 'center') {
        centerCols.push(component);
      } else {
        leftCols.push(component);
      }
    });
  return { leftCols, centerCols, rightCols };
}

export default splitByAlign;
