const RESOURCES = {
  change: {
    english: 'Change to Enligsh',
    korean: 'Change to Korean',
  },
  button: {
    ok: 'Ok',
    send: 'Send',
    cancel: 'Cancel',
    reset: 'Reset',
    delete: 'Delete',
    save: 'Save',
    list: 'List',
    back: 'Back',
    selectAll: 'All',
    deselect: 'Deselect',
    add: 'Add',
    addItem: 'Add Item',
    group: {
      delete: 'Delete',
      download: 'Download',
      noSelect: 'No file is selected',
      move: 'Move',
      noFolder: 'No folder is selected',
    },
    copyClipboard: 'The content to be shared has been copied.',
    noPhoneNumber: 'No phone number.',
    noEmail: 'No email address.',
  },
  newFolder: {
    button: 'New Folder',
    placeholder: 'Input Folder name',
  },
  confirm: {
    delete: 'Are you sure you want to delete?',
    save: 'Are you sure you want to save?',
    move: 'Are you sure you want to move?',
  },
  title: 'Welding part app',
  company: 'Agru Korea',
  address: 'Address',
  user: 'User',
  kanban: 'Kanban',
  backlog: 'Backlog',
  doing: 'Doing',
  review: 'Under Review',
  done: 'Done',
  calendar: 'Calendar',
  rental: 'Rental',
  sales: 'Sales',
  delivery: 'Delivery',
  calculate: 'Finance',
  machine: 'Equipment',
  education: 'Education',
  customer: 'Customer',
  as: 'A/S',
  part: 'Part',
  equipment: 'Equipment',
  oldMenu: '(Old)',
  common: 'Common',
  quality: 'Quality',
  rowCount: 'items',
  menu: {
    kanban: {
      list: 'Kanban Board',
      detail: 'Card Detail',
    },
    calendar: {
      list: 'Work Calendar',
      detail: 'Schedule Detail',
    },
    rental: {
      rent: {
        list: 'Equipment Reantal',
        detail: 'Rental Detail',
      },
      assign: {
        list: 'Equipment Assign',
        detail: 'Assign Detail',
      },
      end: {
        list: 'Rental Ends',
        detail: 'Ends Detail',
      },
      product: {
        list: 'Equipment Manage',
        detail: 'Equipment Detail',
      },
    },
    sales: {
      order: {
        list: 'Equipment Order',
        detail: 'Order Detail',
      },
      consumable: {
        list: 'Consumable Order',
        detail: 'Order Detail',
      },
    },
    delivery: {
      estimate: {
        list: 'Delivery Estimate',
      },
      packing: {
        list: 'Packing List',
        detail: 'Packing Detail',
      },
    },
    calculate: {
      target: {
        list: 'Pending Payments',
      },
      issued: {
        list: 'Request Payments',
        detail: 'Request Payment Detail',
      },
    },
    machine: {
      list: 'Equipments',
      detail: 'Equipment Detail',
      repair: {
        needCheck: 'Maintenance Required',
        list: 'Equipment Maintenance',
        detail: 'Maintenance Detail',
      },
      checklist: {
        list: 'Equipment Checklist',
        detail: 'Checklist Detail',
      },
    },
    education: {
      calendar: {
        list: 'Education Calendar',
      },
      list: 'Educations',
      detail: 'Education Detail',
      ready: 'Education Ready',
      start: 'Education Start',
      end: 'Education Ends',
      student: {
        list: 'Education Student',
        detail: 'Student Detail',
      },
      graduate: {
        list: 'Graduate Welders',
        detail: 'Welder Detail',
      },
      messagetemplate: {
        list: 'Message Template',
        detail: 'Message Template Detail',
      },
      statistics: 'Education Statistics',
    },
    customer: {
      list: 'Customers',
      detail: 'Customer Detail',
      call: {
        list: 'Customer Call',
        detail: 'Call Detail',
      },
      estimate: {
        list: 'Estimates',
        detail: 'Estimate Detail',
      },
    },
    as: {
      list: 'A/S History',
      detail: 'A/S Detail',
    },
    part: {
      list: 'Parts',
      detail: 'Part Detail',
      io: {
        list: 'Part History',
        detail: 'History Detail',
      },
    },
    public: {
      delivery: {
        checklist: 'Delivery Checklist',
        transaction: 'Delivery Receipt',
      },
      calculate: {
        issued: 'Request Payment',
      },
      education: {
        apply: 'Welder Education Apply',
      },
      estimate: 'Estimate',
      as: 'A/S Bill',
    },
    equipment: {
      schedule: {
        calendar: 'Schedule Calendar',
        list: 'Schedule List',
        detail: 'Equipment Schedule Detail',
      },
      list: 'Equipments',
      detail: 'Equipment Detail',
      history: {
        list: 'Equipment Histories',
        detail: 'Equipment History Detail',
      },
      note: {
        list: 'Equipment Notes',
        detail: 'Equipment Note Detail',
      },
      product: {
        list: 'Products',
        detail: 'Product Detail',
      },
      statistics: 'Equipment Statistics',
    },
    quality: {
      schedule: {
        calendar: 'Schedule Calendar',
        list: 'Schedule List',
        detail: 'Quality Schedule Detail',
        statistics: 'Schedule Statistics',
        export: 'Schedule Export',
      },
      issue: {
        list: 'Issue List',
        detail: 'Quality Issue Detail',
        statistics: 'Issue Statistics',
      },
    },
    common: {
      project: {
        list: 'Projects',
        detail: 'Project Detail',
      },
      commonCode: {
        list: 'Common Codes',
        detail: 'Common Code Detail',
      },
      user: {
        list: 'Users',
        detail: 'User Detail',
      },
    },
  },
  validator: {
    required: 'Input value!',
    duplicated: 'Is duplicated!',
  },
  lostChange: 'If you leave this page, your modifications will be discarded.',
  noDataSelected: 'No selected data',
};

export default RESOURCES;
