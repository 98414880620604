/* eslint-disable no-restricted-globals */
import * as queryString from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { cloneDeep, forEach, isEmpty } from 'lodash';
import { Button as AntButton, Modal } from 'antd';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { EModalMode, IButton, ICodeData, IModalProps, TModalForCaller } from 'stores/ConfigStore';
import { convertDisplay, getDataValue, i18nFromJSON } from 'components';
import { MODAL_TYPE_AUTO } from 'components/constants';
import { default as ModalSelector } from 'components/Common/ModalSelector';
import { toJS } from 'mobx';
import { api } from 'providers';
import { each as PromiseEach } from 'bluebird';
import {
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  PlusOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { IDictionary } from 'stores/KeysStore';
import { getPathFromLink } from 'common/router';

declare global {
  interface Window {
    ReactNativeWebView?: any;
  }
}

interface IButtonProps extends RouteComponentProps, IInjectedType, IInjectedStore {
  config: IButton;
  pageUuid: string;
}

type ExposedProps = Omit<IButtonProps, keyof (RouteComponentProps & IInjectedType & IInjectedStore)> &
  Partial<RouteComponentProps & IInjectedType & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Button extends React.Component<IButtonProps> {
  state = {
    modalVisible: false,
  };

  PropsByAction = {
    SUBMIT: {
      type: 'primary',
      htmlType: 'submit',
    },
    PHONE: {
      icon: <PhoneOutlined />,
      onClick: () => {
        const { data } = this.props.keyStore.getItem(this.props.pageUuid);
        const phone = getDataValue(data, this.props.config.source);
        let target = phone;
        if (typeof phone === 'object') {
          target = phone.mobile || phone.phone;
        }
        if (typeof target === 'string') {
          window.location.assign(`tel:${target}`);
        } else {
          const { t } = this.props.i18nStore;
          alert(t.button.noPhoneNumber);
        }
      },
    },
    MAIL: {
      icon: <MailOutlined />,
      onClick: () => {
        const { data } = this.props.keyStore.getItem(this.props.pageUuid);
        const email = getDataValue(data, this.props.config.source);
        if (email) {
          window.location.assign(`mailto:${email}`);
        } else if (this.props.config.modal) {
          // NOTE: modal의 onOk시 메일 보내도록.
          this.openModal();
        } else {
          const { t } = this.props.i18nStore;
          alert(t.button.noEmail);
        }
      },
    },
    MESSAGE: {
      icon: <MessageOutlined />,
      onClick: () => {
        if (this.props.config.source) {
          this.withSelectedRows(() => this.openModal());
        } else {
          this.openModal();
        }
      },
    },
    NEW: {
      onClick: () => {
        const { search = {} } = this.parseQueryString<IDictionary>(this.props.history.location.search) as {
          search: IDictionary;
        };

        this.props.history.push(`${this.props.location.pathname}/new`, { ...search });
      },
    },
    ADD: {
      icon: <PlusOutlined />,
      onClick: () => {
        this.openModal();
      },
    },
    EDIT: {
      icon: <EditOutlined />,
      onClick: () => {
        this.withSelectedRows(() => this.openModal());
      },
    },
    DELETE: {
      icon: <DeleteOutlined />,
      onClick: () => {
        this.withSelectedRows((selectedRows: any[]) => {
          forEach(selectedRows, row => (row.delete = true));
        });
      },
    },
    SET: {
      icon:
        this.props.config.icon === 'lock' ? (
          <LockOutlined />
        ) : this.props.config.icon === 'unlock' ? (
          <UnlockOutlined />
        ) : undefined,
      onClick: () => {
        this.withSelectedRows((selectedRows: any[]) => {
          const { dataIndex, value } = this.props.config;
          forEach(selectedRows, row => dataIndex && (row[dataIndex] = value));
        });
      },
    },
    LINK: {
      onClick: () => {
        const { data, search, option, ...rest } = this.props.keyStore.getItem(this.props.pageUuid);
        const path = getPathFromLink(this.props.config.link, this.props.match, {
          ...rest,
          ...search,
          ...option,
          ...data,
        });
        path && this.props.history.push(path);
      },
    },
    API: {
      onClick: async () => {
        const { request } = this.props.config;
        if (request && request.url) {
          this.props.handleSubmit &&
            (await this.props.handleSubmit(null, async data => {
              console.log('button api request', toJS(data));
              const response = await api[request.method || 'put'](request.url, data);
              console.log('button api response', response);

              return response.data;
            }));
        }
      },
    },
    URL: {
      onClick: async () => {
        const { request } = this.props.config;
        if (request && request.url) {
          const url =
            request.url.indexOf('://') > -1
              ? request.url
              : `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}${request.url}`;

          location.href = url;
        }
      },
    },
    EXPORT: {
      onClick: async () => {
        const { request } = this.props.config;
        if (request && request.url) {
          let url =
            request.url.indexOf('://') > -1
              ? request.url
              : `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}${request.url}`;

          const { search = {} } = this.parseQueryString<IDictionary>(this.props.history.location.search) as {
            search: IDictionary;
          };

          if (isEmpty(search)) {
            Modal.error({
              title: 'Error on Export',
              content: 'Full export is not allowed. Set filter condition, please.',
            });
            return;
          }

          url = `${url}?where=${JSON.stringify(search)}`;

          location.href = url;
        }
      },
    },
    SHARE: {
      onClick: async () => {
        const { data } = this.props.keyStore.getItem(this.props.pageUuid);
        if (data) {
          const { display } = this.props.config;
          let text = data.fullName || data.name;
          if (display) {
            const codeData: ICodeData = {};
            if (display) {
              await PromiseEach(
                display.filter(_dsp => typeof _dsp === 'object'),
                async _dsp => {
                  if (typeof _dsp === 'object') {
                    const codeData = await this.props.keyStore.fetchCodeData(this.props.pageUuid, _dsp.code);

                    codeData[_dsp.code] = codeData.reduce((acc: any, cur: any) => {
                      acc[cur.code] = cur.name;
                      return acc;
                    }, {});
                  }
                },
              );
            }

            text = convertDisplay(data, display, codeData)
              .filter(v => !!v)
              .join(' ');
          }

          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ event: 'share', data: { url: window.location.href, text } }),
            );
          } else {
            await window.navigator.clipboard.writeText(`${text} ${window.location.href}`);

            const { t } = this.props.i18nStore;
            alert(t.button.copyClipboard);
          }
        }
      },
    },
  };

  get selectedRows() {
    const { data } = this.props.keyStore.getItem(this.props.pageUuid);
    if (!this.props.config.source) {
      alert('need config.source');
      return;
    }
    const rows = getDataValue(data, this.props.config.source) || [];
    console.log('selectedRows rows', rows);
    const selectedRows = rows.filter((row: any) => row.checked);
    console.log('selectedRows selectedRows', selectedRows);
    return selectedRows;
  }

  parseQueryString<T>(_query: string): queryString.ParsedQuery<T> {
    const query = queryString.parse(_query);
    Object.entries(query).forEach(([key, value]) => {
      try {
        if (typeof value === 'string') {
          query[key] = JSON.parse(value);
        }
      } catch (e) {}
    });

    return query as unknown as queryString.ParsedQuery<T>;
  }

  openModal() {
    this.setState({
      modalVisible: true,
    });
  }

  closeModal() {
    if (this.props.config.source) {
      const { data } = this.props.keyStore.getItem(this.props.pageUuid);
      const rows = getDataValue(data, this.props.config.source) || [];
      rows.filter((row: any) => row.editing).forEach((row: any) => (row.editing = false));
    }
    this.setState({ modalVisible: false });
  }

  withSelectedRows(success: any) {
    const selectedRows = this.selectedRows;
    if (selectedRows.length > 0) {
      success(selectedRows);
    } else {
      const { t } = this.props.i18nStore;
      alert(t.noDataSelected);
    }
  }

  render() {
    const { cType, text, action, style, onClick, modal, title, link } = this.props.config;
    const { me } = this.props.appStore.userStore;

    if (cType !== 'button') {
      return null;
    }

    const props = (action && this.PropsByAction[action]) || {};
    if (onClick) {
      props.onClick = eval(onClick as any);
    }

    if (me && me.isReadOnly && (action === 'NEW' || link?.id === 'new')) {
      return null;
    }

    const i18nText = i18nFromJSON(text);

    // TODO: components/Form/Input, Table에 유사한 코드 있음.
    let ModalComponent: any = null;
    if (modal || action === 'MESSAGE') {
      const modalProps: IModalProps = (typeof modal === 'string' ? { mType: modal } : modal || {}) as IModalProps;
      const close = (): void => this.closeModal();
      const onCancel = (): void => close();
      let onOk = (): void => close();
      if (action === 'MAIL') {
        onOk = () => {
          const { data } = this.props.keyStore.getItem(this.props.pageUuid);
          const email = getDataValue(data, this.props.config.source);
          if (email) {
            window.location.assign(`mailto:${email}`);
          } else {
            const { t } = this.props.i18nStore;
            alert(t.button.noEmail);
          }
          close();
        };
      }

      if (action === 'MESSAGE') {
        modalProps.mType = modalProps.mType || 'message';
        modalProps.target = 'message';
        onOk = async () => {
          const { data } = this.props.keyStore.getItem(this.props.pageUuid);
          const message = getDataValue(data, modalProps.target) || {};
          console.log('message', message);

          const { request } = this.props.config;
          if (request && request.url) {
            this.props.appStore.setSpinning(true, undefined, this.props.pageUuid);

            if (this.props.config.source) {
              await PromiseEach(this.selectedRows, async row => {
                try {
                  const response = await api.post(request.url, { row, message });
                  console.log('button api response', response);
                } catch (error) {
                  console.log('error on button api', error);
                  message.error(JSON.stringify(error));
                }
              });
            } else {
              try {
                const response = await api.post(request.url, { row: data, message });
                console.log('button api response', response);
              } catch (error) {
                console.log('error on button api', error);
                message.error(JSON.stringify(error));
              }
            }

            this.props.appStore.setSpinning(false, undefined, this.props.pageUuid);
          }
          close();
        };
      }

      if (action === 'ADD' && modalProps.mType === MODAL_TYPE_AUTO) {
        modalProps.mode = EModalMode.NEW;
        modalProps.target = this.props.config.source;
        const column = cloneDeep(this.props.config.column || []);
        column.forEach(col => {
          if (col.modal) {
            if (typeof col.modal === 'string') {
              col.modal = {
                mType: col.modal,
                target: this.props.config.source,
              } as TModalForCaller;
            } else if (col.modal) {
              col.modal.target = this.props.config.source;
            }
          }
        });
        modalProps.form = { column };
      }

      console.log('modalProps', modalProps);

      ModalComponent = (
        <ModalSelector
          pageUuid={this.props.pageUuid}
          {...modalProps}
          visible={this.state.modalVisible}
          onOk={onOk}
          onCancel={onCancel}
        />
      );
    }

    if (title) {
      const i18nTitle = i18nFromJSON(title);

      return (
        <div className="ant-row ant-form-item">
          <div className="ant-form-item-label ant-col-xs-8">
            <label>{i18nTitle}</label>
          </div>
          <div className="ant-form-item-control-wrapper ant-col-xs-16">
            <AntButton {...props} style={toJS(style)}>
              {i18nText}
            </AntButton>
          </div>
          {ModalComponent}
        </div>
      );
    }
    return (
      <React.Fragment>
        <AntButton {...props} style={toJS(style)}>
          {i18nText}
        </AntButton>
        {ModalComponent}
      </React.Fragment>
    );
  }
}

export default withRouter(Button) as unknown as React.ComponentType<ExposedProps>;
