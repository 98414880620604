import { Carousel } from 'antd';
import * as React from 'react';
import { observer } from 'mobx-react';

const Image1 = require('assets/mb_purad_mob.jpg');
const Image2 = require('assets/mb_semi_mob.jpg');
const Image3 = require('assets/mb_agruline_mob.jpg');
const Image4 = require('assets/mb_safe_mob.jpg');
const Image5 = require('assets/mb_industrial_mob.jpg');

const contentStyle: React.CSSProperties = {
  maxHeight: '800px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#ffffff',
};

const imageStyle: React.CSSProperties = {
  width: '100%',
  maxWidth: '800px',
  height: '100%',
  maxHeight: '800px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
};

interface IProps {
  refer?: string;
}
const NoMatch: React.FC<IProps> = observer(({ refer }) => {
  // if (process.env.NODE_ENV === 'production') {
  //   typeof location !== 'undefined' && location.replace('/404');
  // }

  return (
    <Carousel autoplay>
      <div>
        <h3 style={contentStyle}>
          <img src={Image1} style={imageStyle} alt="" />
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}>
          <img src={Image2} style={imageStyle} alt="" />
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}>
          <img src={Image3} style={imageStyle} alt="" />
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}>
          <img src={Image4} style={imageStyle} alt="" />
        </h3>
      </div>
      <div>
        <h3 style={contentStyle}>
          <img src={Image5} style={imageStyle} alt="" />
        </h3>
      </div>
    </Carousel>
  );
});

export default NoMatch;
