import { types, flow } from 'mobx-state-tree';
import ApiProvider from 'providers/ApiProvider';
import UserStore from './UserStore';

const SpinnerModel = types
  .model('SpinnerModel', {
    pageUuid: types.identifier,
    spinning: types.optional(types.boolean, false),
    spinningTip: types.optional(types.string, ''),
  })
  .actions(self => ({
    setSpinning(value: boolean, tip?: string) {
      self.spinning = value;
      self.spinningTip = tip || '';
    },
  }));

export const AppStore = types
  .model('AppStore', {
    loaded: types.optional(types.boolean, false),
    userStore: types.optional(UserStore, { users: {} }),
    spinning: types.optional(types.boolean, false),
    spinningTip: types.optional(types.string, ''),
    modalCount: types.optional(types.number, 0),
    spinners: types.optional(types.map(SpinnerModel), {}),
  })
  .actions(self => {
    function setSpinning(value: boolean, tip?: string, pageUuid?: string) {
      if (pageUuid) {
        const spinner = getSpinner(pageUuid);
        spinner.setSpinning(value, tip);
        return;
      }

      console.log('setSpinning Modal', value);
      self.spinning = value;
      self.spinningTip = tip || '';
    }

    function modalCountPlus() {
      self.modalCount++;
    }

    function modalCountMinus() {
      self.modalCount--;
      if (self.modalCount < 0) {
        self.modalCount = 0;
      }
    }

    function getSpinner(pageUuid: string) {
      let spinner = self.spinners.get(pageUuid);
      while (!spinner) {
        self.spinners.put({ pageUuid });
        spinner = self.spinners.get(pageUuid);
      }
      return spinner;
    }

    const init = flow(function* () {
      // user & organization init
      yield self.userStore.init();
      self.loaded = true;
    });

    return {
      setSpinning,
      modalCountPlus,
      modalCountMinus,
      getSpinner,
      init,
    };
  });

type IAppStoreType = typeof AppStore.Type;
export interface IAppStore extends IAppStoreType {}

const appStore = AppStore.create({}, { provider: ApiProvider });

export default appStore;
