import * as React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { i18nFromJSON, getDataValue } from 'components';
import { IConfigComp, ILocale } from 'stores/ConfigStore';

interface ITitleDescProps {
  config: {
    title?: ILocale;
    desc?: ILocale;
  } & IConfigComp;
  data: any;
  pageUuid: string;
}

const Container = styled.div``;

const TitleDescContainer = styled.div`
  margin-bottom: 5px;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Desc = styled.div`
  padding-left: 10px;
`;

const TitleDesc = (props: any) => {
  const { title, desc } = props.data;
  const i18nTitle = i18nFromJSON(title);
  const i18nDesc = i18nFromJSON(desc);
  return (
    <TitleDescContainer>
      <Title>{i18nTitle}</Title>
      <Desc>{i18nDesc}</Desc>
    </TitleDescContainer>
  );
};

@observer
class TitleWithDesc extends React.Component<ITitleDescProps> {
  render() {
    const { config, data } = this.props;
    const { cType, style, dataIndex } = config;

    if (cType !== 'titleWithDesc') {
      return null;
    }

    const _data = data && getDataValue(data, dataIndex);

    if (Array.isArray(_data)) {
      const components = _data.map(({ title, desc }, index) => (
        <TitleDesc data={{ title, desc }} key={`${title}-${index}`} />
      ));
      return <Container style={toJS(style)}>{components}</Container>;
    }
    const { title, desc } = _data || config;
    return <TitleDesc data={{ title, desc }} />;
  }
}

export default TitleWithDesc;
