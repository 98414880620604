import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Button, Form } from 'antd';
import { IModalFormProps } from 'stores/ConfigStore';
import { Hr, MultiSelector } from 'components';
import { IInjectedStore, Omit } from 'common/@types';
import { merge } from 'lodash';
import { toJS } from 'mobx';
import { FormInstance } from 'antd/es/form';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';

const ButtonWrapper = styled.div`
  text-align: center;
  button {
    margin: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

interface IProps extends IModalFormProps, IInjectedStore {
  pageUuid: string;
}

type ExposedProps = Omit<IProps, keyof IInjectedStore> & Partial<IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class ModalForm extends React.Component<IProps> {
  formRef = React.createRef<FormInstance>();

  onBack = () => {
    this.props.onBack();
  };

  onSubmit = async () => {
    let values: any;

    try {
      values = await this.formRef.current?.validateFields();
    } catch (error) {
      const errorInfo = error as ValidateErrorEntity;
      values = { ...errorInfo.values };

      // skip initial check
      if (errorInfo.errorFields.find(({ name }) => name.find(name => name !== 'initial'))) {
        return;
      }
    }

    console.log('Received values of modal form: ', values);
    this.props.onSubmit(merge(toJS(this.props.data), values));
  };

  render() {
    const {
      config,
      data,
      onBack,
      style,
      pageUuid,
      i18nStore: { t },
    } = this.props;
    const { column } = config;

    const backButton = onBack ? (
      <Button key="back" onClick={this.onBack}>
        {t.button.list}
      </Button>
    ) : null;

    return (
      <Form ref={this.formRef} style={{ paddingTop: 30, ...style }}>
        {column.map((_column: any, key: string) => {
          if (!_column.cType) {
            _column.cType = 'input';
          }

          return (
            <MultiSelector
              config={{ ..._column }}
              data={data}
              key={`${_column.cType}-${key}`}
              pageUuid={pageUuid}
              form={this.formRef}
              mode="form"
            />
          );
        })}
        <Hr />
        <ButtonWrapper>
          {backButton}
          <Button key="submit" htmlType="submit" type="primary" onClick={this.onSubmit}>
            {t.button.ok}
          </Button>
        </ButtonWrapper>
      </Form>
    );
  }
}

export default ModalForm as any as React.ComponentType<ExposedProps>;
