import * as React from 'react';
import { Modal } from 'antd';
import { dangerouslySetInnerHTML } from './componentHelper';
import { i18nStoreInstance } from 'stores';

export interface IConfirmDialogOptions {
  title?: string | React.ReactNode;
  content: React.ReactNode;
}

const confirmDialog = function (options: IConfirmDialogOptions) {
  return new Promise<boolean>((resolve, reject) => {
    const modalContent =
      typeof options.content === 'string' ? <span {...dangerouslySetInnerHTML(options.content)} /> : options.content;

    const { t } = i18nStoreInstance.get();

    Modal.confirm({
      zIndex: 1040,
      okText: t.button.ok,
      cancelText: t.button.cancel,
      title: options.title ? options.title : modalContent,
      content: options.title ? modalContent : '',
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(false);
      },
    });
  });
};

export default confirmDialog;
