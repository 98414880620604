import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { IInjectedStore, Omit } from 'common/@types';
import { Location } from 'history';
import { FormInstance } from 'antd/es/form';

const LogoAK = require('assets/ak.png');

const FormItem = Form.Item;

const TableContainer = styled.div`
  .login-form {
    margin: 25px auto 0;
    max-width: 300px;
  }
  .login-form-forgot {
    float: right;
  }
  .login-form-button {
    width: 100%;
  }
`;

interface IPageProps extends RouteComponentProps, IInjectedStore {}

type ExposedProps = Omit<IPageProps, keyof (RouteComponentProps & IInjectedStore)> &
  Partial<RouteComponentProps & IInjectedStore>;

@inject('appStore', 'i18nStore')
@observer
class Login extends React.Component<IPageProps> {
  formRef = React.createRef<FormInstance>();

  componentDidMount() {
    this.handleLogin();
  }

  componentDidUpdate() {
    this.handleLogin();
  }

  handleLogin() {
    if (this.props.appStore.userStore.me) {
      const location = (this.props.location || {}) as Location<{ referrer?: string }>;
      this.props.history.push(location.state?.referrer || '/');
    }
  }

  handleSubmit = async () => {
    const values = await this.formRef.current?.validateFields();

    try {
      const { login } = this.props.appStore.userStore;
      await login(values.userName, values.password);
      this.handleLogin();
    } catch (error) {
      message.error('Login fail');
    }
  };

  render() {
    return (
      <div>
        <div style={{ padding: '40px', maxWidth: '375px', margin: '40px auto 0' }}>
          <img src={LogoAK} style={{ width: '100%', marginTop: 40 }} />
        </div>
        <TableContainer>
          <Form ref={this.formRef} onFinish={this.handleSubmit} className="login-form">
            <FormItem name="userName" rules={[{ required: true, message: 'Please input your username!' }]}>
              <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
            </FormItem>
            <FormItem name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
              />
            </FormItem>
            <FormItem>
              {/* {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}
            <a className="login-form-forgot" href="">
              Forgot password
            </a> */}
              <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
              </Button>
              {/* Or <a href="">register now!</a> */}
            </FormItem>
          </Form>
        </TableContainer>
      </div>
    );
  }
}

export default withRouter(Login) as React.ComponentType<ExposedProps>;
