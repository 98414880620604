import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EModalMode, IModalProps } from 'stores/ConfigStore';
import { MODAL_TYPE_AUTO } from 'components/constants';
import { Form, Search, SearchWithForm } from 'components/Modal';
import { modalConfig } from 'mocks/modal';
import { computed, IReactionDisposer, observable, reaction, toJS } from 'mobx';

import { IInjectedStore, Omit } from 'common/@types';
import { api } from 'providers';
import { getSelectSchema } from 'common/pageHelper';
import { cloneDeep, isEqual } from 'lodash';
import { message } from 'antd';

const components = {
  search: Search,
  form: Form,
  searchWithForm: SearchWithForm,
};

function findComponent(cType: string): any {
  return components[cType];
}

interface IModalSelector extends IModalProps, IInjectedStore {
  pageUuid: string;
}
type ExposedProps = Omit<IModalSelector, keyof IInjectedStore> & Partial<IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class ModalSelector extends React.Component<IModalSelector> {
  visibleReaction: IReactionDisposer | undefined;
  searchReaction: IReactionDisposer | undefined;
  prevSearch: any;
  pageReaction: IReactionDisposer | undefined;
  prevPage: any;
  visible: boolean = false;
  @observable config: any;

  constructor(props: IModalSelector) {
    super(props);
    this.config = this.getConfig();
  }

  @computed
  get pageUuid() {
    return `${this.props.pageUuid}/${this.config.cType}`;
  }

  getConfig() {
    const mType = this.props.mType;
    const mode = this.props.mode;

    let _config: any = {};
    if (mType === MODAL_TYPE_AUTO) {
      _config = { cType: 'form' };
    } else {
      _config = cloneDeep(modalConfig[mType][0]);
      if (!_config.cType && mode === EModalMode.EDIT) {
        _config.cType = 'form';
      }
    }
    return _config;
  }

  componentDidMount() {
    this.visibleReaction = reaction(
      () => this.props.visible,
      newVisible => {
        if (this.visible !== newVisible) {
          if (newVisible) {
            this.props.appStore.modalCountPlus();
            this.changeToShow();
          } else {
            this.props.appStore.modalCountMinus();
            this.changeToHide();
          }
        }
        this.visible = !!newVisible;
      },
    );
  }

  componentDidUpdate(prevProps: IModalSelector) {
    if (prevProps.mType !== this.props.mType || prevProps.mode !== this.props.mode) {
      this.config = this.getConfig();
    }
  }

  componentWillUnmount() {
    this.visibleReaction && this.visibleReaction();
  }

  changeToShow() {
    console.log('changeToShow / pageUuId', this.pageUuid);
    console.log('changeToShow / parentPageUuid', this.props.pageUuid);
    console.log('changeToShow / modal config', this.config);
    console.log('changeToShow / modal props', this.getProps());
    // default data from parent
    const { data, page = {} } = this.props.keyStore.getItem(this.props.pageUuid);
    console.log('changeToShow / data', data);

    this.props.keyStore.mergeItem(this.pageUuid, {
      form: toJS(data),
      search: { search: this.props.defaultKeyword || '' },
      page: { pageSize: page.pageSize },
      parentPageUuid: this.props.pageUuid,
    });

    this.searchReaction = this.props.keyStore.addReaction(this.pageUuid, 'search', _search => {
      const search = toJS(_search || {}) || {};
      console.log('search reaction', search);

      if (this.props.visible) {
        if (!isEqual(search, this.prevSearch || {})) {
          this.prevSearch = search;
          this.getData();
        }
      }
    });

    this.pageReaction = this.props.keyStore.addReaction(this.pageUuid, 'page', _page => {
      const page = toJS(_page || {}) || {};
      console.log('page reaction', page);

      if (this.props.visible) {
        if (!isEqual(page, this.prevPage || {})) {
          this.prevPage = page;
          this.getData();
        }
      }
    });

    this.getData();
  }

  changeToHide() {
    console.log('changeToHide');
    this.searchReaction && this.searchReaction(); // dispose reaction
    this.pageReaction && this.pageReaction(); // dispose reaction
    this.clearStore();
  }

  clearStore() {
    this.prevSearch = undefined;
    this.props.keyStore.clearItem(this.pageUuid);
  }

  async getData() {
    if (this.config.search && this.config.search.url) {
      try {
        this.props.appStore.setSpinning(true, undefined, this.pageUuid);

        const [path, params] = this.config.search.url.split('?');

        let url = `${path}?`;

        const { search = {}, page = {} } = this.props.keyStore.getItem(this.pageUuid);
        console.log('getData search', search);
        if (Object.keys(search).length) {
          url = `${url}&where=${encodeURIComponent(JSON.stringify(search))}&${params}`;
        }
        if (params) {
          url = `${url}&${params}`;
        }
        // page
        if (page.page) {
          url = `${url}&page=${page.page}`;
        }
        if (page.pageSize) {
          url = `${url}&pageSize=${page.pageSize}`;
        }

        console.log('getData url', url);
        const response = await api.get(url);
        console.log('getData response', response);

        await getSelectSchema(this.props.keyStore, this.pageUuid, this.config, path);

        this.props.keyStore.mergeItem(this.pageUuid, {
          data: response.data || {},
        });
      } catch (error) {
        console.log('error on getData', error);
        message.error(JSON.stringify(error));
      } finally {
        this.props.appStore.setSpinning(false, undefined, this.pageUuid);
      }
    } else {
      // get schema
      if (this.config.form && this.config.form.url) {
        try {
          this.props.appStore.setSpinning(true, undefined, this.pageUuid);

          const [path, _] = this.config.form.url.split('?');
          await getSelectSchema(this.props.keyStore, this.pageUuid, this.config, path);
        } catch (error) {
          console.log('error on getSelectSchema', error);
          message.error(JSON.stringify(error));
        } finally {
          this.props.appStore.setSpinning(false, undefined, this.pageUuid);
        }
      }

      this.props.keyStore.mergeItem(this.pageUuid, { data: {} });
    }
  }

  getProps() {
    const props: any = { ...this.props };
    delete props.appStore;
    delete props.i18nStore;
    delete props.keyStore;
    delete props.pageUuid;
    return props;
  }

  render() {
    const Modal = findComponent(this.config.cType);
    if (Modal) {
      return <Modal pageUuid={this.pageUuid} {...this.config} {...this.getProps()} />;
    }

    return null;
  }
}

export default ModalSelector as React.ComponentType<ExposedProps>;
