import * as React from 'react';
import { observer } from 'mobx-react';
import { Heading } from 'components/Typo';
import { Table } from 'components/Table';
import { IInjectedType, Omit } from 'common/@types';
import { ILinkConfig, ITableConfig } from 'stores/ConfigStore';

interface ILinkProps extends IInjectedType {
  dataIndex: string;
  data?: any;
  pageUuid: string;
  config: ILinkConfig;
}
type ExposedProps = Omit<ILinkProps, keyof IInjectedType> & Partial<IInjectedType>;

@observer
class Link extends React.Component<ILinkProps> {
  render() {
    const { dataIndex, data, form, handleSubmit, pageUuid, mode } = this.props;

    return (
      <div>
        <Heading pageUuid={pageUuid} config={{ cType: 'heading', title: 'Link' }} />
        <Table
          dataIndex={dataIndex}
          data={data}
          form={form}
          mode={mode}
          pageUuid={pageUuid}
          handleSubmit={handleSubmit}
          config={
            {
              style: { container: { marginTop: 20 } },
              attr: { pagination: false, size: 'middle' },
              editModal: 'auto',
              column: [
                { cType: 'checkbox', dataIndex: 'checked' },
                {
                  title: { ko: '제목', en: 'Name' },
                  dataIndex: 'name',
                  cType: 'input',
                },
                {
                  title: { ko: '담당자', en: 'Manager' },
                  dataIndex: 'user.name',
                  cType: 'input',
                  modal: {
                    mType: 'user',
                    namespace: 'user',
                    dataIndexes: {
                      '*': '*',
                    },
                  },
                },
                {
                  title: { ko: '메모', en: 'Memo' },
                  dataIndex: 'memo',
                  cType: 'memo',
                  hidden: true,
                },
              ],
              footer: [
                {
                  cType: 'button',
                  text: { ko: '삭제', en: '삭제' },
                  action: 'DELETE',
                  source: `${dataIndex}`,
                  align: 'left',
                },
                {
                  cType: 'button',
                  text: { ko: '업무 추가', en: '업무 추가' },
                  action: 'ADD',
                  modal: 'auto',
                  source: `${dataIndex}`,
                  align: 'right',
                },
              ],
            } as ITableConfig
          }
        />
      </div>
    );
  }
}

export default Link as React.ComponentType<ExposedProps>;
