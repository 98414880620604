const delay = (milliseconds: number) =>
  new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });

export const download = async (url: string, name: string) => {
  const a = document.createElement('a');
  if (!window.ReactNativeWebView) {
    a.download = name;
  }
  a.href = url;
  a.style.display = 'none';
  document.body.append(a);
  a.click();

  // Chrome requires the timeout
  await delay(100);
  a.remove();
};

export default async function multiDownload(urls: { url: string; name: string }[]) {
  if (!urls) {
    throw new Error('`urls` required');
  }

  for (const [index, row] of urls.entries()) {
    await delay(index * 1000);
    download(row.url, row.name);
  }
}
