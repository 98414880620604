import * as React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { EInputAction, IDateRange } from 'stores/ConfigStore';
import { DatePicker as AntDatePicker } from 'antd';
import { AntFormItem, getDataValue, i18nFromJSON } from 'components';
import { DATE_FORMAT } from 'components/constants';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { set } from 'lodash';
import { RangeValue } from 'rc-picker/lib/interface';
import { IDictionary } from 'stores/KeysStore';

const AntRangePicker = AntDatePicker.RangePicker;

interface IRangeProps extends IInjectedType, IFormItemProps, IInjectedStore {
  config: IDateRange;
  data: any;
  pageUuid: string;
}
type ExposedProps = Omit<IRangeProps, keyof (IInjectedType & IFormItemProps & IInjectedStore)> &
  Partial<IInjectedType & IFormItemProps & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class DateRangePicker extends React.Component<IRangeProps> {
  handleChange = (dates: RangeValue<any>): void => {
    console.log(`DateRangePicker / handleChange:`, dates);
    const { dataIndex, action } = this.props.config;
    if (!dataIndex) {
      return;
    }
    if (action === EInputAction.QUERY) {
      const { search = {}, page = {} } = this.props.keyStore.getItem(this.props.pageUuid);
      const newSearch: IDictionary = {
        ...search,
        [dataIndex]: {
          $between: [
            moment((dates && dates[0]) ?? undefined).format(DATE_FORMAT),
            moment((dates && dates[1]) ?? undefined).format(DATE_FORMAT),
          ],
        },
      };
      if (!dates) {
        delete newSearch[dataIndex];
      }
      console.log('newSearch', newSearch);
      this.props.keyStore.mergeItem(this.props.pageUuid, {
        search: newSearch,
        page: { pageSize: page.pageSize },
      });
    } else {
      const value = `${moment((dates && dates[0]) ?? undefined).format(DATE_FORMAT)}-${moment(
        (dates && dates[1]) ?? undefined,
      ).format(DATE_FORMAT)}`;
      set(this.props.data, dataIndex, value);
      this.props.form.current?.setFieldValue(dataIndex, value);
    }
  };

  componentDidMount() {
    const { config } = this.props;
    const { dataIndex, action, defaultRange } = config;

    if (action === EInputAction.QUERY && dataIndex) {
      const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);
      const searchData = ((search[dataIndex] || {}) as { $between: string[] }).$between || [];

      if (defaultRange && !searchData.length) {
        this.handleChange([moment().startOf(defaultRange), moment().endOf(defaultRange)]);
      }
    }
  }

  render() {
    const {
      config,
      data,
      form,
      pageUuid,
      i18nStore: { t },
    } = this.props;
    const {
      cType,
      dataIndex,
      beginDataIndex,
      endDataIndex,
      format,
      style,
      isRequire,
      readOnly,
      editOnlyNew,
      action,
      placeholder,
      defaultRange,
    } = config;

    if (cType !== 'dateRange') {
      return null;
    }

    const outputFormat = format || DATE_FORMAT;
    const beginStringDate = getDataValue(data, beginDataIndex) || null;
    const endStringDate = getDataValue(data, endDataIndex) || null;
    const fieldValue: any = [];

    if (action === EInputAction.QUERY && dataIndex) {
      const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);
      const searchData = ((search[dataIndex] || {}) as { $between: string[] }).$between || [];

      if (searchData[0]) {
        fieldValue.push(moment(searchData[0]));
      } else {
        fieldValue.push(null);
      }

      if (searchData[1]) {
        fieldValue.push(moment(searchData[1]));
      } else {
        fieldValue.push(null);
      }
    } else {
      if (beginStringDate) {
        fieldValue.push(moment(beginStringDate));
      } else if (defaultRange) {
        fieldValue.push(moment().startOf(defaultRange));
      } else {
        fieldValue.push(null);
      }

      if (endStringDate) {
        fieldValue.push(moment(endStringDate));
      } else if (defaultRange) {
        fieldValue.push(moment().endOf(defaultRange));
      } else {
        fieldValue.push(null);
      }
    }

    console.log('DateRangePicker', fieldValue);

    const fieldName = `range/${beginDataIndex?.replace('.', '_')}/${endDataIndex?.replace('.', '_')}`;

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={fieldName}
          initialValue={fieldValue}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <AntRangePicker
            placeholder={i18nFromJSON(placeholder as any)}
            format={outputFormat}
            style={{ width: '250px', ...style }}
            disabled={readOnly || (editOnlyNew && pageUuid.indexOf('/new') === -1)}
            onChange={this.handleChange}
          />
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default DateRangePicker as React.ComponentType<ExposedProps>;
