import * as React from 'react';
import { IInputGroup } from 'stores/ConfigStore';
import { Input } from 'antd';
import { IInjectedType, Omit } from 'common/@types';
import { MultiSelector } from 'components';
import { observer } from 'mobx-react';

const AntGroup = Input.Group;
interface IInputGroupProps extends IInjectedType {
  config: IInputGroup;
  data: any;
  pageUuid: string;
}
type ExposedProps = Omit<IInputGroupProps, keyof IInjectedType> & Partial<IInjectedType>;

@observer
class InputGroup extends React.Component<IInputGroupProps> {
  render() {
    const { config, data, form, mode, handleSubmit, pageUuid } = this.props;
    const { cType, column } = config;

    if (cType !== 'inputGroup') {
      return null;
    }

    const components =
      column &&
      column.map((_config, index) => (
        <MultiSelector
          config={_config}
          data={data}
          key={`inputGroup-${index}`}
          pageUuid={pageUuid}
          form={form}
          mode={mode}
          handleSubmit={handleSubmit}
        />
      ));

    return <AntGroup compact>{components}</AntGroup>;
  }
}

export default InputGroup as React.ComponentType<ExposedProps>;
