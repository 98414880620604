import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import moment from 'moment';
import styled from 'styled-components';
import { FormRule, Input as AntInput } from 'antd';
import { IInput, IModalProps } from 'stores/ConfigStore';
import { IUser } from 'stores/UserStore';
import { AntFormItem, getDataValue } from 'components';
import { DATE_FORMAT } from 'components/constants';
import { default as ModalSelector } from 'components/Common/ModalSelector';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { api } from 'providers';

interface IInputProps extends IInjectedType, IInjectedStore, IFormItemProps {
  config: IInput;
  data: IUser;
  pageUuid: string;
}
type ExposedProps = Omit<IInputProps, keyof (IInjectedType & IInjectedStore)> & Partial<IInjectedType & IInjectedStore>;

const StyledWrapper = styled.div`
  input[readonly] {
    background-color: #f4f4f4;
  }
`;

@inject('keyStore', 'appStore', 'i18nStore')
@observer
class Input extends React.Component<IInputProps> {
  state = { modalVisible: false };

  render() {
    const {
      config,
      data,
      form,
      pageUuid,
      i18nStore: { t },
    } = this.props;
    const { cType, placeholder, dataIndex, defaultValue, style, isRequire, modal, readOnly, editOnlyNew, validators } =
      config;

    if (cType !== 'input') {
      return null;
    }

    let inputProps: any = {};
    if (readOnly || (editOnlyNew && pageUuid.indexOf('/new') === -1)) {
      inputProps = { ...inputProps, disabled: true };
    }

    let fieldValue = getDataValue(data, dataIndex);

    if (moment.isMoment(fieldValue)) {
      fieldValue = moment(fieldValue).format(DATE_FORMAT);
    }

    // TODO: components/Common/Button, Table에 유사한 코드 있음.
    let ModalComponent: any = null;
    if (modal) {
      const modalProps: IModalProps = typeof modal === 'string' ? { mType: modal } : modal;
      if (dataIndex && !modalProps.dataIndexes) {
        modalProps.dataIndexes = { [dataIndex]: dataIndex };
      }

      inputProps.readOnly = true;
      inputProps.onClick = () => this.setState({ modalVisible: true });
      const close = (): void => this.setState({ modalVisible: false });
      const onOk = (): void => close();
      const onCancel = (): void => close();
      ModalComponent = (
        <ModalSelector
          pageUuid={pageUuid}
          {...modalProps}
          visible={this.state.modalVisible}
          onOk={onOk}
          onCancel={onCancel}
        />
      );
    }

    const rules: FormRule[] = [];
    if (validators && validators.length) {
      validators.forEach(({ type, url }) => {
        rules.push({
          message: t.validator.duplicated,
          validator: async (rule, value) => {
            const { data } = await api.get(`${url}${url.indexOf('?') === -1 ? '?' : '&'}${dataIndex}=${value}`);
            console.log('validator', data);
            const { id } = this.props.keyStore.getItem(this.props.pageUuid);
            console.log('page.id', id);

            switch (type) {
              case 'duplicate':
                const findRow = data.rows.find((row: any) => row.id != id);
                if (findRow) {
                  return 'duplicated';
                }
                break;
              default:
                break;
            }

            return;
          },
        });
      });
    }

    const initialValue = `${fieldValue || defaultValue || ''}`;

    return (
      <StyledWrapper>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          initialValue={initialValue}
          rules={[{ required: isRequire, message: t.validator.required }, ...rules]}
          form={form}
        >
          <AntInput placeholder={placeholder} style={toJS(style)} {...inputProps} />
        </AntFormItem>
        {this.props.children}
        {ModalComponent}
      </StyledWrapper>
    );
  }
}

export default Input as React.ComponentType<ExposedProps>;
