import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Col, Input, Row, Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import styled from 'styled-components';
import { IModalSearchProps, ITableColumn } from 'stores/ConfigStore';
import { i18nFromJSON } from 'components';
import { IInjectedStore, Omit } from 'common/@types';
import { observable, toJS } from 'mobx';

const Wrapper = styled.div`
  padding-top: 30px;
  overflow: auto;
`;

const AntSearch = Input.Search;

interface ISearchProps extends IModalSearchProps, IInjectedStore {
  pageUuid: string;
}

type ExposedProps = Omit<ISearchProps, keyof IInjectedStore> & Partial<IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Search extends React.Component<ISearchProps> {
  @observable
  selectedRowKeys: React.Key[] = [];

  onSearch = async (value: string) => {
    const { page = {}, search = {} } = this.props.keyStore.getItem(this.props.pageUuid);
    this.props.keyStore.mergeItem(this.props.pageUuid, {
      search: { ...search, search: value },
      page: { pageSize: page.pageSize },
    });
  };

  onPageChange = (page: number, pageSize: number) => {
    if (this.props.checkable) {
      this.selectedRowKeys = [];
      this.props.onSelect({});
    }
    this.props.keyStore.mergeItem(this.props.pageUuid, { page: { page, pageSize } });
  };

  render() {
    const { checkable } = this.props;

    let rowSelection: TableRowSelection<any> | undefined;
    if (checkable) {
      rowSelection = {
        type: 'radio',
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
          this.selectedRowKeys = selectedRowKeys;
          this.props.onSelect(selectedRows[0]);
        },
      };
    }

    const touchedColumn = (this.props.config.column as ITableColumn[]).reduce(
      (_touchedColumn: ITableColumn[], column: ITableColumn) => {
        const _column = toJS(column);
        const { title, render, option, cType, dataIndex } = _column;
        const i18nTitle = title ? i18nFromJSON(title) : title;

        if (render || option) {
          if (Array.isArray(render)) {
            _column.render = val => {
              let rtVal = val;
              render.forEach(({ text, value }) => {
                if (val === value) {
                  rtVal = text;
                }
              });
              return rtVal;
            };
          } else if (Array.isArray(option)) {
            _column.render = val => {
              let rtVal = val;
              option.forEach(({ text, value }) => {
                if (val === value) {
                  rtVal = text;
                }
              });
              return rtVal;
            };
          } else if (typeof render === 'function') {
            // to nothing
          } else {
            delete _column.render;
          }
        }

        if (cType === 'checkbox' && dataIndex !== 'checked') {
          _column.render = val => (val ? 'Y' : 'N');
        }

        _touchedColumn.push({ ..._column, title: i18nTitle });
        return _touchedColumn;
      },
      [],
    );

    const { data, search = {} } = this.props.keyStore.getItem(this.props.pageUuid);

    console.log('render touchedColumn', touchedColumn);
    console.log('render rows', data && data.rows);

    return (
      <Wrapper>
        {this.props.config.add && this.props.config.add.addable ? (
          <Row style={{ paddingBottom: '10px' }} justify="space-between">
            {typeof search.search === 'string' ? (
              <Col>
                <AntSearch onSearch={this.onSearch} defaultValue={search.search} placeholder="검색어" enterButton />
              </Col>
            ) : null}
            <Col>
              <Button onClick={this.props.onClickClear} style={{ marginRight: '10px' }}>
                Clear
              </Button>
              <Button onClick={this.props.onClickNew}>New</Button>
            </Col>
          </Row>
        ) : (
          <Row style={{ paddingBottom: '10px' }} justify="space-between">
            <Col>
              <AntSearch onSearch={this.onSearch} defaultValue={search.search} placeholder="검색어" enterButton />
            </Col>
            <Col>
              <Button onClick={this.props.onClickClear}>Clear</Button>
            </Col>
          </Row>
        )}
        <Table
          dataSource={data && data.rows}
          columns={(touchedColumn || [])
            .filter(col => !col.hidden)
            .map(column => {
              let dataIndex = column.displayIndex || column.dataIndex;
              if (typeof dataIndex === 'string') {
                dataIndex = dataIndex.split('.');
              }
              return {
                ...column,
                dataIndex,
              };
            })}
          onRow={row => ({
            onClick: () => {
              this.selectedRowKeys = [row.id || row.userId];
              this.props.onSelect(row);
            },
          })}
          rowSelection={rowSelection}
          rowKey={data?.rows[0]?.id ? 'id' : data?.rows[0]?.userId ? 'userId' : 'id'}
          pagination={
            data && {
              size: 'small',
              style: { float: 'none', textAlign: 'center' },
              total: data.total,
              pageSize: data.pageSize,
              current: data.page,
              onChange: this.onPageChange,
            }
          }
        />
      </Wrapper>
    );
  }
}

export default Search as React.ComponentType<ExposedProps>;
