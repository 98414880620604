import * as React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { EInputAction, IDate } from 'stores/ConfigStore';
import { DatePicker as AntDatePicker } from 'antd';
import { AntFormItem, getDataValue, i18nFromJSON } from 'components';
import { DATE_FORMAT } from 'components/constants';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { IDictionary } from 'stores/KeysStore';

interface IDateProps extends IInjectedType, IInjectedStore, IFormItemProps {
  config: IDate;
  data: any;
  pageUuid: string;
}
type ExposedProps = Omit<IDateProps, keyof (IInjectedType & IInjectedStore)> & Partial<IInjectedType & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class DatePicker extends React.Component<IDateProps> {
  handleChange = (_date: any): void => {
    console.log(`DatePicker / handleChange`, _date);
    const date = moment.isMoment(_date) ? moment(_date).format(DATE_FORMAT) : _date;

    const { dataIndex, action } = this.props.config;
    if (!dataIndex) {
      return;
    }
    if (action === EInputAction.QUERY) {
      const { search = {}, page = {} } = this.props.keyStore.getItem(this.props.pageUuid);
      const newSearch: IDictionary = { ...search, [dataIndex]: date };
      if (!date) {
        delete newSearch[dataIndex];
      }
      console.log('newSearch', newSearch);
      this.props.keyStore.mergeItem(this.props.pageUuid, { search: newSearch, page: { pageSize: page.pageSize } });
    }
  };

  render() {
    const {
      config,
      data,
      form,
      pageUuid,
      i18nStore: { t },
    } = this.props;
    const { searchIndex, action, cType, dataIndex, format, style, isRequire, readOnly, editOnlyNew, placeholder } =
      config;

    if (cType !== 'date') {
      return null;
    }

    let fieldValue: any;
    const targetIndex = searchIndex || dataIndex;

    if (action === EInputAction.QUERY && targetIndex) {
      const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);
      fieldValue = search[targetIndex];
    } else {
      fieldValue = getDataValue(data, targetIndex);
    }

    const outputFormat = format || DATE_FORMAT;

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          initialValue={fieldValue ? moment(fieldValue) : null}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <AntDatePicker
            placeholder={i18nFromJSON(placeholder as any)}
            format={outputFormat}
            style={{ width: 130, ...style }}
            disabled={readOnly || (editOnlyNew && pageUuid.indexOf('/new') === -1)}
            onChange={this.handleChange}
          />
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default DatePicker as React.ComponentType<ExposedProps>;
