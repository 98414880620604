import * as React from 'react';
import { observer } from 'mobx-react';
import { IMixedComp } from 'stores/ConfigStore';
import { Row } from 'antd';
import { IInjectedType, Omit } from 'common/@types';
import { splitByAlign } from 'components';
import styled from 'styled-components';

const HeaderRow = styled(Row)`
  .ant-form-item {
    margin-bottom: 0 !important;
  }
`;

interface IHeaderProps extends IInjectedType {
  config: IMixedComp[] | undefined;
  data?: object;
  pageUuid: string;
}
type ExposedProps = Omit<IHeaderProps, keyof IInjectedType> & Partial<IInjectedType>;

@observer
class Header extends React.Component<IHeaderProps> {
  render() {
    if (!this.props.config) {
      return null;
    }

    const { leftCols, centerCols, rightCols } = splitByAlign(this.props);
    return (
      <HeaderRow style={{ paddingBottom: '10px' }} justify="space-between">
        <Row gutter={10} align="bottom">
          {leftCols}
        </Row>
        <Row gutter={10} align="bottom">
          {centerCols}
        </Row>
        <Row gutter={10} align="bottom">
          {rightCols}
        </Row>
      </HeaderRow>
    );
  }
}

export default Header as React.ComponentType<ExposedProps>;
