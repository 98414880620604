import * as React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { i18nFromJSON } from 'components';
import { ILocale } from 'stores/ConfigStore';

interface IHeadingProps {
  config: {
    cType: string;
    title?: ILocale | string;
    label?: React.ReactNode;
    subTitle?: ILocale;
    style?: object;
  };
  pageUuid: string;
}

const Title = styled.h1`
  font-size: 18px;
  margin: 5px 0 0 0;
  padding: 0;
`;
const SubTitle = styled.small`
  font-size: 70%;
`;

@observer
class Heading extends React.Component<IHeadingProps> {
  render() {
    const { cType, title, subTitle, style, label } = this.props.config;

    if (cType !== 'heading') {
      return null;
    }

    const i18nTitle = i18nFromJSON(title);
    const i18nSubTitle = i18nFromJSON(subTitle);
    const i18nSubTitleTag = i18nSubTitle ? <SubTitle>{i18nSubTitle}</SubTitle> : null;

    return (
      <Title style={toJS(style)}>
        {label || i18nTitle}
        {i18nSubTitleTag}
      </Title>
    );
  }
}

export default Heading;
