import { getDataValue } from './componentHelper';
import { api } from 'providers';
import { each as PromiseEach } from 'bluebird';
import { IKeysStore } from '../stores';

export async function getSelectSchema(keyStore: IKeysStore, pageUuid: string, config: any, url: string) {
  try {
    const path = (() => {
      if (url.match(/:(\w+)$/g)) {
        return url.replace(/:(\w+)$/g, '/schema').replace('//', '/');
      } else if (url.slice(-1) === 's') {
        return url.replace(/.$/, '/schema').replace('//', '/');
      }
      return `${url}/schema`.replace('//', '/');
    })();
    const response = await api.get(path);
    const schema = response.data;

    interface IStateFindSelect {
      depth: number;
      namespace?: string;
    }

    const findSelectType = async (
      _value: any,
      _parent?: any,
      _key?: string | number,
      _depth: number = 0,
      _state: IStateFindSelect = { depth: 0 },
    ) => {
      if (Array.isArray(_value)) {
        await PromiseEach(
          _value,
          async (val, idx) => await findSelectType(val, _value, idx, _depth + 1, { ..._state }),
        );
      } else if (_value && typeof _value === 'object' && Object.keys(_value).length) {
        const newState = { ..._state };
        if (_value.cType === 'Table') {
          newState.namespace = _value.dataIndex;
          newState.depth = _depth;
        }

        await PromiseEach(
          Object.keys(_value),
          async key => await findSelectType(_value[key], _value, key, _depth + 1, newState),
        );
      } else if (_key === 'cType' && _value === 'select') {
        let dataIndex = _parent.schemaIndex || _parent.dataIndex;
        if (_depth > _state.depth && _state.namespace) {
          dataIndex = `${_state.namespace}[0].${dataIndex}`;
        }

        if (!Array.isArray(_parent.option)) {
          const findSchema = getDataValue(schema, dataIndex);
          const comment: string | undefined = findSchema?.comment;
          if (comment && comment.indexOf('공통코드') > -1) {
            const code = (comment.split('-')[1] || '').trim().split(' ')[1];
            if (code) {
              const codeData = await keyStore.fetchCodeData(pageUuid, code);

              _parent.option = codeData.map((_data: any) => ({
                text: _data.name,
                value: _data.code,
              }));
            }
          } else if (comment) {
            const splitComment = (comment.split('-')[1] || '').split('/');
            const options: any[] = [];
            splitComment.forEach(cmt => {
              const arrCmt = cmt.replace(')', '').split('(');
              if (arrCmt.length === 2) {
                options.push({ text: arrCmt[0].trim(), value: arrCmt[1].trim() });
              }
            });
            // set to config
            _parent.option = options;
          }
        }

        if (!Array.isArray(_parent.option)) {
          if (_parent.url && _parent.keys) {
            const _url = _parent.url;
            _parent.url = undefined;

            const codeData = await keyStore.fetchCodeData(pageUuid, _url, true);

            _parent.option = codeData.map((_data: any) => ({
              text: _data[_parent.keys.text],
              value: _data[_parent.keys.value],
            }));
          } else if (_parent.code) {
            const _code = _parent.code;
            _parent.code = undefined;

            const codeData = await keyStore.fetchCodeData(pageUuid, _code);

            _parent.option = codeData.map((_data: any) => ({
              text: _data.name,
              value: _data.code,
            }));
          }
        }
      }
    };

    await findSelectType(config);
    console.log('getSelectSchema', config);
  } catch (error) {
    console.log('error on getSelectSchema', error);
  }
}

export async function prefetchDate(value: Record<string, any>) {
  console.log('prefetchDate', value);
  if (typeof value !== 'object') {
    return {};
  }

  const v = { ...value };

  if (v.equipmentId) {
    const { data } = await api.get(`/v1/equipment/${v.equipmentId}`);
    v.equipment = data;
  }

  return v;
}
