import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep, forEach, set } from 'lodash';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';
import { ILocale, IModalProps, ITableColumn, ITableConfig, TModalForCaller } from 'stores/ConfigStore';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Button, Col, Row, Table as AntTable, Tag } from 'antd';
import { formatDateString, getDataValue, i18nFromJSON } from 'components';
import { MODAL_TYPE_AUTO } from 'components/constants';
import { Footer, Header } from 'components/Layout';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { default as ModalSelector } from 'components/Common/ModalSelector';
import { computed, toJS } from 'mobx';
import { getPathFromLink } from 'common/router';

type TData = {
  rows: any[];
  page: number;
  total: number;
  pageSize?: number;
};

interface ITableProps extends RouteComponentProps, IInjectedType, IInjectedStore {
  config: ITableConfig;
  data: TData;
  dataIndex?: string;
  pageUuid: string;
}
type ExposedProps = Omit<ITableProps, keyof (RouteComponentProps & IInjectedType & IInjectedStore)> &
  Partial<RouteComponentProps & IInjectedType & IInjectedStore>;

type ITableState = {
  modalVisible: boolean;
  selectedRows: any[];
  editRow: any;
};

const Container = styled.div`
  /* add css here */
`;

const StyledTable = styled(AntTable)`
  .ant-table {
    max-width: 100%;
    overflow: auto;
  }

  .ant-table-cell {
    cursor: pointer;
  }

  .ant-table-pagination-right {
    justify-content: center;
  }
`;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Table extends React.Component<ITableProps, ITableState> {
  constructor(props: ITableProps) {
    super(props);

    this.state = {
      modalVisible: false,
      selectedRows: [] as any[],
      editRow: {},
    };
  }

  @computed
  get tableData() {
    let data: any = {};
    if (this.props.dataIndex) {
      const value = toJS(getDataValue(this.props.data, this.props.dataIndex) || []);
      // console.log('table render value', value);
      formatDateString(value);
      data = {
        rows: value,
        page: 1,
        pageSize: 100,
        total: value.length,
      };
    } else if (this.props.data) {
      data = { ...toJS(this.props.data) };
      formatDateString(data.rows);
    }

    if (data.rows && data.rows.length > 0) {
      data.rows = data.rows.filter((row: any) => !row.delete);
      data.total = data.total || data.rows.length;
    }

    forEach(data.rows, row => {
      if (!row._id) {
        row._id = row.id || `${new Date().getTime()}${Math.random()}`;
      }
    });

    return data;
  }

  componentDidMount() {
    this.checkData();
  }

  componentDidUpdate() {
    this.checkData();
  }

  checkData() {
    if (this.props.data && this.props.dataIndex) {
      if (!getDataValue(this.props.data, this.props.dataIndex)) {
        set(this.props.data, this.props.dataIndex, []);
      }
    }
  }

  onPageChange = (page: number, pageSize: number) => {
    this.props.keyStore.mergeItem(this.props.pageUuid, { page: { page, pageSize } });
  };

  getDataRows() {
    let rows: any[] = [];
    if (this.props.dataIndex) {
      const value = getDataValue(this.props.data, this.props.dataIndex);
      rows = value || [];
    } else if (this.props.data) {
      rows = this.props.data.rows;
    }

    if (!Array.isArray(rows)) {
      rows = [rows];
    }

    return rows;
  }

  getDataRow(id: string) {
    return this.getDataRows().find(row => row._id === id || row.id === id);
  }

  render() {
    // set data
    const { dataIndex, form, handleSubmit, pageUuid, mode } = this.props;
    console.log('table render / dataIndex', dataIndex);
    console.log('table render / pageUuid', pageUuid);
    // console.log('table render / this.props.data', this.props.data);

    const data = this.tableData;
    console.log('table render / data', data);
    //
    const { column, header, header2nd, footer, attr = {}, style, editModal, link } = this.props.config;
    const styles = {
      container: (style && style.container) || {},
      table: (style && style.table) || {},
    };
    let haveCheckbox = false;
    let isRadio = false;
    const touchedColumn =
      column &&
      column.reduce((_touchedColumn: ITableColumn[], column) => {
        const _column = toJS(column);
        const {
          cType,
          title,
          dataIndex: _columnIndex,
          radio,
          render,
          tagRender,
          option,
          config: columnConfig,
          link,
        } = _column;

        // NOTE: JSON에 i18n column을 알려주면 좋을듯. 아니면 attr를 고정으로 정하고 상수로 만드는것도.
        const i18nTitle = title ? i18nFromJSON(title) : title;
        let columnIndex;
        if (_columnIndex) {
          columnIndex = _columnIndex;
        } else {
          if (typeof title === 'string') {
            columnIndex = title.toLowerCase();
          } else {
            const { en } = title as ILocale;
            columnIndex = en.toLowerCase();
          }
        }

        if (option) {
          if (Array.isArray(option)) {
            _column.render = val => {
              let rtVal = val;
              option.forEach(({ text, value }) => {
                if (val === value) {
                  rtVal = text;
                }
              });
              return rtVal;
            };
          } else if (typeof render === 'function') {
            // to nothing
          } else if (typeof render === 'string') {
            try {
              _column.render = eval(render);
            } catch (error) {}
          } else {
            delete _column.render;
          }
        }

        if (tagRender) {
          _column.render = val => {
            const condition = tagRender.find(({ type, value }) => {
              if (type === 'like') {
                if (val.indexOf(value) > -1) return true;
              } else {
                if (val === value) return true;
              }
              return false;
            });

            return (
              <Tag color={condition?.color || 'grey'} key={val}>
                {val}
              </Tag>
            );
          };
        }

        if (cType === 'checkbox' && columnIndex !== 'checked') {
          _column.render = val => (val ? 'Y' : 'N');
        }

        if (cType === 'upload') {
          _column.render = val => {
            if (columnConfig && columnConfig.division) {
              return val.find((v: any) => v.division === columnConfig.division) ? 'Y' : 'N';
            }
            return val && val.length ? 'Y' : 'N';
          };
        }

        if (cType === 'number') {
          _column.render = val => `${val || ''}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }

        if (cType === 'link' && link) {
          _column.render = val => {
            const path = getPathFromLink({ ...link, id: val }, this.props.match);
            return (
              <Button size="small" onClick={() => path && this.props.history.push(path)}>
                {i18nTitle}
              </Button>
            );
          };
        }

        if (cType === 'checkbox' && columnIndex === 'checked') {
          haveCheckbox = true;
          isRadio = !!radio;
        } else {
          _touchedColumn.push({
            ..._column,
            title: i18nTitle,
            key: `${columnIndex}_${i18nTitle}`,
            dataIndex: columnIndex,
          });
        }

        return _touchedColumn;
      }, []);

    let rowSelection: TableRowSelection<any> | undefined;
    if (haveCheckbox) {
      rowSelection = {
        type: isRadio ? 'radio' : 'checkbox',
        // NOTE: 이 녀석을 안하면, 선택한 줄을 삭제했는데도, 라디오 버튼은 n번째 자리를 지키고 있음;;
        // 그리고 버그인듯한데, getCheckboxProps가 있을때 setState를 안하면, 화면 갱신이 안됨.
        // getCheckboxProps: (row) => ({ checked: row.checked }),
        // NOTE: checkbox일때
        onSelect: (row: any, selected: boolean, selectedRows: any[]) => {
          selectedRows = selectedRows.filter(v => !!v).map(row => this.getDataRow(row._id || row.id));
          if (!isRadio) {
            row = this.getDataRow(row._id || row.id);
            row.checked = selected;
            this.setState({ selectedRows });
          }
        },
        // NOTE: radio일때
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
          selectedRows = selectedRows.filter(v => !!v).map(row => this.getDataRow(row._id || row.id));
          if (isRadio) {
            // NOTE: selectedRows가 MobX에서 온 놈이라, 그냥 Checked만 주고, 액션은 Modal이나 Button에서 처리
            const oldSelectedRow: any = this.state.selectedRows[0] || {};
            oldSelectedRow.checked = false;
            selectedRows[0].checked = true;
            this.setState({ selectedRows });
          }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          selectedRows = selectedRows.filter(v => !!v).map(row => this.getDataRow(row._id || row.id));
          changeRows = changeRows.map(row => this.getDataRow(row._id || row.id));
          forEach(changeRows, (row: any) => {
            row.checked = selected;
          });
          this.setState({ selectedRows });
        },
      };
    }

    // Modal
    // TODO: components/Common/Button, Input 에 유사한 코드 있음.
    let ModalComponent: any = null;
    if (editModal) {
      let modalProps = { target: dataIndex } as IModalProps;
      if (typeof editModal === 'string') {
        modalProps.mType = editModal;
      } else {
        modalProps = { ...editModal, ...modalProps };
      }

      if (modalProps.mType === MODAL_TYPE_AUTO && touchedColumn && touchedColumn.length) {
        const column = cloneDeep(touchedColumn);
        column.forEach(col => {
          if (col.modal) {
            if (typeof col.modal === 'string') {
              col.modal = {
                mType: col.modal,
                target: dataIndex,
              } as TModalForCaller;
            } else if (col.modal) {
              col.modal.target = dataIndex;
            }
          }
        });
        modalProps.form = { column };
      }

      // console.log({ dataIndex, mType, defaultValue, dataFromModal });
      attr.onRow = (editRow: any) => ({
        onClick: () => {
          editRow = this.getDataRow(editRow._id || editRow.id);
          editRow.editing = true;

          this.setState({
            modalVisible: true,
            editRow: editRow,
          });
        },
      });
      const close = (): void => {
        const rows = this.getDataRows().filter((row: any) => row.editing);
        rows.forEach(row => (row.editing = false));

        this.setState({
          modalVisible: false,
          editRow: null,
        });
      };
      const onOk = (): void => close();
      const onCancel = (): void => close();
      ModalComponent = (
        <ModalSelector
          pageUuid={pageUuid}
          {...modalProps}
          visible={this.state.modalVisible}
          onOk={onOk}
          onCancel={onCancel}
        />
      );
    } else if (link) {
      attr.onRow = (data: any) => ({
        onClick: () => {
          const path = getPathFromLink(link, this.props.match, data);
          path && this.props.history.push(path);
        },
      });
    }

    if (!data || !data.rows) {
      return null;
    }

    console.log('render touchedColumn', touchedColumn);
    console.log('render selectedRows', this.state.selectedRows);
    console.log('render rows', data && data.rows);
    console.log('render page', { total: data.total, pageSize: data.pageSize, current: data.page });

    return (
      <Container style={toJS(styles.container)}>
        <Header
          config={header}
          data={this.props.data}
          pageUuid={pageUuid}
          form={form}
          mode={mode}
          handleSubmit={handleSubmit}
        />
        <Header
          config={header2nd}
          data={this.props.data}
          pageUuid={pageUuid}
          form={form}
          mode={mode}
          handleSubmit={handleSubmit}
        />
        {data.recentDate ? (
          <Row justify="space-between">
            <Col></Col>
            <Col style={{ fontSize: '12px' }}>{`Up to date: ${data.recentDate}`}</Col>
          </Row>
        ) : null}
        <StyledTable
          rowKey="_id"
          rowSelection={rowSelection}
          dataSource={data.rows}
          columns={(touchedColumn || [])
            .filter(col => !col.hidden)
            .map(column => {
              let dataIndex = column.displayIndex || column.dataIndex;
              if (typeof dataIndex === 'string') {
                dataIndex = dataIndex.split('.');
              }
              return {
                ...column,
                dataIndex,
              };
            })}
          style={toJS(styles.table)}
          pagination={{
            style: { float: 'none', textAlign: 'center' },
            total: data.total,
            pageSize: data.pageSize,
            current: data.page,
            onChange: this.onPageChange,
            size: 'small',
            showTotal: () => `Total ${data.total}`,
          }}
          {...attr}
        />
        {ModalComponent}
        <Footer
          config={footer}
          data={this.props.data}
          pageUuid={pageUuid}
          form={form}
          mode={mode}
          handleSubmit={handleSubmit}
          column={touchedColumn}
        />
      </Container>
    );
  }
}

export default withRouter(Table) as React.ComponentType<ExposedProps>;
