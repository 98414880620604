import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { api } from 'providers';
import { IDictionary } from 'stores/KeysStore';
import styled from 'styled-components';
import { DeleteOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Space } from 'antd';
import { FormInstance } from 'antd/es/form';
import { IContainer } from 'stores/ConfigStore';
import { i18nFromJSON, MultiSelector } from 'components';
import { IInjectedStore, Omit } from 'common/@types';
import { RouteComponentProps, withRouter } from 'react-router';
import { computed, IReactionDisposer, observable, toJS } from 'mobx';
import { getSelectSchema, prefetchDate } from 'common/pageHelper';
import { convertValueToJson } from 'common/componentHelper';
import { isEmpty, isEqual, merge } from 'lodash';
import confirmDialog from 'common/confirmDialog';
import Spinner from 'components/Common/Spinner';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import { getListPath, parseQueryString, stringifyQueryString } from 'common/router';
import { UnregisterCallback } from 'history';

interface IPageProps extends RouteComponentProps, IInjectedStore {
  title: string;
  config: IContainer[];
  url?: string;
  path?: string;
}
type ExposedProps = Omit<IPageProps, keyof (RouteComponentProps & IInjectedStore)> &
  Partial<RouteComponentProps & IInjectedStore>;

const Title = styled.h1`
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 1;
  flex: 1 1 auto;
  margin: 0px;
  overflow: hidden;
  font-size: 24px;
`;

const ButtonRow = styled(Row)`
  position: fixed;
  width: 100%;
  left: 0;
  padding: 10px 10px 5px 10px;
  z-index: 1;
  background-color: white;
  height: 47px;
`;

const exceptUrls = {
  '/v1/equipment/:id': '1',
  '/v1/payment/:id': '16',
  '/v1/paymentRevision/:id': '5',
  '/v1/checklist/:id': '6',
  '/v1/student/:id': '3',
  '/v1/graduate/:id': '30',
  '/v1/public/estimateRevision/:id': 'ZXx4anPFj',
  '/v1/public/deliveryPack/:id': 'UIyLVw3Z-',
  '/v1/public/paymentRevision/:id': 'fowsMN5Lq',
};

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class PageFromMenu extends React.Component<IPageProps> {
  formRef = React.createRef<FormInstance>();

  searchReaction: IReactionDisposer | undefined;
  pageReaction: IReactionDisposer | undefined;
  optionReaction: IReactionDisposer | undefined;
  historyBlocker: UnregisterCallback | undefined;
  isFieldChanged = false;

  @observable config: IContainer[] = [];
  @observable pageUuid: string = '';
  @observable isLoaded: boolean = false;

  constructor(props: IPageProps) {
    super(props);

    this.pageUuid = this.getPageUuid();
    console.log('pageUuid', this.pageUuid);
    this.config = [...this.props.config];
  }

  @computed
  get config0() {
    return this.config[0].config || {};
  }

  getPageUuid() {
    const title = this.props.title;
    const match = this.props.match;
    const params: any = match ? match.params : {};
    const id: string = params.id || 'new';
    return `${title}/${id}`;
  }

  componentDidMount() {
    this.getData();

    this.searchReaction = this.props.keyStore.addReaction(this.pageUuid, 'search', (_search: IDictionary) => {
      const search = toJS(_search || {}) || {};
      console.log('search reaction / search', search);

      // sync query
      const query = parseQueryString<IDictionary>(this.props.history.location.search);
      console.log('search reaction / query', query);

      if (!isEqual(search, query.search || {})) {
        const newQuery = { ...query, search };
        if (isEmpty(newQuery.search)) {
          delete (newQuery as any).search;
        }
        console.log('newQuery', newQuery);

        console.log('search reaction / push');
        this.props.history.push(`${this.props.history.location.pathname}?${stringifyQueryString(newQuery)}`);
      }
    });

    this.pageReaction = this.props.keyStore.addReaction(this.pageUuid, 'page', (_page: IDictionary) => {
      const page = toJS(_page || {}) || {};
      console.log('page reaction / page', page);

      // sync query
      const query = parseQueryString<IDictionary>(this.props.history.location.search);
      console.log('page reaction / query', query);

      if (!isEqual(page, query.page || {})) {
        const newQuery = { ...query, page };
        if (isEmpty(newQuery.page)) {
          delete (newQuery as any).page;
        }
        console.log('newQuery', newQuery);

        if (!isEmpty(newQuery)) {
          console.log('page reaction / push');
          this.props.history.push(`${this.props.history.location.pathname}?${stringifyQueryString(newQuery)}`);
        }
      }
    });

    this.optionReaction = this.props.keyStore.addReaction(this.pageUuid, 'option', (_option: IDictionary) => {
      const option = toJS(_option || {}) || {};
      console.log('option reaction / option', option);

      // sync query
      const query = parseQueryString<IDictionary>(this.props.history.location.search);
      console.log('option reaction / query', query);

      if (!isEqual(option, query.option || {})) {
        const newQuery = { ...query, option };
        if (isEmpty(newQuery.option)) {
          delete (newQuery as any).option;
        }
        console.log('newQuery', newQuery);

        if (!isEmpty(newQuery)) {
          console.log('option reaction / replace');
          this.props.history.push(`${this.props.history.location.pathname}?${stringifyQueryString(newQuery)}`);
        }
      }
    });

    this.historyBlocker = this.props.history.block((_, action) => {
      if (action === 'POP' && this.isFieldChanged && this.config[0].cType === 'Form') {
        const { t } = this.props.i18nStore;
        if (!window.confirm(t.lostChange)) {
          return false;
        }
      }
      return;
    });
  }

  componentDidUpdate(prevProps: IPageProps) {
    const _pageUuid = this.getPageUuid();
    if (this.pageUuid !== _pageUuid) {
      this.pageUuid = _pageUuid;
      console.log('pageUuid', this.pageUuid);
    }

    const preQuery = parseQueryString<IDictionary>(prevProps.location.search);
    delete preQuery['option'];
    const newQuery = parseQueryString<IDictionary>(this.props.location.search);
    delete newQuery['option'];

    if (
      prevProps.url !== this.props.url ||
      !isEqual(
        { pathname: prevProps.location.pathname, search: preQuery },
        { pathname: this.props.location.pathname, search: newQuery },
      )
    ) {
      this.config = [...this.props.config];

      this.getData();
    }
  }

  componentWillUnmount() {
    console.log('PageFromMenu.componentWillUnmount');
    this.searchReaction && this.searchReaction(); // dispose reaction
    this.pageReaction && this.pageReaction();
    this.optionReaction && this.optionReaction();
    this.clearStore();
    this.historyBlocker && this.historyBlocker();
  }

  async getData() {
    try {
      console.log('getData', this.props);

      // sync query
      const {
        search = {},
        page = {},
        option = {},
      } = parseQueryString<IDictionary>(this.props.history.location.search) as {
        search: IDictionary;
        page: IDictionary;
        option: IDictionary;
      };
      const state = this.props.history.location.state || {};

      if (this.config0.searchKey && this.config0.searchKey.filter(k => !search[k]).length) {
        console.log('getData skip: searchKey missing', this.config0.searchKey, search);
        return;
      }

      console.log('getData search', search);
      console.log('getData state', state);
      console.log('getData page', page);

      if (!this.props.url) {
        this.props.keyStore.mergeItem(this.pageUuid, { search, page, option, data: {} });
        return;
      }

      this.props.appStore.setSpinning(true, undefined, this.pageUuid);

      try {
        const [path, params] = this.props.url.split('?');
        // const url = path.replace(/:(\w+)/g, (_match, _paramName) => this.props.match.params[_paramName]) + '?' + params;

        const match: any = this.props.match || { params: {} };
        const id = match.params.id || exceptUrls[this.props.url];
        let url = `${path.replace(/:(\w+)$/g, id)}?`;

        if (Object.keys(search).length) {
          url = `${url}&where=${encodeURIComponent(JSON.stringify(search))}`;
        }
        if (params) {
          url = `${url}&${params}`;
        }
        if (page.page) {
          url = `${url}&page=${page.page}`;
        }
        if (page.pageSize) {
          url = `${url}&pageSize=${page.pageSize}`;
        }

        let response: any = {};
        if (id !== 'new') {
          console.log('getData url', url);
          response = await api.get(url);
          console.log('getData response', response);
        }
        await getSelectSchema(this.props.keyStore, this.pageUuid, this.config, path);

        this.props.keyStore.mergeItem(this.pageUuid, {
          search,
          page,
          option,
          id: match.params.id,
          data: response.data || (await prefetchDate({ ...search, ...state })),
        });
      } catch (error) {
        console.log('error on getData', error);
        Modal.error({ title: 'Error on data loading', content: JSON.stringify(error) });
      }
    } finally {
      this.props.appStore.setSpinning(false, undefined, this.pageUuid);
      this.isLoaded = true;
    }
  }

  clearStore() {
    this.props.keyStore.clearItem(this.pageUuid);
  }

  onClickBack = async () => {
    if (this.props.history.action !== 'POP') {
      return this.props.history.goBack();
    }

    const { link } = this.config0;

    if (link) {
      return this.props.history.push(getListPath(link));
    }

    if (this.props.path) {
      return this.props.history.push(this.props.path.replace(/\/:(\w+)$/g, ''));
    }
  };

  onClickDelete = async () => {
    if (!this.props.url) {
      return;
    }

    const { t } = this.props.i18nStore;
    const ok = await confirmDialog({ content: t.confirm.delete });

    if (!ok) {
      return;
    }

    const [path, _] = this.props.url.split('?');
    const url = path.replace(/:(\w+)$/g, '');

    const { data = {} } = this.props.keyStore.getItem(this.pageUuid);
    if (data.id ?? data._id) {
      this.props.appStore.setSpinning(true, undefined, this.pageUuid);
      try {
        await api.delete(`${url}${data.id ?? data._id}`);
      } catch (error) {
        console.log('error on Delete', error);
        this.props.appStore.setSpinning(false, undefined, this.pageUuid);
        Modal.error({ title: 'Error on delete', content: JSON.stringify(error) });
        return;
      }
      this.props.appStore.setSpinning(false, undefined, this.pageUuid);
    }

    const { link } = this.config0;

    if (link) {
      return this.props.history.push(getListPath(link));
    }

    if (this.props.path) {
      return this.props.history.push(this.props.path.replace(/\/:(\w+)$/g, ''));
    }
  };

  handleSubmit = async (e?: any, converter?: (value: any) => Promise<any>): Promise<void> => {
    let values: any;

    try {
      values = await this.formRef.current?.validateFields();
    } catch (error) {
      const errorInfo = error as ValidateErrorEntity;
      values = { ...errorInfo.values };

      // skip initial check
      if (errorInfo.errorFields.find(({ name }) => name.find(name => name !== 'initial'))) {
        throw errorInfo.errorFields;
      }
    }

    const { t } = this.props.i18nStore;
    const ok = await confirmDialog({ content: t.confirm.save });

    if (!ok) {
      return;
    }

    console.log('pageUuid', this.pageUuid);
    console.log('Received values of form: ', values);

    const { data = {} } = this.props.keyStore.getItem(this.pageUuid);
    console.log('originalData', data);

    const mergedData = merge(toJS(data), values);
    console.log('mergedData', mergedData);

    // Should format date value before submit.
    const convertedData = convertValueToJson(mergedData);
    console.log('convertedData', convertedData);

    if (this.props.url) {
      try {
        this.props.appStore.setSpinning(true, undefined, this.pageUuid);

        const [path, _params] = this.props.url.split('?');
        const url = path.replace(/:(\w+)$/g, '');

        const method = convertedData.id || convertedData._id ? 'put' : 'post';

        let { data } = await api[method](url, convertedData);

        if (converter) {
          const _data = await converter(data);

          if (_data) {
            data = _data;
          }
        }

        this.props.keyStore.mergeItem(this.pageUuid, { data }, true);

        this.isFieldChanged = false;

        if (this.props.path && (data.id ?? data._id)) {
          this.props.history.replace(this.props.path.replace(/\/:(\w+)$/g, `\/${data.id ?? data._id}`));
        }
      } catch (error) {
        console.log('error on Submit', error);
        Modal.error({ title: 'Error on submit', content: JSON.stringify(error) });
      } finally {
        this.props.appStore.setSpinning(false, undefined, this.pageUuid);
      }
    } else {
      this.props.keyStore.mergeItem(this.pageUuid, { data: convertedData });
    }
  };

  render() {
    console.log('pageUuId', this.pageUuid);
    const { t } = this.props.i18nStore;
    const { me } = this.props.appStore.userStore;

    // TODO: get Title
    const title = i18nFromJSON(this.props.title);
    // const _titles = (this.props.title || '').split('.');
    // const title = _titles[_titles.length - 1];

    let buttons;
    if (this.config[0].cType === 'Form') {
      buttons = (
        <div style={{ height: '42px' }}>
          <ButtonRow justify="space-between" style={{ top: window.ReactNativeWebView ? '0' : '39px' }}>
            <Col>
              <Button icon={<LeftOutlined />} onClick={this.onClickBack}>
                {this.props.history.action !== 'POP' ? t.button.back : t.button.list}
              </Button>
            </Col>
            {me && me.isReadOnly ? null : (
              <Col>
                <Space>
                  <Button danger icon={<DeleteOutlined />} onClick={this.onClickDelete}>
                    {t.button.delete}
                  </Button>
                  <Button type="primary" icon={<SaveOutlined />} onClick={this.handleSubmit}>
                    {t.button.save}
                  </Button>
                </Space>
              </Col>
            )}
          </ButtonRow>
        </div>
      );
    }

    const { data, hidden, key } = this.props.keyStore.getItem(this.pageUuid);
    console.log('PageFromMenu, data:', data);

    return (
      <Form ref={this.formRef} onFieldsChange={() => (this.isFieldChanged = true)}>
        <Spinner pageUuid={this.pageUuid}>
          {buttons}
          {window.ReactNativeWebView ? null : <Title>{title}</Title>}
          {hidden || !this.isLoaded ? null : (
            <div key={key}>
              {this.config.map((config, index) => (
                <MultiSelector
                  config={toJS(config)}
                  data={data}
                  key={index}
                  pageUuid={this.pageUuid}
                  form={this.formRef}
                  handleSubmit={this.handleSubmit}
                />
              ))}
            </div>
          )}
        </Spinner>
      </Form>
    );
  }
}

export default withRouter(PageFromMenu) as any as React.ComponentType<ExposedProps>;
