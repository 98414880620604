import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IInput } from 'stores/ConfigStore';
import { IUser } from 'stores/UserStore';
import { Input as AntInput, InputRef } from 'antd';
import { AntFormItem, getDataValue } from 'components';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { set } from 'lodash';
import { toJS } from 'mobx';

const AntInputGroup = AntInput.Group;

interface IPhoneProps extends IInjectedType, IInjectedStore, IFormItemProps {
  config: IInput;
  data: IUser;
  pageUuid: string;
}
type ExposedProps = Omit<IPhoneProps, keyof (IInjectedType & IInjectedStore)> & Partial<IInjectedType & IInjectedStore>;

const divider = (
  <AntInput
    style={{
      width: '5%',
      borderLeft: 0,
      paddingLeft: 0,
      paddingRight: 0,
      pointerEvents: 'none',
      backgroundColor: '#fff',
    }}
    placeholder="-"
    disabled
  />
);

interface IPhoneInputProps {
  value?: string;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
}

interface IPhoneInputState {
  initialValue: string;
  value: string[];
}

class PhoneInput extends React.Component<IPhoneInputProps, IPhoneInputState> {
  state = { initialValue: '', value: ['', '', ''] };

  input0: InputRef | null | undefined;
  input1: InputRef | null | undefined;
  input2: InputRef | null | undefined;

  static getDerivedStateFromProps(props: IPhoneInputProps, state: IPhoneInputState) {
    if ('value' in props) {
      let initialValue = props.value + '';

      if (state.initialValue === initialValue) {
        return null;
      }

      if (initialValue.indexOf('-') === -1) {
        initialValue = initialValue.replace(/(\d{1,3})(\d{0,4})(\d{0,4})/, '$1-$2-$3');
      }

      return {
        initialValue,
        value: initialValue.split('-'),
      };
    }
    return null;
  }

  handleChange = (e: any, idx: number) => {
    const _value: string = e.target.value;
    console.log('handleChange', idx, _value);
    if (isNaN(parseInt(e.target.value || 0, 10))) {
      const nextIdx = idx + 1;
      if (`input${nextIdx}` in this) {
        this[`input${nextIdx}`].focus();
      }
      return;
    }

    const newState: any = { ...this.state };
    newState.value[idx] = _value;

    if (!('value' in this.props)) {
      this.setState(newState);
    }

    this.triggerChange(newState.value);

    if (_value.length >= e.target.maxLength) {
      const nextIdx = idx + 1;
      if (`input${nextIdx}` in this) {
        this[`input${nextIdx}`].focus();
      }
    }
    if (_value.length === 0) {
      const prevIdx = idx - 1;
      if (`input${prevIdx}` in this) {
        this[`input${prevIdx}`].focus();
      }
    }
  };

  triggerChange = (value: string[]) => {
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(value.join('-'));
    }
  };

  render() {
    return (
      <AntInputGroup style={toJS(this.props.style)} compact>
        <AntInput
          ref={e => (this.input0 = e)}
          style={{ width: '30%' }}
          value={this.state.value[0]}
          onChange={(e: any) => this.handleChange(e, 0)}
          maxLength={3}
        />
        {divider}
        <AntInput
          ref={e => (this.input1 = e)}
          style={{ width: '30%', borderLeft: 0 }}
          value={this.state.value[1]}
          onChange={(e: any) => this.handleChange(e, 1)}
          maxLength={4}
        />
        {divider}
        <AntInput
          ref={e => (this.input2 = e)}
          style={{ width: '30%', borderLeft: 0 }}
          value={this.state.value[2]}
          onChange={(e: any) => this.handleChange(e, 2)}
          maxLength={4}
        />
      </AntInputGroup>
    );
  }
}

@inject('i18nStore')
@observer
class Phone extends React.Component<IPhoneProps> {
  onChange = (value: string) => {
    console.log('onChange Phone', value);
    const { dataIndex } = this.props.config;
    if (dataIndex) {
      set(this.props.data, dataIndex, value);
      this.props.form.current?.setFieldValue(dataIndex, value);
    }
  };

  render() {
    const {
      config,
      data,
      form,
      i18nStore: { t },
    } = this.props;
    const { cType, dataIndex, isRequire, style } = config;

    if (cType !== 'phone') {
      return null;
    }

    const initialValue = getDataValue(data, dataIndex) || '';

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          initialValue={initialValue}
          rules={[{ required: isRequire, message: t.validator.required }]}
          form={form}
        >
          <PhoneInput onChange={this.onChange} style={style} />
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default Phone as React.ComponentType<ExposedProps>;
