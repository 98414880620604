import * as React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { IMixedConfig } from 'stores/ConfigStore';
import { Col, Row } from 'antd';
import { MultiSelector, splitContainerProps } from 'components';
import { Footer, Header } from 'components/Layout';
import { IInjectedType, Omit } from 'common/@types';

interface IMixedProps extends IInjectedType {
  config: IMixedConfig;
  data?: any;
  pageUuid: string;
}
type ExposedProps = Omit<IMixedProps, keyof IInjectedType> & Partial<IInjectedType>;

const Container = styled.div`
  /* add css here */
`;

@observer
class Mixed extends React.Component<IMixedProps> {
  render() {
    const { config, data, form, handleSubmit, pageUuid, mode } = this.props;
    const { column, style = {}, attr = {}, header, header2nd, footer, isRow } = config;
    const { styles, attrs } = splitContainerProps({ style, attr });

    return (
      <Container>
        <Header config={header} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        <Header config={header2nd} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        {isRow ? (
          column &&
          column.map((_config, key) => (
            <Row style={toJS(styles.row)} {...attrs.row} key={`${_config.cType}-${key}`}>
              <Col style={toJS(styles.column)} {...attrs.column}>
                <MultiSelector
                  config={_config}
                  pageUuid={pageUuid}
                  data={data}
                  form={form}
                  mode={mode}
                  handleSubmit={handleSubmit}
                />
              </Col>
            </Row>
          ))
        ) : (
          <Row style={toJS(styles.row)} {...attrs.row}>
            {column &&
              column.map((_config, key) => (
                <Col style={toJS(styles.column)} {...attrs.column} key={`${_config.cType}-${key}`}>
                  <MultiSelector
                    config={_config}
                    pageUuid={pageUuid}
                    data={data}
                    form={form}
                    mode={mode}
                    handleSubmit={handleSubmit}
                  />
                </Col>
              ))}
          </Row>
        )}
        <Footer config={footer} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
      </Container>
    );
  }
}

export default Mixed as React.ComponentType<ExposedProps>;
