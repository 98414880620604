import * as React from 'react';
import { observer } from 'mobx-react';
import { IContainer, IFormColumn, IMixedComp } from 'stores/ConfigStore';
import { Button, ButtonGroup, Hr } from 'components';
import {
  Checkbox,
  Checkboxes,
  DatePicker,
  DateRangePicker,
  Email,
  Form,
  FormGroup,
  FormItem,
  Input,
  InputGroup,
  Memo,
  Message,
  Number as NumberForm,
  Password,
  Phone,
  Search,
  Select,
} from 'components/Form';
import { CheckList, CommentList, List, UserList } from 'components/List';
import { DataWithDesc, Desc, Heading, TitleWithDesc } from 'components/Typo';
import { BarChart, ColumnChart, LineChart, PieChart, TableChart } from 'components/Chart';
import { Image } from 'components/Image';
import { Table } from 'components/Table';
import { Mixed } from 'components/Mixed';
import { Agenda, Link, MultiView, Tab, Timeline } from 'components/Layout';
import { Attachments, Upload } from 'components/Attachments';
import { KanbanList } from 'components/Kanban';
import { IInjectedType, Omit } from 'common/@types';

const formComponents = {
  formGroup: FormGroup,
  inputGroup: InputGroup,
  select: Select,
  input: Input,
  search: Search,
  number: NumberForm,
  email: Email,
  phone: Phone,
  memo: Memo,
  message: Message,
  password: Password,
  checkbox: Checkbox,
  checkboxes: Checkboxes,
  date: DatePicker,
  dateRange: DateRangePicker,
  upload: Upload,
};

const chartComponents = {
  lineChart: LineChart,
  pieChart: PieChart,
  barChart: BarChart,
  tableChart: TableChart,
  columnChart: ColumnChart,
};

const components = {
  ...formComponents,
  ...chartComponents,
  button: Button,
  buttonGroup: ButtonGroup,
  heading: Heading,
  desc: Desc,
  titleWithDesc: TitleWithDesc,
  dataWithDesc: DataWithDesc,
  image: Image,
  list: List,
  userList: UserList,
  checkList: CheckList,
  commentList: CommentList,
  hr: Hr,
  kanbanList: KanbanList,
  timeline: Timeline,
  agenda: Agenda,
};

export function findComponent(cType: string): any {
  return components[cType];
}
export function isFormComponent(cType: string): boolean {
  return !!formComponents[cType];
}

const containers = {
  Table,
  Form,
  Mixed,
  Tab,
  Attachments,
  Link,
  MultiView,
};

function findContainer(cType: string): any {
  return containers[cType];
}
export function isContainer(cType: string): boolean {
  return !!containers[cType];
}

interface IMultiSelectorProps extends IInjectedType {
  pageUuid: string;
  config: IMixedComp | IContainer | IFormColumn;
  data?: any;
}
type ExposedProps = Omit<IMultiSelectorProps, keyof IInjectedType> & Partial<IInjectedType>;

@observer
class MultiSelector extends React.Component<IMultiSelectorProps> {
  render() {
    const { cType, showOnlyModify } = this.props.config;
    const { data, form, handleSubmit, pageUuid, mode } = this.props;

    if (showOnlyModify && pageUuid.indexOf('/new') > -1) {
      return null;
    }

    if (isContainer(cType)) {
      // containers
      const { config, dataIndex } = this.props.config as IContainer;
      const Container = findContainer(cType);

      if (Container) {
        return (
          <Container
            config={config}
            data={data}
            dataIndex={dataIndex}
            key={pageUuid}
            pageUuid={pageUuid}
            form={form}
            mode={mode}
            handleSubmit={handleSubmit}
          />
        );
      }
    } else {
      // components
      const { config } = this.props;
      const Component = findComponent(cType);

      if (Component) {
        if (isFormComponent(cType)) {
          return (
            <FormItem
              config={config as IFormColumn}
              data={data}
              key={pageUuid}
              pageUuid={pageUuid}
              form={form}
              mode={mode}
              handleSubmit={handleSubmit}
            />
          );
        }
        return (
          <Component
            config={config}
            data={data}
            key={pageUuid}
            pageUuid={pageUuid}
            form={form}
            mode={mode}
            handleSubmit={handleSubmit}
          />
        );
      }
    }

    return null;
  }
}

export default MultiSelector as React.ComponentType<ExposedProps>;
