import axios, { AxiosRequestConfig } from 'axios';

export type IApiMethod = (route: string, config?: AxiosRequestConfig) => Promise<{ data: any; [k: string]: any }>;
export type IApiBodyMethod = (
  route: string,
  body?: any,
  config?: AxiosRequestConfig,
) => Promise<{ data: any; [k: string]: any }>;

interface IApiMethods {
  get: IApiMethod;
  delete: IApiMethod;
  post: IApiBodyMethod;
  put: IApiBodyMethod;
}

axios.defaults.baseURL = '/api';

const _axios = axios.create({
  headers: { Pragma: 'no-cache' },
});

const axiosWrapper = async (method: string, route: string, config?: AxiosRequestConfig) => {
  try {
    const { data, ...rest } = await _axios[method](route, config);
    if (data.error) {
      throw data.error;
    }
    return { data, ...rest };
  } catch (e) {
    throw e;
  }
};

const axiosBodyWrapper = async (method: string, route: string, body?: any, config?: AxiosRequestConfig) => {
  try {
    if (!(body instanceof FormData) && typeof body === 'object') {
      body = JSON.parse(JSON.stringify(body, (k, v) => (v === undefined ? null : v)));
    }

    const { data, ...rest } = await _axios[method](route, body, config);
    if (data.error) {
      throw data.error;
    }
    return { data, ...rest };
  } catch (e: any) {
    throw e.response.data || e.response.body || e.response || e;
  }
};

const api: IApiMethods = {
  get: (route, config) => axiosWrapper('get', route, config),
  delete: (route, config) => axiosWrapper('delete', route, config),
  post: (route, body, config) => axiosBodyWrapper('post', route, body, config),
  put: (route, body, config) => axiosBodyWrapper('put', route, body, config),
};

export default api;
