import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EInputAction, IModalProps, IOption, ISelect, TMenu, TMenuContainer } from 'stores/ConfigStore';
import { Button, Divider, Input as AntInput, InputRef, Select as AntSelect, Space, Spin } from 'antd';
import { AntFormItem, getDataValue, i18nFromJSON } from 'components';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { set } from 'lodash';
import { FormItemProps } from 'antd/es/form';
import { SelectProps } from 'antd/lib/select';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { computed, observable } from 'mobx';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'components/constants';
import styled from 'styled-components';
import { menu } from 'mocks/menu';
import { ICodeDataRow } from '../../stores/KeysStore';
import { Rule } from 'rc-field-form/lib/interface';
import { default as ModalSelector } from 'components/Common/ModalSelector';

const SelectWrap = styled.div`
  .ant-select-clear {
    z-index: 9999;
  }
`;

interface ISelectProps extends IInjectedType, IFormItemProps, IInjectedStore {
  config: ISelect;
  data?: any;
  pageUuid: string;
}
type ExposedProps = Omit<ISelectProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

interface ISelectState {
  addedValue: string;
  open: boolean;
  modalVisible: boolean;
}

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Select extends React.Component<ISelectProps, ISelectState> {
  state = { addedValue: '', open: false, modalVisible: false };

  @observable spinning = false;
  @observable addedData: string[] = [];
  addInput: InputRef | null | undefined;

  @computed
  get remoteData(): ICodeDataRow[] {
    const { url } = this.props.config;
    return url ? this.props.keyStore.getCodeData(this.props.pageUuid, url) : [];
  }

  @computed
  get codeData(): ICodeDataRow[] {
    const { code } = this.props.config;
    return code ? this.props.keyStore.getCodeData(this.props.pageUuid, code) : [];
  }

  @computed
  get menuItems(): DefaultOptionType[] {
    const options: DefaultOptionType[] = [];

    function menuToItem(_menu: TMenu) {
      options.push({
        label: i18nFromJSON(_menu.name),
        value: _menu.name,
      });
    }

    function objectToMenu(_menus: TMenuContainer) {
      Object.keys(_menus).forEach(key => {
        const _menu = _menus[key];

        Array.isArray(_menu)
          ? _menu.filter(({ hidden, isPublic }) => !hidden && !isPublic).map(menuToItem)
          : objectToMenu(_menu);
      });
    }

    objectToMenu(menu || {});

    return options;
  }

  componentDidMount() {
    this.getRemoteData().then(this.checkDefaultSelect);
  }

  componentDidUpdate(prevProps: ISelectProps) {
    if (this.props.config.url !== prevProps.config.url) {
      this.getRemoteData().then(this.checkDefaultSelect);
    }
  }

  checkDefaultSelect = async () => {
    const { config } = this.props;
    const { dataIndex, searchIndex, action, defaultSelect, multi, option, keys } = config;
    const targetIndex = searchIndex || dataIndex;

    if (action === EInputAction.QUERY && targetIndex) {
      const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);

      if (defaultSelect && !search[targetIndex]) {
        if (multi && defaultSelect === 'all') {
          let optionData: IOption[] = option || [];

          if (!option?.length) {
            if (this.remoteData.length > 0 && keys) {
              optionData = this.remoteData.map((_data: any) => ({
                text: _data[keys.text],
                value: _data[keys.value],
              }));
            }

            if (this.codeData.length > 0) {
              optionData = this.codeData.map((_data: any) => ({
                text: _data.name,
                value: _data.code,
              }));
            }
          }

          if (this.addedData.length > 0) {
            this.addedData.forEach(_data => {
              optionData.push({
                text: _data,
                value: _data,
              });
            });
          }

          this.multiSelectAll(
            undefined,
            optionData.map(o => o.value),
          );
        } else {
          this.onChange(multi ? [defaultSelect] : defaultSelect);
        }
      }
    }
  };

  async getRemoteData() {
    const { url, code, option } = this.props.config;

    if (option?.length) {
      return;
    }

    if (url) {
      try {
        this.spinning = true;

        await this.props.keyStore.fetchCodeData(this.props.pageUuid, url, true);
      } finally {
        this.spinning = false;
      }
    } else if (code) {
      try {
        this.spinning = true;

        await this.props.keyStore.fetchCodeData(this.props.pageUuid, code);
      } finally {
        this.spinning = false;
      }
    }
  }

  onChange = (_value: string | string[]): void => {
    console.log(`selected`, _value);
    const { data, config, form } = this.props;
    const { dataIndex, searchIndex, action, multi, keys } = config || {};
    const targetIndex = searchIndex || dataIndex;

    let value: string | string[];
    if (Array.isArray(_value)) {
      value = _value.map(v => (moment.isMoment(v) ? moment(v).format(DATE_FORMAT) : v));
    } else if (moment.isMoment(_value)) {
      value = moment(_value).format(DATE_FORMAT);
    } else {
      value = _value;
    }

    if (action === EInputAction.QUERY) {
      if (targetIndex) {
        const { search = {}, page = {} } = this.props.keyStore.getItem(this.props.pageUuid);
        const newSearch = { ...search, [targetIndex]: value };
        if (!value || (Array.isArray(value) && !value.length)) {
          delete newSearch[targetIndex];
        }
        this.props.keyStore.mergeItem(this.props.pageUuid, { search: newSearch, page: { pageSize: page.pageSize } });
      }

      return;
    }

    if (action === EInputAction.VIEW) {
      const { search = {}, page = {} } = this.props.keyStore.getItem(this.props.pageUuid);
      this.props.keyStore.mergeItem(this.props.pageUuid, {
        search,
        page: { pageSize: page.pageSize },
        option: { viewIndex: parseInt((value as string) || '0') },
      });

      return;
    }

    if (multi && keys?.value && Array.isArray(value)) {
      if (!data || !targetIndex) {
        return;
      }

      const targetData: any[] = getDataValue(data, targetIndex) ?? [];

      value.forEach(v => {
        const existRow = targetData.find(r => r[keys.value] === v);
        if (!existRow) {
          targetData.push({ [keys.value]: v });
        } else {
          delete existRow.delete;
        }
      });

      targetData.forEach(row => {
        if (!(value as string[]).find(v => v === row[keys.value])) {
          row.delete = true;
        }
      });

      set(data, targetIndex, targetData);
      form.current?.setFieldValue(targetIndex, targetData);
    } else {
      if (targetIndex) {
        set(data, targetIndex, value);
        form.current?.setFieldValue(targetIndex, value);
      }
    }
  };

  addItem = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const { addedValue } = this.state;

    if (!this.addedData.find(d => d === addedValue)) {
      this.addedData.push(addedValue);
      this.setState({ addedValue: '', open: true });
    }
  };

  multiSelectAll = (
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    value: (string | number | null | undefined)[],
  ) => {
    e?.preventDefault();

    const { data, config } = this.props;
    const { dataIndex, searchIndex, multi, keys, action } = config || {};
    const targetIndex = searchIndex || dataIndex;

    if (action === EInputAction.QUERY) {
      if (targetIndex) {
        const { search = {}, page = {} } = this.props.keyStore.getItem(this.props.pageUuid);
        const newSearch = { ...search, [targetIndex]: value };
        if (!value || (Array.isArray(value) && !value.length)) {
          delete newSearch[targetIndex];
        }
        this.props.keyStore.mergeItem(this.props.pageUuid, { search: newSearch, page: { pageSize: page.pageSize } });
      }

      this.setState({ open: false });
      return;
    }

    if (multi && keys?.value && Array.isArray(value)) {
      if (!data || !targetIndex) {
        this.setState({ open: false });
        return;
      }

      const targetData: any[] = getDataValue(data, targetIndex) ?? [];

      value.forEach(_value => {
        const existRow = targetData.find(_row => _row[keys.value] === _value);
        if (!existRow) {
          targetData.push({ [keys.value]: _value });
        } else {
          delete existRow.delete;
        }
      });

      targetData.forEach(row => {
        if (!value.find(v => v === row[keys.value])) {
          row.delete = true;
        }
      });

      set(data, targetIndex, targetData);
    }

    this.setState({ open: false });
  };

  onClear = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.preventDefault();

    const { data, config } = this.props;
    const { dataIndex, searchIndex, multi, action } = config || {};
    const targetIndex = searchIndex || dataIndex;

    if (action === EInputAction.QUERY) {
      if (targetIndex) {
        const { search = {}, page = {} } = this.props.keyStore.getItem(this.props.pageUuid);
        const newSearch = { ...search };
        delete newSearch[targetIndex];
        this.props.keyStore.mergeItem(this.props.pageUuid, { search: newSearch, page: { pageSize: page.pageSize } });
      }

      this.setState({ open: false });
      return;
    }

    if (multi) {
      if (!data || !targetIndex) {
        this.setState({ open: false });
        return;
      }

      const targetData: any[] = getDataValue(data, targetIndex) ?? [];
      const newDate: any[] = [];

      targetData.forEach(row => {
        if (typeof row === 'object') {
          row.delete = true;
          newDate.push(row);
        }
      });

      set(data, targetIndex, newDate);
    } else {
      if (targetIndex) {
        set(data, targetIndex, '');
      }
    }

    this.setState({ open: false });
  };

  onDropdownVisibleChange = (open: boolean) => {
    this.setState({ open });
  };

  render() {
    const {
      config,
      data,
      form,
      pageUuid,
      i18nStore: { t },
    } = this.props;
    const {
      cType,
      dataIndex,
      searchIndex,
      placeholder,
      defaultValue,
      option,
      style,
      isRequire,
      keys,
      onHeader,
      action,
      readOnly,
      editOnlyNew,
      multi,
      allowAdd,
      search = false,
      modal,
    } = config;

    if (cType !== 'select') {
      return null;
    }

    let fieldValue: any | any[];
    const targetIndex = searchIndex || dataIndex;
    if (action === EInputAction.QUERY && targetIndex) {
      const { search = {} } = this.props.keyStore.getItem(this.props.pageUuid);
      fieldValue = search[targetIndex];
    } else {
      fieldValue = getDataValue(data, targetIndex);
    }

    if (action === EInputAction.VIEW) {
      const { option } = this.props.keyStore.getItem(this.props.pageUuid);
      const { viewIndex = 0 } = option || {};
      fieldValue = viewIndex;
    }

    let initialValue = fieldValue ?? defaultValue;

    if (multi) {
      if (!Array.isArray(initialValue)) {
        if (initialValue) {
          initialValue = [initialValue];
        } else {
          initialValue = [];
        }
      }

      if (keys?.value) {
        initialValue = initialValue
          .filter((row: any) => (typeof row === 'object' ? !row.delete : true))
          .map((row: any) => (typeof row === 'object' ? row[keys?.value] : row));
      }
    } else {
      if (Array.isArray(initialValue)) {
        initialValue = initialValue[0];
      }
    }

    console.log('select / initialValue', initialValue);

    const formItemProps: FormItemProps = { name: targetIndex, initialValue };
    const selectProps: SelectProps = {
      value: initialValue,
      disabled: readOnly || (editOnlyNew && pageUuid.indexOf('/new') === -1),
    };

    if (multi) {
      selectProps.mode = 'multiple';
      formItemProps.name = `${formItemProps.name}-multi`;
    }

    // TODO: components/Common/Button, Table에 유사한 코드 있음.
    let ModalComponent: any = null;
    if (modal) {
      const modalProps: IModalProps = typeof modal === 'string' ? { mType: modal } : modal;
      if (dataIndex && !modalProps.dataIndexes) {
        modalProps.dataIndexes = { [dataIndex]: dataIndex };
      }

      selectProps.disabled = true;
      selectProps.onClick = () => this.setState({ modalVisible: true });
      const close = (): void => this.setState({ modalVisible: false });
      const onOk = (): void => close();
      const onCancel = (): void => close();
      ModalComponent = (
        <ModalSelector
          pageUuid={pageUuid}
          {...modalProps}
          visible={this.state.modalVisible}
          onOk={onOk}
          onCancel={onCancel}
        />
      );
    }

    let optionData: IOption[] = [...(option || [])];
    console.log('optionData', optionData);

    if (!option?.length) {
      if (this.remoteData.length > 0 && keys) {
        console.log('_options from remoteData', this.remoteData);
        optionData = this.remoteData.map((_data: any) => ({
          text: _data[keys.text],
          value: _data[keys.value],
        }));
      }

      if (this.codeData.length > 0) {
        console.log('_options from codeData', this.codeData);
        optionData = this.codeData.map((_data: any) => ({
          text: _data.name,
          value: _data.code,
        }));
      }
    }

    if (this.addedData.length > 0) {
      console.log('_options from addedData', this.addedData);
      this.addedData.forEach(_data => {
        optionData.push({
          text: _data,
          value: _data,
        });
      });
    }

    if (Array.isArray(initialValue)) {
      initialValue.forEach(v => {
        if (!optionData.find(o => o.value === v)) {
          optionData.push({
            text: v,
            value: v,
          });
        }
      });
    } else {
      if (initialValue && !optionData.find(o => o.value === initialValue)) {
        optionData.push({
          text: initialValue,
          value: initialValue,
        });
      }
    }

    let options: DefaultOptionType[] = [];

    for (const _option of optionData) {
      const { text, value, selected } = _option;
      if (!initialValue && selected === true) {
        initialValue = multi ? [value] : value;
      }
      options.push({ label: i18nFromJSON(text ?? value), value: value ?? text });
    }

    if (action === EInputAction.MENU) {
      options = this.menuItems;
    }

    if (onHeader === true) {
      this.props.formItemProps.style = { ...this.props.formItemProps.style, marginBottom: 0 };
    }

    const rules: Rule[] = isRequire
      ? [{ required: true, message: t.validator.required, type: multi ? 'array' : 'string' }]
      : [];

    console.log('select / options', options);

    return (
      <Spin spinning={this.spinning}>
        <AntFormItem {...this.props.formItemProps} {...formItemProps} rules={rules} form={form}>
          <SelectWrap>
            <AntSelect
              allowClear={!/\b(iPad|iPhone|iPod)\b/.test(navigator.userAgent)}
              showSearch={search}
              {...selectProps}
              filterOption={(input, option) => `${option?.label ?? ''}`.toLowerCase().includes(input.toLowerCase())}
              onChange={(value: any) => this.onChange(value)}
              onClear={this.onClear}
              open={this.state.open}
              onDropdownVisibleChange={this.onDropdownVisibleChange}
              placeholder={i18nFromJSON(placeholder as any)}
              style={{ minWidth: style?.minWidth ?? style?.width ?? 97, ...style }}
              options={options}
              getPopupContainer={trigger => trigger.parentElement}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    {multi ? (
                      <Button
                        type="text"
                        size="small"
                        icon={<CheckOutlined />}
                        onClick={e =>
                          this.multiSelectAll(
                            e,
                            options.map(o => o.value),
                          )
                        }
                      >
                        {t.button.selectAll}
                      </Button>
                    ) : null}
                    <Button type="text" size="small" icon={<CloseOutlined />} onClick={this.onClear}>
                      {t.button.deselect}
                    </Button>
                  </Space>
                  {allowAdd ? (
                    <>
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px' }}>
                        <AntInput
                          placeholder="Please enter item"
                          ref={e => (this.addInput = e)}
                          value={this.state.addedValue}
                          onChange={e => {
                            this.setState({ addedValue: e.target.value });
                          }}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={this.addItem}>
                          {t.button.addItem}
                        </Button>
                      </Space>
                    </>
                  ) : null}
                </>
              )}
            />
          </SelectWrap>
        </AntFormItem>
        {this.props.children}
        {ModalComponent}
      </Spin>
    );
  }
}

export default Select as React.ComponentType<ExposedProps>;
