import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { i18nFromJSON, MultiSelector } from 'components';
import { Footer, Header } from 'components/Layout';
import { ITabConfig } from 'stores/ConfigStore';
import { Tabs as AntTabs } from 'antd';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';

interface ITabsProps extends IInjectedType, IInjectedStore {
  config: ITabConfig;
  data?: any;
  pageUuid: string;
}
type ExposedProps = Omit<ITabsProps, keyof (IInjectedType & IInjectedStore)> & Partial<IInjectedType & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Tab extends React.Component<ITabsProps> {
  onChange = (activeKey: string) => {
    const { option = {} } = this.props.keyStore.getItem(this.props.pageUuid);
    this.props.keyStore.mergeItem(this.props.pageUuid, {
      option: { ...option, tabIndex: activeKey },
    });
  };

  render() {
    const {
      form,
      mode,
      handleSubmit,
      config: { column, header, header2nd, footer },
      pageUuid,
    } = this.props;

    let defaultActiveKey = '0';
    const { option = {} } = this.props.keyStore.getItem(this.props.pageUuid);
    const activeKey = option.tabIndex as string;

    const panes =
      column &&
      column.map((tabColumn, index) => {
        const strIndex = index.toString();
        if (tabColumn.active) {
          defaultActiveKey = strIndex;
        }

        let components = null;
        if (tabColumn.column) {
          components = tabColumn.column.map((contentColumn, contentIndex) => (
            <MultiSelector
              config={contentColumn}
              data={this.props.data}
              pageUuid={pageUuid}
              key={`tab-${contentIndex}`}
              form={form}
              mode={mode}
              handleSubmit={handleSubmit}
            />
          ));
        }

        return { label: i18nFromJSON(tabColumn.title), key: strIndex, children: components };
      });

    return (
      <div>
        <Header config={header} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        <Header config={header2nd} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        <AntTabs activeKey={activeKey || defaultActiveKey} onChange={this.onChange} type="card" items={panes} />
        <Footer config={footer} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
      </div>
    );
  }
}

export default Tab as React.ComponentType<ExposedProps>;
