import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { cloneDeep, forEach } from 'lodash';
import { IList, IButton, IModalProps, EModalMode, TModalForCaller } from 'stores/ConfigStore';
import { List as AntList, Avatar, Button as AntButton } from 'antd';
import { getDataValue, formatDateString } from 'components';
import { MODAL_TYPE_AUTO } from 'components/constants';
import { default as ModalSelector } from 'components/Common/ModalSelector';
import { IInjectedType, Omit } from 'common/@types';
import { ButtonProps } from 'antd/lib/button';
import { computed, toJS } from 'mobx';
import { PlusOutlined } from '@ant-design/icons';

const AntItem = AntList.Item;
const AntItemMeta = AntList.Item.Meta;

interface IItemProps {
  item: any;
  icon?: any;
  pageUuid: string;
  onClick?: (item: any) => any;
}

@observer
class UserItem extends React.Component<IItemProps> {
  render() {
    const { item, icon } = this.props;
    const title = item.path ? <a href={item.path}>{item.name}</a> : item.name;
    return (
      <AntItem
        style={{ float: 'left', borderBottom: 'none', cursor: 'pointer' }}
        onClick={() => this.props.onClick && this.props.onClick(item)}
      >
        <AntItemMeta
          avatar={
            <Avatar src={icon} size="large">
              {item.nicName || item.name}
            </Avatar>
          }
        />
      </AntItem>
    );
  }
}

interface IUserListProps extends RouteComponentProps, IInjectedType {
  config: IList & IButton;
  data?: any;
  pageUuid: string;
}
type ExposedProps = Omit<IUserListProps, keyof IInjectedType> & Partial<IInjectedType>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class UserList extends React.Component<IUserListProps> {
  state = {
    modalVisible: false,
  };

  getDataRows() {
    const { config } = this.props;
    const { dataIndex } = config;

    let rows: any[] = [];
    if (dataIndex) {
      const value = getDataValue(this.props.data, dataIndex);
      rows = value || [];
    } else if (this.props.data) {
      rows = this.props.data.rows;
    }

    if (!Array.isArray(rows)) {
      rows = [rows];
    }

    return rows;
  }

  getDataRow(id: string) {
    return this.getDataRows().find(row => row.id === id);
  }

  @computed
  get listData() {
    const { config } = this.props;
    const { dataIndex } = config;

    let data: any = {};
    if (dataIndex) {
      let value = toJS(getDataValue(this.props.data, dataIndex) || []);
      if (!Array.isArray(value)) {
        value = [value];
      }
      // console.log('table render value', value);
      formatDateString(value);
      data = {
        rows: value,
        page: 1,
        pageSize: 100,
        total: value.length,
      };
    } else if (this.props.data) {
      data = { ...toJS(this.props.data) };
      formatDateString(data.rows);
    }

    if (data.rows && data.rows.length > 0) {
      data.rows = data.rows.filter((row: any) => !row.delete);
      data.total = data.rows.length;
    }

    forEach(data.rows, row => {
      if (!row._id) {
        row._id = row.id || `${new Date().getTime()}${Math.random()}`;
      }
    });

    return data;
  }

  openModal = (): void => this.setState({ modalVisible: true });
  closeModal = (): void => this.setState({ modalVisible: false });

  render() {
    const { config, pageUuid } = this.props;
    const { cType, attr, modal } = config;
    const props: ButtonProps = {
      shape: 'circle',
      size: 'large',
      icon: <PlusOutlined />,
      onClick: () => {
        this.openModal();
      },
    };

    let ModalComponent: any = null;
    if (modal) {
      const modalProps: IModalProps = (typeof modal === 'string' ? { mType: modal } : modal) as IModalProps;
      const close = (): void => this.closeModal();
      const onCancel = (): void => close();
      const onOk = (): void => close();

      if (modalProps.mType === MODAL_TYPE_AUTO) {
        modalProps.mode = EModalMode.NEW;
        modalProps.target = this.props.config.source;
        const column = cloneDeep(this.props.config.column || []);
        column.forEach(col => {
          if (col.modal) {
            if (typeof col.modal === 'string') {
              col.modal = {
                mType: col.modal,
                target: this.props.config.source,
              } as TModalForCaller;
            } else if (col.modal) {
              col.modal.target = this.props.config.source;
            }
          }
        });
        modalProps.form = { column };
      }

      ModalComponent = (
        <ModalSelector
          pageUuid={this.props.pageUuid}
          {...modalProps}
          visible={this.state.modalVisible}
          onOk={onOk}
          onCancel={onCancel}
        />
      );
    }

    if (cType !== 'userList') {
      return null;
    }

    const data = this.listData;

    const removeFileItem = (index: number) => {
      const rows = this.getDataRows().filter((row: any) => !row.delete);
      if (rows) {
        rows[index].delete = true;
      }
    };

    return (
      <AntList
        {...attr}
        itemLayout="horizontal"
        rowKey="id"
        dataSource={data.rows}
        renderItem={(item: any, index: number) => (
          <UserItem item={item} pageUuid={pageUuid} onClick={() => removeFileItem(index)} />
        )}
      >
        <AntItem style={{ borderBottom: 'none' }}>
          <AntButton {...props} />
        </AntItem>
        {ModalComponent}
      </AntList>
    );
  }
}

export default UserList as React.ComponentType<ExposedProps>;
