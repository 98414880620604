import { CSSProperties } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { SelectProps } from 'antd/lib/select';
import { OptionProps } from 'rc-select/lib/Option';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { GroupProps, InputProps, SearchProps, TextAreaProps } from 'antd/lib/input';
import { CheckboxGroupProps, CheckboxProps } from 'antd/lib/checkbox';
import { RowProps } from 'antd/lib/row';
import { ColProps } from 'antd/lib/col';
import { ListProps } from 'antd/lib/list';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';

import { MenuProps } from 'antd/lib/menu';

// i18n
export type ILocale = {
  ko: string;
  en: string;
};
export function isILocale(field: any): field is ILocale {
  return field.ko !== undefined;
}

//
// Modal
export enum EModalMode {
  NEW = 'NEW',
  EDIT = 'EDIT',
}

export type TModalForCaller = {
  mType: string;
  mode?: EModalMode;
  target?: string;
};

interface IModalForCaller {
  modal?: string | TModalForCaller;
  editModal?: string | TModalForCaller;
}

export interface ILink {
  to: string;
  idIndex?: string;
  id?: string;
  dataIndex?: string;
  searchKey?: string;
}

export interface IValidator {
  type: string;
  url: string;
}

// components
export interface IConfigComp extends IModalForCaller {
  cType: string;
  showOnlyModify?: boolean;
  text?: ILocale | string;
  title?: ILocale | string;
  isRequire?: boolean;
  dataIndex?: string;
  searchIndex?: string;
  schemaIndex?: string;
  style?: CSSProperties;
  attr?: any;
  dateFormat?: string;
  validators?: IValidator[];
}

// button
export enum EButtonAction {
  SUMBIT = 'SUMBIT',
  PHONE = 'PHONE',
  MAIL = 'MAIL',
  NEW = 'NEW',
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  API = 'API',
  MESSAGE = 'MESSAGE',
}

export interface IActionAPIRequest {
  url: string;
  method: 'post' | 'put' | 'get' | 'delete';
}

export type IButton = {
  action?: EButtonAction;
  source?: string;
  request?: IActionAPIRequest;
  value?: any;
  column?: any[];
  link?: ILink;
  display?: IDisplay;
} & IConfigComp &
  ButtonProps;

// select
export type IOption = {
  text?: string;
  selected?: boolean;
} & Omit<OptionProps, 'children'>;

export enum EInputAction {
  QUERY = 'QUERY',
  SEARCH = 'SEARCH',
  VIEW = 'VIEW',
  MENU = 'MENU',
}

export type IInputOption = {
  action?: EInputAction;
  readOnly?: boolean;
  editOnlyNew?: boolean;
};

export type ISelect = {
  option?: IOption[];
  url?: string;
  code?: string;
  multi?: boolean;
  search?: boolean;
  keys?: {
    text: string;
    value: string;
  };
  onHeader?: true;
  allowAdd?: boolean;
  defaultSelect?: 'all' | string;
} & IInputOption &
  IConfigComp &
  SelectProps<any>;

// input
export type ISearch = IInputOption & IConfigComp & SearchProps;

export type IInput = IInputOption & IConfigComp & InputProps;

export type IMemo = {
  option?: IOption[];
  url?: string;
  keys?: {
    text: string;
    value: string;
  };
} & IInputOption &
  IConfigComp &
  TextAreaProps;

export type IInputGroup = {
  column?: IConfigComp[];
} & IInputOption &
  IConfigComp &
  GroupProps;

export type IFormGroup = {
  column?: IConfigComp[];
} & IInputOption &
  IConfigComp &
  GroupProps;

// date-picker
export type IDate = IInputOption & IConfigComp & DatePickerProps;
export type IDateRange = {
  beginDataIndex?: string;
  endDataIndex?: string;
  defaultRange?: 'year';
} & IInputOption &
  IConfigComp &
  RangePickerProps;

export type ICheckbox = {
  option?: {
    hiddenLabel?: boolean;
    value?: string;
    checked?: boolean;
  };
} & IInputOption &
  IConfigComp &
  CheckboxProps &
  CheckboxGroupProps;

export type IDisplay = (string | { dataIndex: string; code: string })[];
export interface ICodeData {
  [code: string]: { [key: string]: string };
}

// list
// NOTE: component의 props를 하나로 묵지말고, 아래처럼 attr로 구분하는것은?
//   IInput = {} & IConfigComp & InputProps
//   =>
//   IInput = { attrs?: InputProps } & IConfigComp
export type IList = {
  cType: string;
  dataIndex?: string;
  attr?: Partial<ListProps<any>>;
  display?: IDisplay;
  tagRender?: IValueColor[];
};

export type IMonthCell = {
  groupBy?: string | { namespace?: string; dataIndex: string; display?: IDisplay };
  display?: IDisplay;
};

export type IAgenda = {
  format?: string;
  targetDate?: string;
  monthCell?: IMonthCell;
} & IList &
  IButton;

// mix component
export type IMixedComp = {
  align?: 'left' | 'center' | 'right';
  showOnlyModify?: boolean;
} & (IButton | ISelect | IInput);

//
// default config
interface IDefaultConfig {
  header?: IMixedComp[];
  header2nd?: IMixedComp[];
  footer?: IMixedComp[];
  link?: ILink;
  searchKey?: string[];
}

// containers
export interface IContainer {
  cType: string;
  showOnlyModify?: boolean;
  config?: ITableConfig | IFormConfig | IMixedConfig | ITabConfig | IMultiViewConfig | IChartConfig;
  data?: any[];
  dataIndex?: string;
}

// table
export type ITableColumn = {
  cType?: string;
  title?: string | ILocale;
  action?: string;
  radio?: boolean;
  option?: IOption[];
  modal?: string | TModalForCaller;
  displayIndex?: string;
  hidden?: boolean;
  config?: ILinkConfig;
  tagRender?: IValueColor[];
  link?: ILink;
} & ColumnProps<any>;

export interface IValueColor {
  type: string;
  value: string;
  color: string;
  dataIndex?: string;
}

export type ITableConfig = {
  column?: ITableColumn[];
  style?: {
    container?: CSSProperties;
    table?: CSSProperties;
  };
  attr?: TableProps<any>;
} & IDefaultConfig &
  IModalForCaller;

// form
export type IFormColumn = {
  border?: boolean;
  with?: IMixedComp[];
  labelAlign?: 'left';
} & IConfigComp &
  InputProps &
  IInputOption;

export type IFormConfig = {
  column?: IFormColumn[];
  style?: IStyleGroup;
  attr?: IAttrGroup;
} & IDefaultConfig;

// mixed
export type IMixedConfig = {
  // cType: 'Mixed';
  column?: IMixedComp[];
  style?: IStyleGroup;
  attr?: IAttrGroup;
  isRow?: boolean;
} & IDefaultConfig;

// tab
export type ITabConfig = {
  column?: {
    active?: boolean;
    title?: ILocale | string;
    link?: string;
    column?: [];
  }[];
} & IDefaultConfig;

export type IMultiViewConfig = {
  column?: IContainer[];
} & IDefaultConfig;

export type IChartConfig = {
  cType: string;
  request: IActionAPIRequest;
  seriesField?: string;
  isStack?: boolean;
  isGroup?: boolean;
  style?: CSSProperties;
  displayTotal?: boolean;
  colors?: IValueColor[];
} & IDefaultConfig;

export type ITableChartConfig = {
  cType: string;
  request: IActionAPIRequest;
  rowId: ITableChartColumn;
  columnHeadKey?: string;
  column: ITableChartColumn[];
  style?: CSSProperties;
} & IDefaultConfig;

export type ITableChartColumn = {
  key: string;
  text?: ILocale | string;
};

export type IKanbanConfig = {
  url: string;
  column?: IMixedComp[];
} & IDefaultConfig;

export type ILinkConfig = {
  title?: ILocale | string;
  linkId?: string;
  division?: string;
  readOnly?: boolean;
  imageOnly?: boolean;
} & IConfigComp;

//
// container style
export type IStyleGroup = {
  container?: CSSProperties;
  row?: CSSProperties;
  column?: CSSProperties;
};

export type IAttrGroup = {
  row?: RowProps;
  column?: ColProps;
};

//
// Menu
export type TMenu = {
  menuId?: number;
  depth?: number;
  name: string;
  desc?: string;
  path?: string;
  url?: string;
  hidden?: boolean;
  parent?: string;
  isPublic?: boolean;
  slide?: string;
  noStorybook?: boolean;
};

export type TMenuContainer = {
  [Identifier: string]: TMenuContainer | TMenu[];
};

export interface IMenuProps extends Partial<MenuProps> {
  menu?: TMenuContainer;
  appear?: boolean;
  hideMenu?: () => void;
}

export interface IModalProps {
  mType: string;
  mode?: EModalMode;
  target?: string;
  namespace?: string;
  dataIndexes?: any;
  visible?: boolean;
  defaultKeyword?: string;
  form?: any;
  onOk?: () => void;
  onCancel?: () => void;
}

export interface IModalAddable {
  addable: boolean;
  url?: string;
}

export interface IModalConfigProps {
  column: any;
  url?: string;
  add?: IModalAddable;
}

export interface IModalSearchProps {
  config: IModalConfigProps;
  checkable?: boolean;
  onSelect: any;
  onClickNew: any;
  onClickClear: any;
}

export interface IModalFormProps {
  config: IModalConfigProps;
  data: any;
  style?: any;
  onSubmit?: any;
  onBack?: any;
}
