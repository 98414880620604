import * as React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { ICheckbox } from 'stores/ConfigStore';
import { AntFormItem, getDataValue, i18nFromJSON } from 'components';
import { Checkbox as AntCheckbox } from 'antd';
import { IFormItemProps, IInjectedType, Omit } from 'common/@types';

interface ICheckboxProps extends IInjectedType, IFormItemProps {
  config: ICheckbox;
  data?: any;
  pageUuid: string;
}
type ExposedProps = Omit<ICheckboxProps, keyof IInjectedType> & Partial<IInjectedType>;

@observer
class Checkbox extends React.Component<ICheckboxProps> {
  render() {
    const { config, data, form } = this.props;
    const { cType, dataIndex, style, option = {} } = config;

    if (cType !== 'checkbox') {
      return null;
    }

    const title = !option.hiddenLabel ? i18nFromJSON(config.title) : null;
    const checked = getDataValue(data, dataIndex) || option.checked;

    const initialValue = !!checked;

    return (
      <>
        <AntFormItem
          {...this.props.formItemProps}
          name={dataIndex}
          valuePropName="checked"
          initialValue={initialValue}
          form={form}
        >
          <AntCheckbox style={toJS(style)}>{title}</AntCheckbox>
        </AntFormItem>
        {this.props.children}
      </>
    );
  }
}

export default Checkbox as React.ComponentType<ExposedProps>;
