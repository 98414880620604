import * as React from 'react';
import moment from 'moment';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { getDataValue, i18nFromJSON } from 'components';
import { IConfigComp, ILocale } from 'stores/ConfigStore';
import { DATE_FORMAT } from 'components/constants';

interface IDataWithDescProps {
  config: { desc?: ILocale } & IConfigComp;
  data: any;
  title?: string;
  pageUuid?: string;
}

const Container = styled.div`
  padding: 10px;
`;

class DataWithDesc extends React.Component<IDataWithDescProps> {
  render() {
    const { config, data } = this.props;
    const { cType, desc, style, dataIndex, title, dateFormat } = config;
    const _value = getDataValue(data, dataIndex) || desc;
    const i18nValue = moment(_value).isValid()
      ? moment(_value).format(dateFormat ? dateFormat : DATE_FORMAT)
      : i18nFromJSON(_value);

    if (cType !== 'dataWithDesc') {
      return null;
    }

    return (
      <Container style={toJS(style)}>
        {title}
        {i18nValue}
      </Container>
    );
  }
}

export default DataWithDesc;
