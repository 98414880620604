import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { message, Modal } from 'antd';
import { IModalConfigProps, IModalProps } from 'stores/ConfigStore';
import { IInjectedStore, Omit } from 'common/@types';
import { Form as ModalForm, Search as ModalSearch } from 'components/Modal/Content';
import { convertAndSetData } from 'common/modalHelper';
import { merge } from 'lodash';
import { convertValueToJson } from 'common/componentHelper';
import { api } from 'providers';
import Spinner from 'components/Common/Spinner';

interface IProps extends IModalProps, IInjectedStore {
  mType: string;
  cType: string;
  title?: any;
  search: IModalConfigProps;
  form: IModalConfigProps;
  pageUuid: string;
}

type ExposedProps = Omit<IProps, keyof IInjectedStore> & Partial<IInjectedStore>;

type IState = {
  mode: string;
};

const SEARCH_MODE = 'search';
const FORM_MODE = 'form';

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class SearchWithForm extends React.Component<IProps, IState> {
  state: IState = {
    mode: SEARCH_MODE,
  };

  onSelectItem = (form: any) => {
    this.setState({
      mode: FORM_MODE,
    });
    this.props.keyStore.mergeItem(this.props.pageUuid, { form });
  };

  onClickClear = () => {
    console.log('onClickClear');
    this.onSubmit({});
  };

  onClickNew = () => {
    console.log('onClickNew');
    this.setState({
      mode: FORM_MODE,
    });
    this.props.keyStore.mergeItem(this.props.pageUuid, { form: { new: true } });
  };

  async saveForm(values: any) {
    const { form } = this.props.keyStore.getItem(this.props.pageUuid);
    const mergedData = merge(form, values);
    console.log('mergedData', mergedData);

    const convertedData = convertValueToJson(mergedData);
    console.log('convertedData', convertedData);

    if (this.props.search.add && this.props.search.add.url) {
      try {
        this.props.appStore.setSpinning(true, undefined, this.props.pageUuid);

        const [path, params] = this.props.search.add.url.split('?');
        const url = path.replace(/:(\w+)$/g, '');

        const response = await api.post(url, convertedData);
        return response.data;
      } catch (error) {
        console.log('error on saveForm', error);
        message.error(JSON.stringify(error));
      } finally {
        this.props.appStore.setSpinning(false, undefined, this.props.pageUuid);
      }
    }
  }

  // Form에서 쓰는 녀석들
  onSubmit = async (values: any) => {
    const { form } = this.props.keyStore.getItem(this.props.pageUuid);
    if (form?.new) {
      values = await this.saveForm(values);
    }

    convertAndSetData(this, values);
    this.props.onOk && this.props.onOk();
    this.setState({
      mode: SEARCH_MODE,
    });
    this.props.keyStore.mergeItem(this.props.pageUuid, { form: {} });
  };

  onBack = () => {
    this.setState({
      mode: SEARCH_MODE,
    });
    this.props.keyStore.mergeItem(this.props.pageUuid, { form: {} });
  };

  // self에서 쓰는 녀석들
  onCancel = () => {
    this.setState({
      mode: SEARCH_MODE,
    });
    this.props.keyStore.mergeItem(this.props.pageUuid, { form: {} });
    this.props.onCancel && this.props.onCancel();
  };

  render() {
    const { cType, visible, mode, title, pageUuid } = this.props;
    const { form } = this.props.keyStore.getItem(this.props.pageUuid);

    if (cType !== 'searchWithForm') {
      return null;
    }

    return (
      <Modal open={visible} onCancel={this.onCancel} footer={null} title={title} destroyOnClose={true}>
        <Spinner pageUuid={this.props.pageUuid}>
          {visible ? (
            this.state.mode === SEARCH_MODE ? (
              <ModalSearch
                config={this.props.search}
                pageUuid={pageUuid}
                onClickClear={this.onClickClear}
                onSelect={this.onSelectItem}
                onClickNew={this.onClickNew}
              />
            ) : (
              <ModalForm
                config={this.props.form}
                pageUuid={pageUuid}
                data={form}
                onSubmit={this.onSubmit}
                onBack={this.onBack}
              />
            )
          ) : null}
        </Spinner>
      </Modal>
    );
  }
}

export default SearchWithForm as React.ComponentType<ExposedProps>;
