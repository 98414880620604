import { action, observable, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { api } from 'providers';

export interface IDictionary {
  [key: string]: any;
}

export interface IKeyStoreData {
  key: string;
  id?: string;
  parentPageUuid?: string;
  search?: IDictionary;
  form?: IDictionary;
  data?: IDictionary;
  page?: IDictionary;
  option?: IDictionary;
  hidden?: boolean;
  [key: string]: any;
}

export interface ICodeDataRow {
  code: string;
  name: string;
}

export default class KeysStore {
  @observable store: { [key: string]: IKeyStoreData } = {};

  constructor(initialState: IDictionary = {}) {
    this.store = initialState;
  }

  addReaction(key: string, subKey: string, callback: (value: any) => any) {
    this.checkItem(key);

    return reaction(
      () => this.store[key][subKey],
      value => {
        callback && callback(value);
      },
    );
  }

  @action public checkItem(key: string) {
    if (!this.store[key]) {
      this.clearItem(key);
    }
  }

  @action public setItem(key: string, value: Partial<IKeyStoreData>): void {
    this.store[key] = { key: uuidv4(), ...value };
  }

  @action public mergeItem(key: string, value: Partial<IKeyStoreData>, redraw?: boolean): void {
    this.checkItem(key);

    Object.entries(value).forEach(([k, v]) => {
      this.store[key][k] = v;
    });

    // data refresh for ant.design
    if (redraw) {
      this.store[key].key = uuidv4();
    }
  }

  public getItem(key: string): IKeyStoreData {
    this.checkItem(key);
    return this.store[key];
  }

  @action public clearItem(key: string): void {
    this.store[key] = { key: uuidv4() };
  }

  @action public removeItem(key: string): void {
    delete this.store[key];
  }

  @action public setCodeData(key: string, code: string, value: ICodeDataRow[]) {
    const codeData = this.store[key]?.codeData || {};

    this.mergeItem(key, { codeData: { ...codeData, [code]: value } });
  }

  public getCodeData(key: string, code: string): ICodeDataRow[] {
    return this.store[key]?.codeData?.[code] || [];
  }

  public async fetchCodeData(key: string, code: string, isUrl = false): Promise<ICodeDataRow[]> {
    const codeData = this.getCodeData(key, code);
    if (codeData.length) {
      return codeData;
    }

    const { data } = await api.get(
      isUrl
        ? `${code}${code.indexOf('?') === -1 ? '?' : '&'}pageSize=100000000&scope=code`
        : `/v1/commonCode/details/${code}`,
    );

    this.setCodeData(key, code, data?.rows || []);
    return this.getCodeData(key, code);
  }
}

export type IKeysStore = InstanceType<typeof KeysStore>;
