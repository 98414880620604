import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IFormItemProps, IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { ILinkConfig } from 'stores/ConfigStore';
import { DeleteOutlined, DownloadOutlined, FolderAddOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, message, Row, Spin, Upload as AntUpload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { api } from 'providers';
import { set } from 'lodash';
import { observable } from 'mobx';
import { getDataValue, i18nFromJSON } from 'components';
import styled from 'styled-components';
import confirmDialog from 'common/confirmDialog';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { UploadListType } from 'antd/lib/upload/interface';
import { each as PromiseEach } from 'bluebird';
import multiDownload, { download } from 'common/download';
import FolderSelect from './FolderSelect';
import { FileRow, UploadListItem } from './UploadListItem';
import * as queryString from 'query-string';

const { Panel } = Collapse;
const { Search } = Input;

const StyledCollapse = styled(Collapse)`
  .anticon-close {
    font-size: 20px !important;
  }

  .ant-upload-picture-card-wrapper {
    margin-top: 10px;
  }

  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledSearch = styled(Search)`
  margin-right: 5px;
  margin-bottom: 5px;
  width: 300px;
`;

const GroupButton = styled(Button)`
  margin-right: 5px;
  margin-bottom: 5px;
`;

interface IAttachmentsProps extends IInjectedType, IInjectedStore, IFormItemProps {
  data?: any;
  pageUuid: string;
  config: { config: ILinkConfig; dataIndex: string; title: string };
}
type ExposedProps = Omit<IAttachmentsProps, keyof (IInjectedType & IInjectedStore & IFormItemProps)> &
  Partial<IInjectedType & IInjectedStore & IFormItemProps>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Upload extends React.Component<IAttachmentsProps> {
  @observable spinning = false;
  @observable activeKey: string | string[] | undefined = undefined;
  @observable folderList: Record<string, any[]> = {};
  @observable fileList: Record<string, { code: string; name: string; rows: FileRow[] }> = {};
  @observable preview = { visible: false, current: 0, rows: [] as FileRow[] };
  @observable checkedRow: FileRow[] = [];

  // @computed
  // get folderList(): Record<string, string[]> {
  //   const { dataIndex } = this.props.config;
  //
  //   return this.props.keyStore.getItem(this.props.pageUuid)[`${dataIndex}-folderList`] ?? {};
  // }
  //
  // set folderList(value: Record<string, string[]>) {
  //   const { dataIndex } = this.props.config;
  //
  //   this.props.keyStore.mergeItem(this.props.pageUuid, {
  //     [`${dataIndex}-folderList`]: value,
  //   });
  // }

  componentDidMount() {
    this.updateFileList();
  }

  componentDidUpdate(prevProps: IAttachmentsProps) {
    if (this.props.data !== prevProps.data || this.props.config.dataIndex !== prevProps.config.dataIndex) {
      this.updateFileList();
    }
  }

  async updateFileList() {
    try {
      this.spinning = true;

      const folderList: Record<string, string[]> = {};
      const fileList: Record<string, { code: string; name: string; rows: FileRow[] }> = {};

      const { config, data } = this.props;
      const { title, dataIndex, config: uploadConfig } = config;

      if (dataIndex && !getDataValue(data, dataIndex)) {
        set(data, dataIndex, []);
      }

      if (uploadConfig?.division) {
        const d = uploadConfig?.division;
        folderList[d] = [];
        fileList[d] = { code: uploadConfig?.division, name: i18nFromJSON(title), rows: [] };
      } else {
        const codeData = await this.props.keyStore.fetchCodeData(this.props.pageUuid, 'AttachmentDivision');

        codeData.forEach(({ code, name }) => {
          if (!fileList[code]) {
            folderList[code] = [];
            fileList[code] = { code, name, rows: [] };
          }
        });
      }

      const target = getDataValue(data, dataIndex) || [];
      target
        .filter((row: any) => {
          if (row.delete) {
            return false;
          }
          if (uploadConfig?.division) {
            return row.division === uploadConfig.division;
          } else if (folderList[row.division]) {
            return true;
          }
          return false;
        })
        .forEach((row: any) => {
          if (row.folder && !folderList[row.division].find(v => v === row.folder)) {
            folderList[row.division].push(row.folder);
          }

          const _row: FileRow = {
            uid: row.id,
            division: row.division,
            name: row.name,
            status: row.delete ? 'removed' : 'done',
            url: row.path,
            folder: row.folder,
          };

          fileList[row.division]?.rows.push(_row);
        });

      this.folderList = folderList;
      this.fileList = fileList;
    } finally {
      this.spinning = false;
    }
  }

  onNewFolder(code: string, value: string) {
    if (value && !this.folderList[code].find(v => v === value)) {
      this.folderList[code].push(value);
    }
  }

  onCheck(file: FileRow, checked: boolean) {
    if (checked) {
      this.checkedRow.push(file);
    } else {
      this.checkedRow = this.checkedRow.filter(r => r.uid !== file.uid);
    }
  }

  async groupDelete(division: string, folder: string) {
    const checkedRow = this.checkedRow.filter(r => r.division === division && r.folder === folder);
    console.log('groupDelete', checkedRow);

    const { t } = this.props.i18nStore;
    const {
      config: { dataIndex },
      data,
    } = this.props;

    if (!checkedRow.length) {
      message.warning(t.button.group.noSelect);
      return;
    }

    const ok = await confirmDialog({ content: t.confirm.delete });

    if (!ok) {
      return;
    }

    try {
      this.spinning = true;

      await PromiseEach(checkedRow, async info => {
        if (!info.uid) {
          return;
        }

        try {
          await api.delete(`/v1/attachment/${info.uid}`);
        } catch (e) {
          console.log('error on attachment delete', e);
        }

        const target = getDataValue(data, dataIndex);
        set(
          data,
          dataIndex,
          target.filter((r: any) => r.id !== info.uid),
        );

        await this.updateFileList();
      });
    } finally {
      this.spinning = false;
    }
  }

  async groupDownload(division: string, folder: string) {
    const checkedRow = this.checkedRow.filter(r => r.division === division && r.folder === folder);
    console.log('groupDownload', checkedRow);

    const { t } = this.props.i18nStore;

    if (!checkedRow.length) {
      message.warning(t.button.group.noSelect);
      return;
    }

    await multiDownload(
      checkedRow.map(file => ({
        url: `/api/v1/attachment/download?${queryString.stringify({
          url: file.url,
          filename: file.name,
        })}`,
        name: file.name,
      })),
    );
  }

  async groupMove(division: string, folder: string, targetFolder: string | undefined) {
    const checkedRow = this.checkedRow.filter(r => r.division === division && r.folder === folder);
    console.log('groupMove', checkedRow);

    const { t } = this.props.i18nStore;
    const {
      config: { dataIndex },
      data,
    } = this.props;

    if (!checkedRow.length) {
      message.warning(t.button.group.noSelect);
      return;
    }

    if (typeof targetFolder !== 'string') {
      message.warning(t.button.group.noFolder);
      return;
    }

    const ok = await confirmDialog({ content: t.confirm.move });

    if (!ok) {
      return;
    }

    try {
      this.spinning = true;

      const target = getDataValue(data, dataIndex);

      set(
        data,
        dataIndex,
        target.map((r: any) => {
          const file = checkedRow.find(c => r.id === c.uid);
          if (file) {
            r.folder = targetFolder;
          }

          return r;
        }),
      );

      await this.updateFileList();
    } finally {
      this.spinning = false;
    }
  }

  renderUpload(division: string, folder: string, rows: FileRow[], folders: string[]) {
    const { me } = this.props.appStore.userStore;
    const { t } = this.props.i18nStore;
    const { config, data } = this.props;
    const { dataIndex, config: uploadConfig } = config;

    const listType: UploadListType = uploadConfig.imageOnly || division === 'photo' ? 'picture-card' : 'picture';

    const uploadProps: UploadProps = {
      name: 'file',
      multiple: true,
      listType,
      customRequest: async ({ onSuccess, onError, file }) => {
        try {
          this.spinning = true;
          const formData = new FormData();
          formData.append('files', file as any);
          const { data: fileData } = await api.post('/v1/attachment/upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          fileData.map((f: any) => getDataValue(data, dataIndex).push({ ...f, division, folder }));

          await this.updateFileList();

          onSuccess?.({}, fileData);
        } catch (error: any) {
          onError?.(error);
        } finally {
          this.spinning = false;
        }
      },
      fileList: rows,
      onChange: info => {
        if (info.file.status !== 'uploading') {
          console.log('Upload onChange', info.file.status);
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onRemove: async info => {
        if (!info.uid) {
          return;
        }

        const ok = await confirmDialog({ content: t.confirm.delete });

        if (!ok) {
          return;
        }

        try {
          this.spinning = true;
          try {
            await api.delete(`/v1/attachment/${info.uid}`);
          } catch (e) {
            console.log('error on attachment delete', e);
          }

          const target = getDataValue(data, dataIndex);
          set(
            data,
            dataIndex,
            target.filter((r: any) => r.id !== info.uid),
          );

          await this.updateFileList();
        } finally {
          this.spinning = false;
        }
      },
      onPreview: async file => {
        if (/\.(gif|jpg|jpeg|tiff|png|heic|webp)$/i.test(file.name)) {
          const filteredRows = rows.filter(
            r =>
              (!r.folder || r.folder === (file as FileRow).folder) &&
              /\.(gif|jpg|jpeg|tiff|png|heic|webp)$/i.test(r.name),
          );

          this.preview = {
            visible: true,
            current: filteredRows.findIndex(r => r.uid === file.uid),
            rows: filteredRows,
          };
        } else {
          await download(
            `/api/v1/attachment/download?${queryString.stringify({
              url: file.url,
              filename: file.name,
            })}`,
            file.name,
          );
        }
      },
      itemRender: (originNode, file, currFileList) => (
        <UploadListItem
          originNode={originNode}
          file={file as FileRow}
          fileList={currFileList as FileRow[]}
          listType={listType}
          onCheck={(f, c) => this.onCheck(f, c)}
          // onRemove={f => uploadProps.onRemove?.(f)}
          onPreview={f => uploadProps.onPreview?.(f)}
        />
      ),
    };

    return (
      <div>
        <Row>
          {me && me.isReadOnly ? null : (
            <GroupButton onClick={() => this.groupDelete(division, folder)}>
              <DeleteOutlined /> {t.button.group.delete}
            </GroupButton>
          )}
          <GroupButton onClick={() => this.groupDownload(division, folder)}>
            <DownloadOutlined /> {t.button.group.download}
          </GroupButton>
          {me && me.isReadOnly ? null : (
            <FolderSelect folders={folders} onClick={v => this.groupMove(division, folder, v)} />
          )}
        </Row>
        <AntUpload key={division} {...uploadProps}>
          {uploadConfig.readOnly || (me && me.isReadOnly) ? null : (
            <Button>
              <UploadOutlined /> {t.button.add}
            </Button>
          )}
        </AntUpload>
      </div>
    );
  }

  render() {
    const { me } = this.props.appStore.userStore;
    const { t } = this.props.i18nStore;
    const { config } = this.props;
    const { config: uploadConfig } = config;

    const defaultActiveKey: string[] = [];
    if (uploadConfig?.division) {
      defaultActiveKey.push(uploadConfig?.division);
    }

    const uploadComps = Object.values(this.fileList).map(({ code, name, rows }) => {
      if (rows.length && !defaultActiveKey.find(k => k === code)) {
        defaultActiveKey.push(code);
      }

      const folder = this.folderList[code];

      return (
        <Panel key={code} header={name}>
          {me && me.isReadOnly ? null : (
            <StyledSearch
              onSearch={v => this.onNewFolder(code, v)}
              placeholder={t.newFolder.placeholder}
              enterButton={
                <Button>
                  <FolderAddOutlined /> {t.newFolder.button}
                </Button>
              }
            />
          )}

          {folder.length ? (
            <StyledCollapse defaultActiveKey={rows.map(r => r.folder)}>
              {folder.map(f => (
                <Panel key={f} header={f}>
                  {this.renderUpload(
                    code,
                    f,
                    rows.filter(r => r.folder === f),
                    folder,
                  )}
                </Panel>
              ))}
            </StyledCollapse>
          ) : null}

          {this.renderUpload(
            code,
            '',
            rows.filter(r => !r.folder),
            folder,
          )}
        </Panel>
      );
    });

    return (
      <Spin spinning={this.spinning}>
        <StyledCollapse activeKey={this.activeKey || defaultActiveKey} onChange={k => (this.activeKey = k)}>
          {uploadComps}
        </StyledCollapse>
        <Lightbox
          open={this.preview.visible}
          close={() => {
            this.preview.visible = false;
          }}
          plugins={[Thumbnails, Zoom]}
          index={this.preview.current}
          slides={this.preview.rows.map(r => ({ src: r.url }))}
        />
      </Spin>
    );
  }
}

export default Upload as React.ComponentType<ExposedProps>;
