import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Heading } from 'components/Typo';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { ILinkConfig } from 'stores/ConfigStore';
import { Upload } from '.';

interface IAttachmentsProps extends IInjectedType, IInjectedStore {
  dataIndex: string;
  data?: any;
  pageUuid: string;
  config: ILinkConfig;
  children?: React.ReactNode;
  title: string;
}
type ExposedProps = Omit<IAttachmentsProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Attachments extends React.Component<IAttachmentsProps> {
  render() {
    const { dataIndex, data, pageUuid, config, title, children } = this.props;

    return (
      <div style={{ marginTop: 20 }}>
        <Heading pageUuid={pageUuid} config={{ cType: 'heading', title: (config && config.title) || 'Attachments' }} />
        <Upload data={data} pageUuid={pageUuid} config={{ config, dataIndex, title }} formItemProps={{ children }} />
      </div>
    );
  }
}

export default Attachments as React.ComponentType<ExposedProps>;
