import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { IInjectedStore, Omit } from 'common/@types';

interface IPageProps extends RouteComponentProps, IInjectedStore {
  children?: React.ReactNode;
}

export interface ILocation {
  pathname?: string;
  search?: string;
}

type ExposedProps = Omit<IPageProps, keyof (RouteComponentProps & IInjectedStore)> &
  Partial<RouteComponentProps & IInjectedStore>;

@inject('appStore', 'i18nStore')
@observer
class PrivateRoute extends React.Component<IPageProps> {
  UNSAFE_componentWillMount() {
    this.handleSpinner();
  }

  UNSAFE_componentWillUpdate() {
    this.handleSpinner();
  }

  handleSpinner() {
    const { loaded, setSpinning } = this.props.appStore;

    if (!loaded) {
      setSpinning(true);
    } else {
      setSpinning(false);
    }
  }

  render() {
    const { userStore, loaded } = this.props.appStore;
    const { isLoggedIn } = userStore;

    if (!loaded) {
      return <div style={{ height: '100%' }} />;
    } else if (!isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              referrer: this.props.location && `${this.props.location.pathname}?${this.props.location.search}`,
            },
          }}
        />
      );
    }
    return this.props.children;
  }
}

export default withRouter(PrivateRoute) as React.ComponentType<ExposedProps>;
