import { flow, getEnv, types } from 'mobx-state-tree';
import IStoreEnv from './StoreEnv';
import { toJS } from 'mobx';

export const User = types.model('User', {
  userId: types.identifierNumber,
  name: types.maybeNull(types.string),
  password: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
  group: types.maybeNull(types.string),
  part: types.maybeNull(types.string),
  rank: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  nicName: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  mobile: types.maybeNull(types.string),
  isReadOnly: types.maybeNull(types.boolean),
  allowMenu: types.maybeNull(types.array(types.string)),
});

type IUserType = typeof User.Type;
export interface IUser extends IUserType {}

const UserStore = types
  .model('UserStore', {
    users: types.optional(types.map(User), {}),
    meId: types.maybe(types.number),
  })
  .views(self => ({
    get me() {
      return self.meId && self.users.get(self.meId + '');
    },
    get provider() {
      return getEnv<IStoreEnv>(self).provider;
    },
    get isLoggedIn() {
      return !!self.meId;
    },
  }))
  .actions(self => {
    function getPlainMe() {
      return toJS(self.me);
    }

    function setMe(userId: number) {
      self.meId = userId;
    }

    function addUser(user: IUser) {
      self.users.put(user);
    }

    function clearUser() {
      self.users.clear();
      self.meId = undefined;
    }

    const getMe = flow(function* (token?: string) {
      const user = yield self.provider.AuthRequest.me(token);
      if (user) {
        addUser(user);
        self.meId = user.userId;
      }

      return self.me;
    });

    const login = flow(function* (email: string | undefined, userPs: string | undefined) {
      yield self.provider.AuthRequest.login(email, userPs);
      return yield getMe();
    });

    const logout = flow(function* () {
      yield self.provider.AuthRequest.logout();
      clearUser();
    });

    const init = flow(function* () {
      yield getMe();
      return self.me;
    });

    return {
      getPlainMe,
      setMe,
      getMe,
      addUser,
      login,
      logout,
      init,
    };
  });

type IUserStoreType = typeof UserStore.Type;
export interface IUserStore extends IUserStoreType {}

export default UserStore;
