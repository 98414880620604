import { TMenu, TMenuContainer } from 'stores/ConfigStore';

const menu: TMenuContainer = {};

menu.equipment = [
  {
    name: 'menu.equipment.schedule.calendar',
    desc: '장비/장비일정/캘린더',
    url: '/v1/equipment/schedules',
    path: '/equipment/schedule.calendar',
    slide: 'v2-g1ac4045ed98_0_665',
  },
  {
    name: 'menu.equipment.schedule.list',
    desc: '장비/장비일정/목록',
    url: '/v1/equipment/schedules',
    path: '/equipment/schedule',
    slide: 'v2-g1ac4045ed98_0_665',
  },
  {
    name: 'menu.equipment.schedule.detail',
    hidden: true,
    desc: '장비/장비일정/폼',
    url: '/v1/equipment/schedule/:id',
    path: '/equipment/schedule/:id',
    slide: 'v2-g1ac4045ed98_0_831',
  },
  {
    name: 'menu.equipment.list',
    desc: '장비/장비대장/목록',
    path: '/equipment/list',
    url: '/v1/equipments',
    slide: 'v2-g1a49a093742_0_36',
  },
  {
    name: 'menu.equipment.history.detail',
    hidden: true,
    desc: '장비/장비이력/폼',
    path: '/equipment/history/:id',
    url: '/v1/equipment/history/:id',
    slide: 'v2-g1ac4045ed98_0_1005',
  },
  {
    name: 'menu.equipment.note.list',
    desc: '장비/장비비고/목록',
    url: '/v1/equipment/notes',
    path: '/equipment/note',
    slide: 'v2-g1a49a093742_0_65',
  },
  {
    name: 'menu.equipment.note.detail',
    hidden: true,
    desc: '장비/장비비고/폼',
    url: '/v1/equipment/note/:id',
    path: '/equipment/note/:id',
    slide: 'v2-g1a49a093742_0_117',
  },
  {
    name: 'menu.equipment.statistics',
    desc: '장비/장비현황',
    path: '/equipment/statistics',
    slide: 'v2-g1a49cab6808_0_0',
  },
];

menu.quality = [
  {
    name: 'menu.quality.schedule.calendar',
    desc: '품질/품질일정/캘린더',
    url: '/v1/quality/schedules',
    path: '/quality/schedule.calendar',
    slide: 'v2-g1a4ad3a08ac_0_300',
  },
  {
    name: 'menu.quality.schedule.list',
    desc: '품질/품질일정/목록',
    url: '/v1/quality/schedules',
    path: '/quality/schedule',
    slide: 'v2-g1a4ad3a08ac_0_300',
  },
  {
    name: 'menu.quality.schedule.detail',
    hidden: true,
    desc: '품질/품질일정/폼',
    url: '/v1/quality/schedule/:id',
    path: '/quality/schedule/:id',
    slide: 'v2-g1a4ad3a08ac_0_439',
  },
  {
    name: 'menu.quality.schedule.export',
    desc: '품질/품질일정/Export',
    path: '/quality/schedule.export',
    slide: 'v2-g1a4ad3a08ac_0_300',
  },
  {
    name: 'menu.quality.issue.list',
    desc: '품질/품질이슈/목록',
    url: '/v1/quality/issues',
    path: '/quality/issue',
    slide: 'v2-g1a49cab6808_0_47',
  },
  {
    name: 'menu.quality.issue.detail',
    hidden: true,
    desc: '품질/품질이슈/폼',
    url: '/v1/quality/issue/:id',
    path: '/quality/issue/:id',
    slide: 'v2-g1a4ad3a08ac_0_1',
  },
  {
    name: 'menu.quality.issue.statistics',
    desc: '품질/품질이슈/통계',
    path: '/quality/issue/statistics',
    slide: 'v2-g1a4ad3a08ac_0_590',
  },
  {
    name: 'menu.quality.schedule.statistics',
    desc: '품질/품질일정/통계',
    path: '/quality/schedule/statistics',
    slide: 'v2-g1a4ad3a08ac_0_788',
  },
];

menu.education = [
  {
    name: 'menu.education.calendar.list',
    desc: '교육/일정',
    url: '/v1/educations',
    path: '/education/calendar',
    slide: 'v1-p46',
  },
  {
    name: 'menu.education.list',
    desc: '교육/목록',
    path: '/education/list',
    url: '/v1/educations',
    slide: 'v1-g3c384acf21_3_49',
  },
  {
    name: 'menu.education.detail',
    hidden: true,
    desc: '교육/폼',
    path: '/education/list/:id',
    url: '/v1/education/:id',
    slide: 'v1-p47',
  },
  {
    name: 'menu.education.ready',
    hidden: true,
    desc: '교육/준비',
    path: '/education/ready/:id',
    url: '/v1/education/:id',
    slide: 'v1-p48',
  },
  {
    name: 'menu.education.start',
    hidden: true,
    desc: '교육/시작',
    path: '/education/start/:id',
    url: '/v1/education/:id',
    slide: 'v1-p49',
  },
  {
    name: 'menu.education.end',
    hidden: true,
    desc: '교육/종료',
    path: '/education/end/:id',
    url: '/v1/education/:id',
    slide: 'v1-p50',
  },
  {
    name: 'menu.education.student.list',
    desc: '교육/교육생/목록',
    path: '/student',
    url: '/v1/students',
    slide: 'v1-g37b887e9e4_1_412',
  },
  {
    name: 'menu.education.student.detail',
    hidden: true,
    desc: '교육/교육생/폼',
    path: '/student/:id',
    url: '/v1/student/:id',
    slide: 'v1-g4d9d28f185_0_13',
  },
  {
    name: 'menu.education.graduate.list',
    desc: '교육/수료자/목록',
    path: '/graduate',
    url: '/v1/graduates',
    slide: 'v1-g4811cdd0de_0_13',
  },
  {
    name: 'menu.education.graduate.detail',
    hidden: true,
    desc: '교육/수료자/폼',
    path: '/graduate/:id',
    url: '/v1/graduate/:id',
    slide: 'v1-g37b887e9e4_1_343',
  },
  {
    name: 'menu.public.education.apply',
    hidden: true,
    desc: '교육/[고객] 융착사 인증교육 신청서',
    path: '/public/education/apply/:id',
    isPublic: true,
    url: '',
    slide: 'v1-p52',
    noStorybook: true,
  },
  {
    name: 'menu.education.messagetemplate.list',
    desc: '교육/메시지 템플릿/목록',
    path: '/message/template',
    url: '/v1/message/templates',
    slide: '',
  },
  {
    name: 'menu.education.messagetemplate.detail',
    hidden: true,
    desc: '교육/메시지 템플릿/폼',
    path: '/message/template/:id',
    url: '/v1/message/template/:id',
    slide: '',
  },
  {
    name: 'menu.education.statistics',
    desc: '교육/통계',
    path: '/education/statistics',
    slide: '',
  },
];

menu.common = [
  {
    name: 'menu.common.user.list',
    desc: '공통/사용자/목록',
    path: '/user',
    url: '/v1/users',
    slide: 'v2-g1ac4045ed98_0_573',
  },
  {
    name: 'menu.common.user.detail',
    hidden: true,
    desc: '공통/사용자/폼',
    path: '/user/:id',
    url: '/v1/user/:id',
    slide: 'v2-g1ac4045ed98_0_573',
  },
  {
    name: 'menu.common.commonCode.list',
    desc: '공통/공통코드/목록',
    path: '/commonCode/list',
    url: '/v1/commonCodes',
    slide: 'v2-g1d318e61036_0_0',
  },
  {
    name: 'menu.common.commonCode.detail',
    hidden: true,
    desc: '공통/공통코드/폼',
    path: '/commonCode/list/:id',
    url: '/v1/commonCode/:id',
    slide: 'v2-g1d318e61036_0_0',
  },
  {
    name: 'menu.customer.list',
    desc: '공통/업체/목록',
    path: '/customer/list',
    url: '/v1/companys',
    slide: 'v1-p54',
  },
  {
    name: 'menu.customer.detail',
    hidden: true,
    desc: '공통/업체/폼',
    path: '/customer/list/:id',
    url: '/v1/company/:id',
    slide: 'v1-p54',
  },
  {
    name: 'menu.common.project.list',
    desc: '공통/프로젝트/목록',
    path: '/project/list',
    url: '/v1/projects',
    slide: 'v2-',
  },
  {
    name: 'menu.common.project.detail',
    hidden: true,
    desc: '공통/프로젝트/폼',
    path: '/project/list/:id',
    url: '/v1/project/:id',
    slide: 'v2-',
  },
  {
    name: 'menu.equipment.product.list',
    desc: '장비/품목/목록',
    path: '/equipment/product',
    url: '/v1/products',
    slide: 'v1-g42e7e6a700_0_97',
  },
  {
    name: 'menu.equipment.product.detail',
    hidden: true,
    desc: '장비/품목/폼',
    path: '/equipment/product/:id',
    url: '/v1/product/:id',
    slide: 'v1-g42e7e6a700_0_97',
  },
];

menu.oldMenu = {
  kanban: [
    {
      name: 'menu.kanban.list',
      desc: '업무보드 (칸반)/목록',
      url: '/v1/kanbans',
      path: '/kanban',
      slide: 'v1-p17',
    },
    {
      name: 'menu.kanban.detail',
      hidden: true,
      desc: '업무보드 (칸반)/폼',
      url: '/v1/kanban/:id',
      path: '/kanban/:id',
      slide: 'v1-p18',
    },
    {
      name: 'menu.calendar.list',
      desc: '업무일정표/목록',
      path: '/calendar',
      url: '/v1/kanbans',
      slide: 'v1-p19',
    },
  ],
  rental: [
    {
      name: 'menu.rental.avaliable',
      hidden: true,
      desc: '임대/가능 수량',
      path: '/rental/avaliable',
      url: '',
      slide: 'v1-p22',
      noStorybook: true,
    },
    {
      name: 'menu.rental.count',
      hidden: true,
      desc: '임대/수량',
      path: '/rental/count',
      url: '',
      slide: 'v1-p22',
      noStorybook: true,
    },
    {
      name: 'menu.rental.calendar',
      hidden: true,
      desc: '임대/일정',
      path: '/rental/calendar',
      url: '',
      slide: 'v1-p23',
      noStorybook: true,
    },
    {
      name: 'menu.rental.rent.list',
      desc: '임대/융착기/목록',
      path: '/rental/list',
      url: '/v1/rentals',
      slide: 'v1-g393ecfb1b8_4_73',
    },
    {
      name: 'menu.rental.rent.detail',
      hidden: true,
      desc: '임대/융착기/폼',
      path: '/rental/list/:id',
      url: '/v1/rental/:id',
      slide: 'v1-g393ecfb1b8_4_2',
    },
    {
      name: 'menu.rental.assign.list',
      desc: '임대/융착기/할당/목록',
      path: '/rental/assign',
      url: '/v1/rental/products',
      slide: 'v1-g37b887e9e4_1_1',
    },
    {
      name: 'menu.rental.assign.detail',
      hidden: true,
      desc: '임대/융착기/할당/폼',
      path: '/rental/assign/:id',
      url: '/v1/rental/product/:id',
      slide: 'v1-g37b887e9e4_1_1',
    },
    {
      name: 'menu.public.rental.apply',
      hidden: true,
      desc: '임대/융착기/[고객] 임대 요청서',
      path: '/public/rental/apply/:id',
      url: '',
      isPublic: true,
      slide: 'v1-p25',
      noStorybook: true,
    },
    {
      name: 'menu.rental.end.list',
      desc: '임대/종료/목록',
      path: '/rental/end',
      url: '/v1/rental/products',
      slide: 'v1-g393ecfb1b8_4_289',
    },
    {
      name: 'menu.rental.end.detail',
      hidden: true,
      desc: '임대/종료/폼',
      path: '/rental/end/:id',
      url: '/v1/rental/product/:id',
      slide: 'v1-g393ecfb1b8_4_289',
    },
    {
      name: 'menu.rental.product.list',
      desc: '임대/임대품목/목록',
      path: '/rental/product',
      url: '/v1/products',
      slide: 'v1-g42e7e6a700_0_97',
    },
    {
      name: 'menu.rental.product.detail',
      hidden: true,
      desc: '임대/임대품목/폼',
      path: '/rental/product/:id',
      url: '/v1/product/:id',
      slide: 'v1-g42e7e6a700_0_97',
    },
  ],
  sales: [
    {
      name: 'menu.sales.order.list',
      desc: '판매/장비 발주/목록',
      path: '/sales/order',
      url: '/v1/part/partOrders',
      slide: 'v1-p27',
    },
    {
      name: 'menu.sales.order.detail',
      hidden: true,
      desc: '판매/장비 발주/폼',
      path: '/sales/order/:id',
      url: '/v1/part/order/:id',
      slide: 'v1-p28',
    },
    {
      name: 'menu.sales.consumable.list',
      desc: '판매/소모품 발주/목록',
      path: '/sales/consumable',
      url: '/v1/part/consumalbeOrders',
      slide: 'v1-p29',
    },
    {
      name: 'menu.sales.consumable.detail',
      hidden: true,
      desc: '판매/소모품 발주/폼',
      path: '/sales/consumable/:id',
      url: '/v1/part/order/:id',
      slide: 'v1-p30',
    },
    {
      name: 'menu.sales.common.detail',
      hidden: true,
      desc: '판매/발주/폼',
      path: '/part/order/:id',
      url: '/v1/part/order/:id',
      isPublic: true,
      slide: 'v1-p28',
    },
  ],
  delivery: [
    {
      name: 'menu.delivery.estimate.list',
      desc: '납품/예정',
      path: '/delivery/estimate',
      url: '/v1/deliveryItems',
      slide: 'v1-p32',
    },
    {
      name: 'menu.delivery.packing.list',
      desc: '납품/패킹/목록',
      path: '/delivery/packing',
      url: '/v1/deliveryPacks',
      slide: 'v1-p33',
    },
    {
      name: 'menu.delivery.packing.detail',
      hidden: true,
      desc: '납품/패킹/폼',
      path: '/delivery/packing/:id',
      url: '/v1/deliveryPack/:id',
      slide: 'v1-p33',
    },
    {
      name: 'menu.public.delivery.checklist',
      hidden: true,
      desc: '납품/[고객] 구성품 체크리스트',
      path: '/public/delivery/checklist/:id',
      isPublic: true,
      slide: 'v1-p34',
      noStorybook: true,
    },
    {
      name: 'menu.public.delivery.transaction',
      hidden: true,
      desc: '납품/[고객] 거래명세표',
      path: '/public/delivery/transaction/:id',
      url: '/v1/public/deliveryPack/:id',
      isPublic: true,
      slide: 'v1-p34',
    },
  ],
  calculate: [
    {
      name: 'menu.calculate.target.list',
      desc: '정산/대상',
      path: '/calculate/target',
      url: '/v1/deliveryItems/needPayment',
      slide: 'v1-p36',
    },
    {
      name: 'menu.calculate.issued.list',
      desc: '정산/정산서/목록',
      path: '/calculate/issued',
      url: '/v1/payments',
      slide: 'v1-g393ecfb1b8_4_211',
    },
    {
      name: 'menu.calculate.issued.detail',
      hidden: true,
      desc: '정산/정산서/폼',
      path: '/calculate/issued/:id',
      url: '/v1/payment/:id',
      slide: 'v1-p37',
    },
    {
      name: 'menu.public.calculate.issued',
      hidden: true,
      desc: '정산/[고객] 정산서',
      path: '/public/calculate/issued/:id',
      url: '/v1/public/paymentRevision/:id',
      isPublic: true,
      slide: 'v1-g3c384acf21_3_11',
    },
  ],
  machine: [
    {
      name: 'menu.machine.list',
      desc: '장비/명세/목록',
      path: '/machine/list',
      url: '/v1/equipments',
      slide: 'v1-p40',
    },
    {
      name: 'menu.machine.detail',
      hidden: true,
      desc: '장비/명세/폼',
      path: '/machine/list/:id',
      url: '/v1/equipment/:id',
      slide: 'v1-p41',
    },
    {
      name: 'menu.machine.repair.needCheck',
      desc: '장비/점검/대상목록',
      path: '/machine/repair',
      url: '/v1/equipment/needCheck',
      slide: 'v1-p42',
    },
    {
      name: 'menu.machine.repair.list',
      desc: '장비/점검/점검이력',
      path: '/machine/repair',
      url: '/v1/equipment/checks',
      slide: 'v1-p42',
    },
    {
      name: 'menu.machine.repair.detail',
      hidden: true,
      desc: '장비/점검/폼',
      path: '/machine/repair/:id',
      url: '/v1/equipment/check/:id',
      slide: 'v1-p43',
    },
    {
      name: 'menu.machine.checklist.list',
      desc: '장비/구성품 체크리스트/목록',
      path: '/machine/checklist',
      url: '/v1/checklists',
      slide: 'v1-p44',
    },
    {
      name: 'menu.machine.checklist.detail',
      hidden: true,
      desc: '장비/구성품 체크리스트/폼',
      path: '/machine/checklist/:id',
      url: '/v1/checklist/:id',
      slide: 'v1-p44',
    },
  ],
  customer: [
    {
      name: 'menu.customer.call.list',
      desc: '고객/문의/목록',
      path: '/customer/call',
      url: '/v1/supports',
      slide: 'v1-p55',
    },
    {
      name: 'menu.customer.call.detail',
      hidden: true,
      desc: '고객/문의/폼',
      path: '/customer/call/:id',
      url: '/v1/support/:id',
      slide: 'v1-p55',
    },
    {
      name: 'menu.customer.estimate.list',
      desc: '고객/견적서/목록',
      path: '/customer/estimate',
      url: '/v1/estimates',
      slide: 'v1-g393ecfb1b8_4_135',
    },
    {
      name: 'menu.customer.estimate.detail',
      hidden: true,
      desc: '고객/견적서/폼',
      path: '/customer/estimate/:id',
      url: '/v1/estimate/:id',
      slide: 'v1-p56',
    },
    {
      name: 'menu.public.estimate',
      hidden: true,
      desc: '고객/[고객] 견적서',
      path: '/public/estimate/:id',
      isPublic: true,
      url: '/v1/public/estimateRevision/:id',
      slide: 'v1-g393ecfb1b8_4_149',
    },
  ],
  as: [
    {
      name: 'menu.as.list',
      desc: 'AS/목록',
      path: '/as/list',
      url: '/v1/afterServices',
      slide: 'v1-p58',
    },
    {
      name: 'menu.as.detail',
      hidden: true,
      desc: 'AS/폼',
      path: '/as/list/:id',
      url: '/v1/afterService/:id',
      slide: 'v1-p59',
    },
    {
      name: 'menu.public.as',
      hidden: true,
      desc: 'AS/[고객] 수리비 견적서',
      path: '/public/as/:id',
      isPublic: true,
      url: '/v1/public/estimateRevision/:id',
      slide: 'v1-p60',
    },
  ],
  part: [
    {
      name: 'menu.part.list',
      desc: '자재/목록',
      path: '/part/list',
      url: '/v1/parts',
      slide: 'v1-p62',
    },
    {
      name: 'menu.part.detail',
      hidden: true,
      desc: '자재/폼',
      path: '/part/list/:id',
      url: '/v1/part/:id',
      slide: 'v1-p62',
    },
    {
      name: 'menu.part.io.list',
      desc: '자재/입출고/목록',
      path: '/part/io',
      url: '/v1/part/historys',
      slide: 'v1-p63',
    },
    {
      name: 'menu.part.io.detail',
      hidden: true,
      desc: '자재/입출고/폼',
      path: '/part/io/:id',
      url: '/v1/part/history/:id',
      slide: 'v1-p64',
    },
  ],
};

export { menu };

let menuId = 0;
const result: TMenu[] = [];

(function objectToMenu(_menus: TMenuContainer) {
  Object.keys(_menus).forEach(key => {
    const _menu = _menus[key];

    if (Array.isArray(_menu)) {
      menuId++;
      result.push({
        menuId,
        depth: 1,
        name: `menu.${key}.title`,
      });

      _menu.forEach(__menu => {
        menuId++;
        result.push({
          ...__menu,
          menuId,
          depth: 2,
        });
      });
    } else {
      objectToMenu(_menu as TMenuContainer);
    }
  });
})(menu);

export default result;

export function findMenuByName(name: string) {
  let targetMenu: TMenu | undefined;

  (function findMenu(_menus: TMenuContainer) {
    Object.values(_menus).find(_menu => {
      if (Array.isArray(_menu)) {
        targetMenu = _menu.find(__menu => __menu.name === name);
      } else {
        findMenu(_menu);
      }

      return !!targetMenu;
    });
  })(menu);

  return targetMenu;
}
