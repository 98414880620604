import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { forEach } from 'lodash';
import { IButton, IList } from 'stores/ConfigStore';
import { Empty as AntEmpty, Timeline as AntTimeline } from 'antd';
import { formatDateString, getDataValue } from 'components';
import { IInjectedType, Omit } from 'common/@types';
import { computed, toJS } from 'mobx';
import styled from 'styled-components';
import moment from 'moment';
import { getPathFromLink } from 'common/router';

const AntTimelineItem = AntTimeline.Item;

interface ITimelineProps extends RouteComponentProps, IInjectedType {
  styled?: string;
  config: IList & IButton;
  data?: any;
  pageUuid: string;
}

type ExposedProps = Omit<ITimelineProps, keyof (RouteComponentProps & IInjectedType)> &
  Partial<RouteComponentProps & IInjectedType>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Timeline extends React.Component<ITimelineProps> {
  @computed
  get listData() {
    const { config } = this.props;
    const { dataIndex } = config;

    let data: any = {};
    if (dataIndex) {
      let value = toJS(getDataValue(this.props.data, dataIndex) || []);
      if (!Array.isArray(value)) {
        value = [value];
      }
      // console.log('table render value', value);
      formatDateString(value);
      data = {
        rows: value,
        page: 1,
        pageSize: 100,
        total: value.length,
      };
    } else if (this.props.data) {
      data = { ...toJS(this.props.data) };
      formatDateString(data.rows);
    }

    if (data.rows && data.rows.length > 0) {
      data.rows = data.rows.filter((row: any) => !row.delete);
      data.total = data.rows.length;
    }

    forEach(data.rows, row => {
      if (!row._id) {
        row._id = row.id || `${new Date().getTime()}${Math.random()}`;
      }
    });

    return data;
  }

  get getItems(): React.ReactNode[] {
    const data = this.listData;
    const items: any[] = [];
    if (data.total) {
      data.rows.forEach((item: any) => {
        const itemDate = moment(item.dueDate).isValid() ? moment(item.dueDate).format('A h:mm') : false;
        const itemPerson = item.companyPerson
          ? '[' + item.companyPerson.name + (item.companyPerson.role ? ' ' + item.companyPerson.role : '') + ']'
          : false;

        items.push(
          <AntTimelineItem key={item._id}>
            <div onClick={() => this.onClickItem(item)} style={{ cursor: 'pointer' }}>
              <div>{itemDate}</div>
              <div>{itemPerson || ''}</div>
              <div>{item.name}</div>
            </div>
          </AntTimelineItem>,
        );
      });
    }

    return items || <AntEmpty />;
  }

  onClickItem(data: any) {
    const path = getPathFromLink(this.props.config.link, this.props.match, data);
    path && this.props.history.push(path);
  }

  render() {
    const { config, styled: styledString = '' } = this.props;
    const { attr } = config;
    const Container = styled.div`
      position: relative;
      display: flex;
      padding: 10px;
    `;

    return <AntTimeline {...attr}>{this.getItems}</AntTimeline>;
  }
}

export default withRouter(Timeline) as React.ComponentType<ExposedProps>;
