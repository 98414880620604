import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { Card as AntCard } from 'antd';
import { IKanbanConfig, ISelect } from 'stores/ConfigStore';
import { cloneDeep } from 'lodash';
import { Heading } from 'components/Typo';
import { api } from 'providers';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';
import { inject, observer } from 'mobx-react';
import { computed, toJS } from 'mobx';
import i18nFromJSON from 'components/Common/I18nFromJSON';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';
import { getPathFromLink } from 'common/router';

const KanbanRow = styled.div`
  color: #172b4d;
  font-size: 14px;
  box-sizing: border-box;
  display: table-cell;
  list-style: none;
  margin: 0;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
  vertical-align: top;
  padding: 1px 3px 0;
  border-radius: 0 0 4px 4px;
  background: #f4f5f7;
`;

const KanbanCard = styled(AntCard)`
  &.ant-card-bordered {
  }

  font-weight: 400;
  font-style: normal;
  list-style: none;
  margin: 0;
  background: #fff;
  color: #333;
  cursor: move;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  transition: background-color 140ms ease-in-out, border-color 75ms ease-in-out;
  border: 0;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
  padding: 0px;
  font-size: 12px;
  padding-left: 3px;
`;

const KanbanTitle = styled.p`
  font-weight: bold;
`;

const KanbanGrabber = styled.div`
  border-spacing: 10px 0;
  list-style: none;
  color: #333;
  cursor: move;
  margin: 0;
  padding: 0;
  position: absolute;
  text-indent: -9999em;
  width: 3px;
  height: auto;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #34a1f5;
`;

interface IKanbanListProps extends IInjectedType, IInjectedStore, RouteComponentProps {
  config: IKanbanConfig;
  pageUuid: string;
  dataIndex?: string;
  data: any;
}

type ExposedProps = Omit<IKanbanListProps, keyof (IInjectedType & RouteComponentProps)> &
  Partial<IInjectedType & RouteComponentProps>;

interface IKanbanItem {
  _id: number;
  board: string;
  data: any;
}

interface IKanbanListState {
  backlog?: IKanbanItem[];
  doing?: IKanbanItem[];
  review?: IKanbanItem[];
  done?: IKanbanItem[];
}

// a little function to help us with reordering the result
const reOrder = (list: any[], startIndex: number, endIndex: number): IKanbanItem[] => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  source: IKanbanItem[],
  destination: IKanbanItem[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
): { [k: string]: IKanbanItem[] } => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  padding: 0,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : '',
  padding: grid * 0.5,
  width: 190,
});

const defaultState = {
  backlog: [],
  doing: [],
  review: [],
  done: [],
};

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class KanbanList extends React.Component<IKanbanListProps, IKanbanListState> {
  id2List = {
    droppable1: 'backlog',
    droppable2: 'doing',
    droppable3: 'review',
    droppable4: 'done',
  };

  constructor(props: IKanbanListProps) {
    super(props);
  }

  @computed
  get cards(): IKanbanListState {
    const cards = cloneDeep(defaultState) as IKanbanListState;
    const items: any = { ...toJS(this.props.data) };
    if (items.total > 0) {
      for (const row of items.rows) {
        const kb = {
          _id: row.id,
          board: row.board,
          data: row,
        };
        cards[row.board].push(kb);
      }
    }

    return cards;
  }

  @computed
  get droppables(): React.ReactNode[] {
    const DroppableComponents: any = [];

    for (const key in this.id2List) {
      if (this.cards.hasOwnProperty(this.id2List[key])) {
        const item: IKanbanItem[] = this.cards[this.id2List[key]];
        DroppableComponents.push(
          <Droppable key={key} droppableId={key}>
            {(provided, snapshot) => (
              <KanbanRow ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                <Heading
                  pageUuid={this.props.pageUuid}
                  config={{
                    cType: 'heading',
                    title: i18nFromJSON(this.id2List[key]),
                    style: { marginTop: '0', fontSize: '12px', color: '#5E6C84', textTransform: 'uppercase' },
                  }}
                />
                {item.map((item: IKanbanItem, index) => (
                  <Draggable key={key + '-' + item._id} draggableId={key + '-' + item._id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => this.onClickItem(item.data)}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style || ({} as DraggingStyle | NotDraggingStyle),
                        )}
                      >
                        <KanbanCard size="small" bodyStyle={{ padding: grid }}>
                          <KanbanTitle>
                            {`[${this.getDivisionName(item.data.division)}]`} {item.data.name}
                          </KanbanTitle>
                          <p>{this.getCardPerson(item.data.companyPerson)}</p>
                          <KanbanGrabber />
                        </KanbanCard>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </KanbanRow>
            )}
          </Droppable>,
        );
      }
    }
    return DroppableComponents;
  }

  getList = (id: string | number) => this.cards[this.id2List[id]];

  onDragEnd = (result: DropResult, _: ResponderProvided) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reOrder(this.getList(source.droppableId), source.index, destination.index);

      this.cards[this.id2List[destination.droppableId]] = items;
    } else {
      const result = move(this.getList(source.droppableId), this.getList(destination.droppableId), source, destination);

      this.cards[this.id2List[source.droppableId]] = result[source.droppableId];
      this.cards[this.id2List[destination.droppableId]] = result[destination.droppableId];
    }

    this.checkChanged();
  };

  getCardPerson = (companyPerson: any) => {
    if (companyPerson && companyPerson.name) {
      const { name } = companyPerson;
      const rank = companyPerson.rank ? ' ' + companyPerson.rank : '';

      return `(${name}${rank ? ` ${rank}` : ''})`;
    }

    return '';
  };

  checkChanged() {
    const { data = {} } = this.props.keyStore.getItem(this.props.pageUuid);
    Object.keys(this.cards).forEach(board => {
      const items: IKanbanItem[] = this.cards[board];
      items.forEach(async item => {
        if (item.board !== board) {
          const rowIdx = (data.rows as any[]).findIndex((row: any) => row.id === item._id);
          const row = data.rows[rowIdx];
          row.board = board;
          item.board = board;
          // api
          if (this.props.config.url) {
            await api.put(this.props.config.url, { id: item._id, board });
          }
        }
      });
    });
    console.log('checkChanged', data);
  }

  onClickItem(data: any) {
    const path = getPathFromLink(this.props.config.link, this.props.match, data);
    path && this.props.history.push(path);
  }

  getDivisionName(division: string) {
    const { column } = this.props.config;
    if (column) {
      const divisionColumn = column.find(col => col.dataIndex === 'division');
      const { option } = divisionColumn as ISelect;
      if (option) {
        const findOption = option.find(opt => opt.value === division);
        if (findOption) {
          return findOption.text;
        }
      }
    }
    return division;
  }

  render() {
    console.log('KanbanList render / cards', this.cards);
    const Container = styled.div`
      width: 200%;
      display: flex;
      /* border: 1px solid lightgrey; */
    `;
    const KanbanWrapper = styled.div`
      display: flex;
      overflow: auto;
    `;
    return (
      <KanbanWrapper>
        <Container>
          <DragDropContext onDragEnd={this.onDragEnd}>{this.droppables}</DragDropContext>
        </Container>
      </KanbanWrapper>
    );
  }
}
export default withRouter(KanbanList) as React.ComponentType<ExposedProps>;
