import * as React from 'react';
import { FlexCard } from 'components';
import styled from 'styled-components';
import { Button } from 'antd';
import { observer } from 'mobx-react';

export const ContentBox = styled.div`
  width: 90%;
  max-width: 300px;

  flex: none;
  text-align: center;

  img {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 800;
    color: ${p => p.theme.title_color};
    margin: 20px 0;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    color: ${p => p.theme.text_color_secondary};
  }

  [data-button-group] {
    margin: 30px 0;
    width: 100%;
    .ant-btn {
      margin: 10px 0;
      padding: 0 30px;
    }
  }
`;

@observer
class ErrorBoundary extends React.Component<any, any> {
  state = {
    err: '',
    info: '',
  };
  componentDidCatch(err: any, info: any) {
    // if (process.env.NODE_ENV === 'production') {
    //   typeof window !== 'undefined' && window.location.reload(true);
    // }
    this.setState({ err, info });
  }

  render() {
    const { err, info } = this.state;
    if (err) {
      return (
        <FlexCard align={'center'} justify={'center'} height={'100%'}>
          <ContentBox>
            <h1>Oops!</h1>
            <p>
              <b>An error has occurred</b>
              <br />
              Please try again later. If the problem persists, please contact the customer center.
            </p>
            <div data-button-group>
              <Button
                type={'primary'}
                size={'large'}
                onClick={() => {
                  typeof window !== 'undefined' && window.location.reload();
                }}
              >
                Retry
              </Button>
            </div>
          </ContentBox>
        </FlexCard>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
