import styled from 'styled-components';
import * as React from 'react';

const StyledHr = styled.div`
  color: #172b4d;
  margin: 0;
  margin-top: 8px;
  padding: 0;
  padding-top: 8px;
  box-shadow: rgb(235, 236, 240) 0px 2px 0px 0px inset;
`;

const Hr: React.FC = () => {
  return <StyledHr />;
};

export default Hr;
