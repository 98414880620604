import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EModalMode, IModalConfigProps, IModalProps } from 'stores/ConfigStore';
import { IInjectedStore, Omit } from 'common/@types';
import { Modal } from 'antd';
import { Form as ModalForm } from 'components/Modal/Content';
import { convertAndSetData, getAndRevertData } from 'common/modalHelper';
import Spinner from 'components/Common/Spinner';

interface IProps extends IModalProps, IInjectedStore {
  mType: string;
  cType: string;
  title?: any;
  form: IModalConfigProps;
  pageUuid: string;
}

type ExposedProps = Omit<IProps, keyof IInjectedStore> & Partial<IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class Form extends React.Component<IProps> {
  onSubmit = (values: any) => {
    console.log('Modal Form onSubmit', values);
    convertAndSetData(this, values);
    this.props.onOk && this.props.onOk();
  };

  onCancel = () => {
    this.props.onCancel && this.props.onCancel();
  };

  render() {
    const { cType, visible, mode, title, pageUuid } = this.props;

    if (mode === EModalMode.NEW && cType !== 'form') {
      return null;
    }

    const data = getAndRevertData(this);

    const formStyle = {} as any;
    let modalTitle = null;
    if (title) {
      modalTitle = typeof title === 'string' ? title : data[title.dataIndex];
      if (modalTitle) {
        formStyle.paddingTop = 0;
      }
    }

    return (
      <Modal open={visible} onCancel={this.onCancel} title={modalTitle} footer={null} destroyOnClose={true}>
        <Spinner pageUuid={this.props.pageUuid}>
          {visible ? (
            <ModalForm
              config={this.props.form}
              pageUuid={pageUuid}
              data={data}
              onSubmit={this.onSubmit}
              style={formStyle}
            />
          ) : null}
        </Spinner>
      </Modal>
    );
  }
}

export default Form as React.ComponentType<ExposedProps>;
