import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MultiSelector } from 'components';
import { Footer, Header } from 'components/Layout';
import { IMultiViewConfig } from 'stores/ConfigStore';
import { IInjectedStore, IInjectedType, Omit } from 'common/@types';

interface IMultiViewProps extends IInjectedType, IInjectedStore {
  config: IMultiViewConfig;
  data?: any;
  pageUuid: string;
}
type ExposedProps = Omit<IMultiViewProps, keyof (IInjectedType & IInjectedStore)> &
  Partial<IInjectedType & IInjectedStore>;

@inject('appStore', 'i18nStore', 'keyStore')
@observer
class MultiView extends React.Component<IMultiViewProps> {
  render() {
    const {
      form,
      mode,
      handleSubmit,
      config: { column, header, header2nd, footer },
      pageUuid,
    } = this.props;

    const { option } = this.props.keyStore.getItem(this.props.pageUuid);
    const { viewIndex = 0 } = option || {};
    const targetColumn = column?.[viewIndex];

    return (
      <div>
        <Header config={header} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        <Header config={header2nd} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
        {targetColumn && (
          <MultiSelector
            key={viewIndex}
            config={targetColumn}
            data={this.props.data}
            pageUuid={pageUuid}
            form={form}
            mode={mode}
            handleSubmit={handleSubmit}
          />
        )}
        <Footer config={footer} pageUuid={pageUuid} form={form} mode={mode} handleSubmit={handleSubmit} />
      </div>
    );
  }
}

export default MultiView as React.ComponentType<ExposedProps>;
